
































import { PartyProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import {Component, Vue, Prop, Ref} from 'vue-property-decorator';
import InsuarenceDialog from '@/libs/clients/components/InsuarenceDialog.vue';

@Component({
    components: {
        InsuarenceDialog
    }
})
export default class InsuaranceCard extends Vue {


    @Prop() partyProduct!: PartyProductModel;
    @Prop() clientId!: string;
    @Ref() dialog!: InsuarenceDialog;
    
    newId: string | null = Guid.EmptyGuid();

    openPartyProduct() {
        if (this.partyProduct.id == this.newId)
            return;
        this.dialog.open();
    }

}

