export enum CrudGetter {
    Pending = 'PENDING',
    Saving = 'SAVING',
    Deleting = 'DELETING',
    Data = 'ALL_DATA',
    DataItem = 'ITEM_DATA',
    Detail = 'DETAIL',
    Error = 'ERROR',
    Downloading = "DOWNLOADING",
    DataStats = "ALL_DATA_STATS",
    Count = "COUNT",
    Avg = "AVERAGE",
    Sum = "SUM",
    GrouppedData = "GROUPPED_DATA"
}
