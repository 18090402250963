
























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class TextInput extends Vue {

    innerText: string | number | null = "";

    @Prop() value!: string | number | null;
    @Prop() label!: string;
    @Prop() classObject!: string;
    @Prop() placeholder!: string;
    @Prop({ default: "text" }) type!: string;
    @Prop({ default: null }) suffix!: string;
    @Prop() disabled!: boolean;
    @Prop() readonly!: boolean;
    @Prop() step!: string;
    @Prop() currencyFormat!: boolean;
    @Prop() rules!: any[];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) hideDetails!: boolean;

    mounted () {
      this.innerText = this.value;
      if (this.currencyFormat)
        this.innerText = this.formatCurrency(this.innerText);
    }

    @Watch("innerText")
    innerTextChanged(v: string, p: string) {
      if (v == p)
        return;
      if (this.currencyFormat) {
        this.innerText = this.formatCurrency(v);
        this.$emit("input", this.type == "number" && v ? Number(v.replaceAll(" ", "")) : v);
      } else {
        this.$emit("input", this.type == "number" && v ? Number(v.replaceAll(" ", "")) : v);
      }
      this.$emit("change", v);
    }

    @Watch("value")
    valueChanged(v: string, p: string) {
      if (v == p)
        return;
      this.innerText = v;
    }

    formatCurrency (v: any) {
      const origo = v;
      if (v != null) {
        v = String(v).replace(/\s/g, "");
        return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    }

}
