












import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class ClientSelect extends Vue {

    @Prop({ default: "" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() type!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: true }) createParty!: boolean;
    @Prop() productId!: string;

}
