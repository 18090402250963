

































































import { ApplicationUserModel, PartyActivityModel, PartyModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { partiesStore, partyActivitiesStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import moment from 'moment';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { Guid } from '@/libs/common/functions/guid';

import { cloneDeep } from 'lodash';

@Component
export default class ClientComments extends Vue {

    newComment: string | null = null;
    commentsList: PartyActivityModel[] = [];
    itemToEdit: string | null = null;
    creating = false;
    saving: string | null = Guid.EmptyGuid();
    deleting: string | null = Guid.EmptyGuid();

    get today(): string {
        return moment().format("DD.MM.YYYY");
    }

    get client(): PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.id);
    }

    get comments (): PartyActivityModel[] {
        return partyActivitiesStore.useGetter(CrudGetter.Data, this.id);
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    @Prop() id!: string;

    @Watch("comments")
    commentsChanged (v: PartyActivityModel[]) {
        this.commentsList = cloneDeep(v);
    }

    @Watch("id")
    idChanged () {
        this.loadComments();
    }
    
    mounted () {
        this.loadComments();
    }

    loadComments() {
        partyActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyActivityModel>([{ field: "createdAt", index: 0, order: "desc" }], [{ field: "partyId", op: "eq", comparand: this.client.id! }], undefined, false, this.id));
    }

    createComment () {
        const newItem = {
            id: Guid.EmptyGuid(),
            description: this.newComment,
            partyId: this.client.id,
            createdById: this.user.partyId
        } as PartyActivityModel;
        partyActivitiesStore.dispatch(CrudAction.Create, { item: newItem, key: this.id });
        this.creating = true;
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === partyActivitiesStore.getActionName(CrudResponseAction.CreateSuccess)) {
                subscribtion();
                self.newComment = null;
                this.creating = false;
            }
            else if (mutation.type === partyActivitiesStore.getActionName(CrudResponseAction.CreateFailed)) {
                subscribtion();
                this.creating = false;
            }
        });
    }

    deleteComment (item: PartyActivityModel) {
        partyActivitiesStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [{ field: "id", op: "eq", comparand: item.id! }], undefined, false, this.id));
        const self = this;
        this.deleting = item!.id!;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === partyActivitiesStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                subscribtion();
                self.deleting = null;
            }
            else if (mutation.type === partyActivitiesStore.getActionName(CrudResponseAction.DeleteFailed)) {
                subscribtion();
                console.log("Comment update failed");
                self.deleting = null;
            }
        });
    }

    editComment (item: PartyActivityModel) {
        this.itemToEdit = item.id!;
    }

    saveComment () {
        if (this.itemToEdit != null) {
            this.saving = this.itemToEdit;
            const item = this.commentsList.find(x => x.id == this.itemToEdit);
            partyActivitiesStore.dispatch(CrudAction.Update, { item: item, key: this.id });
            const self = this;
            this.saving = item!.id!;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === partyActivitiesStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                    subscribtion();
                    self.itemToEdit = null;
                    this.saving = null;
                }
                else if (mutation.type === partyActivitiesStore.getActionName(CrudResponseAction.UpdateFailed)) {
                    subscribtion();
                    console.log("Comment update failed");
                    this.saving = null;
                }
            });
        }
    }

    textAreaToHtml (value : string, length = 0) {
        if (value) {
            value = value.replaceAll("\n", "<br />");
        }
        if (value) {
            if (length > 0) {
                if (value.length > length) {
                    value = value.substring(0, length).concat("...");
                }
            }
        }
        return value;
    }

}
