


























































import {Component, Prop, Vue} from 'vue-property-decorator';
import SystemSettingsProductNoif from "@/libs/system-settings/pages/ProductNoif.vue";

@Component({
	components: {  }
})
export default class SystemSettingsProductNrem extends Vue {

}
