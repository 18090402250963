






































import { PartyProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import {Component, Vue, Prop, Ref} from 'vue-property-decorator';
import NCIFDialog from '@/libs/clients/components/NCIFDialog.vue';

@Component({
    components: {
        NCIFDialog
    }
})
export default class NOIFCard extends Vue {

    @Prop() partyProduct!: PartyProductModel;
    @Prop() clientId!: string;
    @Ref() dialog!: NCIFDialog;
    @Prop({ default: false }) accountSection!: boolean;
    
    newId: string | null = Guid.EmptyGuid();

    openPartyProduct() {
        this.dialog.open();
    }

}

