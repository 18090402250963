import {RouteConfig} from 'vue-router';

import UserSettings from './pages/UserSettings.vue';
import UserSettingsGeneral from './pages/General.vue';
import UserSettingsPasswordChange from './pages/PasswordChange.vue';

export default [
    {
        path: '/user-settings',
        name: 'UserSettings',
        component: UserSettings,
        meta: {
            reload: true,
            clientZone: true
        },
        children: [
            {
                path: "general",
                name: "UserSettingsGeneral",
                component: UserSettingsGeneral,
                meta: {
                    clientZone: true
                },
            },
            {
                path: "password-change",
                name: "UserSettingsPasswordChange",
                component: UserSettingsPasswordChange,
                meta: {
                    clientZone: true
                },
            }
        ]
    }
] as RouteConfig[];
