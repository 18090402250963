





































/* eslint-disable no-mixed-spaces-and-tabs */
import { PartyProductModel, ApplicationUserModel, ExchangeRateModel, ExchangeRateTypeEnum, QueryOperator, WalletModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import CftDialog from '@/libs/clients/components/CftDialog.vue';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Constants } from '@/libs/constants/constants';
import {CrudAction, CrudReponse, CrudResponseAction} from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { exchangeRatesStore } from '@/libs/exchange-rates/+state/store';
import { walletsStore } from '@/libs/wallets/+state/store';
import Page from "@/Page.vue";

@Component({
    components: {
		CftDialog
    }
})
export default class CftCard extends Page {

    @Prop() partyProduct!: PartyProductModel;
    @Prop() clientId!: string;
    @Ref() dialog!: CftDialog;
    @Prop({ default: false }) accountSection!: boolean;
    
    wallets: WalletModel[] = [];
    newId: string | null = Guid.EmptyGuid();

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get walletCZK(): WalletModel {
        return this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId);
    }

    get currentInvestmentValue () {
        const exchangeRateNLT = (exchangeRatesStore.useGetter(CrudGetter.Data, 'LastNLTExchangeRate') as ExchangeRateModel[]).firstOrDefault();
        const exchangeRateUSD = (exchangeRatesStore.useGetter(CrudGetter.Data, 'LastUSDExchangeRate') as ExchangeRateModel[]).firstOrDefault();
        const walletNLT = (walletsStore.useGetter(CrudGetter.Data, 'CftWallets') as WalletModel[]).where(x => x.currencyId == Constants.CurrencyCftId).firstOrDefault();
		if (exchangeRateUSD != null && exchangeRateNLT != null && walletNLT != null) {
            return walletNLT.currentValue * exchangeRateUSD.rate * exchangeRateNLT.rate;
        }
        return null;
    }

    mounted() {
        if (this.partyProduct.id != this.newId) {
            this.loadWallets();
            this.loadLastExchangeRates();
        }
    }

    openPartyProduct() {
        this.dialog.open();
    }

    loadWallets () {
        walletsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
            { field: "partyProductId", op: "eq", comparand: this.partyProduct.id }
        ], undefined, false, 'CftWallets'));
	    this.subscribe(walletsStore, CrudReponse.GetAll, 'CftWallets').then((wallets: WalletModel[]) => {
		    this.wallets = wallets;
	    });
    }

    loadLastExchangeRates() {
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
            { field: 'toDate', index: 1, order: 'desc' }
        ], [
            { field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyUsdId },
            { field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
            { field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Currency },
            { field: 'isPublic', op: QueryOperator.Eq, comparand: true }
        ], { pageIndex: 1, pageSize: 1 }, false, "LastUSDExchangeRate"));
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
            { field: 'toDate', index: 1, order: 'desc' }
        ], [
            { field: 'productId', op: QueryOperator.Eq, comparand: Constants.CftProductId },
            { field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Product },
            { field: 'isPublic', op: QueryOperator.Eq, comparand: true }
        ], { pageIndex: 1, pageSize: 1 }, false, "LastNLTExchangeRate"));
    }

}

