














import { ProductTypeModel } from '@/libs/Api';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component({ })
export default class AccountTypeSelect extends Vue {

    @Prop({ default: "Typ účtu" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: true }) includeClient!: boolean;

    get items(): ProductTypeModel[] {
        const items = [
          { id: "Admin", name: "Admin" },
          { id: "Manager", name: "Manažer" },
          { id: "Consultant", name: "Poradce" },
          { id: "Assistant", name: "Asistent" }
        ];
        if (this.includeClient) {
            items.push({ id: "Client", name: "Klient" });
        }
        return items;
    }

}
