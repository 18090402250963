































import { PartyProductModel, ApplicationUserModel, ExchangeRateModel, ExchangeRateTypeEnum, QueryOperator, WalletModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import RequestForQuotationDialog from '@/libs/account/components/RequestForQuotationDialog.vue';
import { Constants } from '@/libs/constants/constants';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { exchangeRatesStore } from '@/libs/exchange-rates/+state/store';
import { walletsStore } from '@/libs/wallets/+state/store';

@Component({
    components: {
        RequestForQuotationDialog
    }
})
export default class ClientCftCard extends Vue {

    @Prop() partyProduct!: PartyProductModel;
    @Prop() clientId!: string;
    @Prop({ default: false }) accountSection!: boolean;
    
    wallets: WalletModel[] = [];
    newId: string | null = Guid.EmptyGuid();

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get walletCZK(): WalletModel {
        return this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId);
    }

    get currentInvestmentValue () {
        const exchangeRateNLT = (exchangeRatesStore.useGetter(CrudGetter.Data, 'LastNLTExchangeRate') as ExchangeRateModel[]).firstOrDefault();
        const exchangeRateUSD = (exchangeRatesStore.useGetter(CrudGetter.Data, 'LastUSDExchangeRate') as ExchangeRateModel[]).firstOrDefault();
        const walletNLT = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCftId);
        if (exchangeRateUSD != null && exchangeRateNLT != null && walletNLT != null) {
            return walletNLT.currentValue * exchangeRateUSD.rate * exchangeRateNLT.rate;
        }
        return null;
    }

    mounted() {
        if (this.partyProduct.id != this.newId) {
            this.loadWallets();
            this.loadLastExchangeRates();
        }
    }

    loadWallets () {
        walletsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
            { field: "partyProductId", op: "eq", comparand: this.partyProduct.id }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === walletsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                const wallets = walletsStore.useGetter(CrudGetter.Data);
                this.wallets = wallets;
                subscriber();
            }
        });
    }

    loadLastExchangeRates() {
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
            { field: 'toDate', index: 1, order: 'desc' }
        ], [
            { field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyUsdId },
            { field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
            { field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Currency },
            { field: 'isPublic', op: QueryOperator.Eq, comparand: true }
        ], { pageIndex: 1, pageSize: 1 }, false, "LastUSDExchangeRate"));
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
            { field: 'toDate', index: 1, order: 'desc' }
        ], [
            { field: 'productId', op: QueryOperator.Eq, comparand: Constants.CftProductId },
            { field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Product },
            { field: 'isPublic', op: QueryOperator.Eq, comparand: true }
        ], { pageIndex: 1, pageSize: 1 }, false, "LastNLTExchangeRate"));
    }

}

