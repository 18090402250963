





























import { PartyModel, StateOfCapitalYearModel, TransactionModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { stateOfCapitalYearStore } from '@/libs/state-of-capital/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Constants } from '@/libs/constants/constants';

@Component({
    components: {
        CrudTable
    }
})
export default class ProductReOpportunitiesStateOfCapitalYear extends Vue {

    headers = [
        { text: 'Datum', align: 'left', sortable: false, value: 'date', type: "date" },
        { text: 'Zhodnocení', align: 'left', sortable: false, value: 'value', suffix: "%", type: "text", readonly: true }
    ];
    defaultItem = { id: Guid.EmptyGuid()!, value: undefined, date: undefined, productId: Constants.ReOpportunitiesId.toString() } as StateOfCapitalYearModel;

    @Ref() crudTable!: CrudTable;
    @Prop() id!: string;

    get items(): any[] {
        return stateOfCapitalYearStore.useGetter(CrudGetter.Data) as StateOfCapitalYearModel[];
    }

    mounted() {
        this.loadData();
    }

    evaluation (value: number | undefined, investmentValue: number | undefined) {
        if (value && investmentValue && value != null && investmentValue != null && Number(investmentValue) > 0) {
            return ((Number(investmentValue) / Number(value) - 1) * 100).toFixed(2) + " %";
        }
        return "--";
    }

    loadData () {
        stateOfCapitalYearStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<StateOfCapitalYearModel>([ { field: "date", index: 1, order: "desc" } ], [ { field: "productId", op: "eq", comparand: Constants.ReOpportunitiesId } ], undefined, false));
    }

    addStateOfCapital () {
        this.crudTable.addItemClick();
    }

    saveItem (e: TransactionModel) {
        if (e.id == Guid.EmptyGuid()) {
            stateOfCapitalYearStore.dispatch(CrudAction.Create, { item: e });
        }
        else {
            stateOfCapitalYearStore.dispatch(CrudAction.Update, { item: e });
        }
    }

    deleteItem (e: TransactionModel) {
        stateOfCapitalYearStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TransactionModel>(undefined, [ { field: "id", op: "eq", comparand: e.id! } ], undefined, false));
    }
    
}
