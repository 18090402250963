









import { PartyModel } from '@/libs/Api';
import {Component, Vue, Prop} from 'vue-property-decorator';
import { partiesStore } from '@/libs/parties/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import CloudFiles from '@/libs/cloud/components/CloudFiles.vue';

@Component({
    components: { CloudFiles }
})
export default class ClientFiles extends Vue {

    get client(): PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.id);
    }

    get clientFolder(): string | null {
        if (this.client != null) {
            return encodeURIComponent("Clients/${0}#{1}".format(this.client.id!, this.client.displayName!));
        }
        else {
            return null;
        }
    }

    @Prop() id!: string;

}
