




import {Component, Prop, Watch} from "vue-property-decorator";
	import Chart from "@/libs/clients/components/Cft/Chart.vue";
	import VueApexCharts from "vue-apexcharts";
	import {CrudAction, CrudReponse} from "@/libs/core/+state/models/crud-action";
	import {createCrudQueryPayload, createGrouppedQueryPayload} from "@/libs/core/+state/models/crud-query-payload";
	import {
		ConvertFunctionEnum,
		ExchangeRateModel, GroupByDefinition,
		GroupByProjectionDefinition,
		ProjectionAggregationEnum,
		QueryOperator
	} from "@/libs/Api";
	import {Constants} from "@/libs/constants/constants";
	import moment from "moment";
	import {exchangeRatesStore} from "@/libs/exchange-rates/+state/store";
	import {CrudGetter} from "@/libs/core/+state/models/crud-getter";

	@Component({
		components: {VueApexCharts}
	})
	export default class ChartByTransaction extends Chart {

		loaded = false;
		chartSeries = [];
		chartOptions = {};

		@Prop() dateRange!: string;
		@Prop() fromDate!: string;
		@Prop() toDate!: string;
		@Prop() height!: number;

		@Watch("dateRange")
		dateRangeChanged(): void {
			this.loadData();
		}

		@Watch("fromDate")
		@Watch("toDate")
		dateChanged(): void {
			if (this.dateRange == "custom") {
				this.loadData();
			}
		}

		mounted(): void {
			console.log("height", this.height);
			this.loadData();
		}

		loadData(): void {
			this.loaded = false;
			exchangeRatesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<ExchangeRateModel>([
				{ field: 'productId', op: QueryOperator.Eq, comparand: Constants.CftProductId },
				{ field: 'isPublic', op: QueryOperator.Eq, comparand: true },
				{ field: 'fromDate', op: QueryOperator.Gte, comparand: this.getRangeFromDate(this.dateRange, this.fromDate) },
				{ field: 'fromDate', op: QueryOperator.Lte, comparand: this.getRangeToDate(this.dateRange, this.toDate) },
			], [
				{
					field: "fromDate",
					convert: ConvertFunctionEnum.Date
				} as GroupByDefinition,
				{
					field: "fromDate",
					convert: ConvertFunctionEnum.Hour
				} as GroupByDefinition
			], [
				{
					field: "Rate",
					aggregation: ProjectionAggregationEnum.Avg,
					outputField: "Rate"
				} as GroupByProjectionDefinition
			], "DialogExchangeRates"));
			this.subscribe(exchangeRatesStore, CrudReponse.GetGroupped, "DialogExchangeRates").then(() => {
				this.make();
				this.loaded = true;
			});
		}

		make(): void {
			let data = exchangeRatesStore.useGetter(CrudGetter.GrouppedData, "DialogExchangeRates") as any[];
			const minValue = data?.length > 0 ? data?.select(x => x.rate)?.min() : 0;
			const maxValue = data?.length > 0 ? data?.select(x => x.rate)?.max() : 0;
			const minDate = data?.length > 0 ? data.select(x => moment(x.key.date).add('hour', x.key.hour + 2).toDate().getTime())?.min() : new Date().getTime();
			const maxDate = data?.length > 0 ? data.select(x => moment(x.key.date).add('hour', x.key.hour + 2).toDate().getTime())?.max() : new Date().getTime();
			this.chartOptions = this.getChartOptions(moment(minDate).format("YYYY-MM-DDTHH:mm:ss"), moment(maxDate).format("YYYY-MM-DDTHH:mm:ss"), minValue, maxValue);
			this.chartSeries = [
				{
					name: "Vývoj kurzu",
					data: data.select(x => [ moment(x.key.date).add('hour', x.key.hour + 2).toDate().getTime(), x.rate ]).orderBy(x => x[0]).toArray()
				}
			];
		}

	}
