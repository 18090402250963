






































































































































































































































































































































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { ApplicationUserModel, CodebookModel, CodebookType, FileCopyModel, FileModel, PartyModel, ProductActivityModel, UserTaskActivityModel, UserTaskModel, UserTaskSolverModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import ApiService from "@/libs/core/api-service"
import { tasksStore, userTaskActivitiesStore } from '@/libs/tasks/+state/store';
import { CrudAction, CrudReponse, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import moment from 'moment';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import CloudFiles from '@/libs/cloud/components/CloudFiles.vue';
import { filesStore } from '@/libs/files/+state/store';
import { cloneDeep } from 'lodash';
import SubTaskItemList from './SubTaskItemList.vue';
import Page from '@/Page.vue';

@Component({
    components: {
        CloudFiles, SubTaskItemList
    }
})
export default class TaskDialog extends Page {

    isActive = true;
    dialog = false;
    archiveTaskDialog = false;
    deleteTaskDialog = false;
    archivingTask = false;
    deletingTask = false;
    id: string | null = null;
    loadOverride: any = null;
    newComment: string | null = null;
    comments: Array<any> = [];
    userTaskActivities: UserTaskActivityModel[] = [];
    userTaskSolvers: UserTaskSolverModel[] = [];
    commentsList: UserTaskActivityModel[] = [];
    valid = false;
    basicRules = [
        (v: any) => (!!v) || 'Toto pole je povinné.'
    ];
    dateBasicRules = [
        (v: any) => (!!v && v.length > 0) || 'Toto pole je povinné.'
    ];
    now = moment().format("YYYY-MM-DDTHH:MM:ss");
    dayStart = moment().format("YYYY-MM-DDT00:00:00");
    newId = Guid.EmptyGuid();
    pinFile = false;
    searchFile: string | null = null;
    itemToEdit: string | null = null;
    creatingComment = false;
    savingComment: string | null = "";
    deletingComment: string | null = "";
    task: UserTaskModel | null = null;
    loading = false;
    showFiles = false;

    @Prop() name!: string;
    @Prop() activator!: string;
    @Ref() form!: any;
    @Ref() cloudFiles!: CloudFiles;

    filesToConnectHeaders = [
        { text: 'Název souboru', value: 'fileName', sortable: false, align: "left" },
        { text: 'Umístění', value: 'party.name', sortable: false, align: "left" },
        { text: '', value: 'id', sortable: false, align: "right" },
    ];

    searchFileTimeout : any = null;
    attachingFiles: string[] = [];

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Watch("searchFile")
    searchFileChaned (v: string | null) {
        clearTimeout(this.searchFileTimeout);
        this.searchFileTimeout = setTimeout(this.searchFiles, 300);
    }

    @Watch("task")
    taskChanged (v: UserTaskModel) {
        if (v != null) {
            this.searchFiles();
        }
    }

    @Watch("activities")
    commentsChanged (v: UserTaskActivityModel[]) {
        this.commentsList = cloneDeep(v);
    }

    get isNewItem(): boolean {
        return this.id == null || this.id == Guid.EmptyGuid();
    }

    get saving(): boolean {
        return tasksStore.useGetter(CrudGetter.Saving);
    }

    get canDelete(): boolean {
        return true;
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get today(): string {
        return moment().format("DD.MM.YYYY");
    }

    get taskFolder(): string | null {
        if (this.task != null) {
            return encodeURIComponent("Tasks/${0}#{1}".format(this.task.id!, this.task.name!));
        }
        else {
            return null;
        }
    }

    get filesToConnect(): FileModel[] {
        return filesStore.useGetter(CrudGetter.Data);
    }

    get activities(): UserTaskActivityModel[] {
        return [...userTaskActivitiesStore.useGetter(CrudGetter.Data, this.id)];
    }

    mounted () {
        if (this.activator) {
            this.dialog = false;
        }
    }

    createItem () {
        this.open(Guid.EmptyGuid());
    }

    open (v: string, loadOverride: any = null) {
        this.id = v;
        this.dialog = true;
        this.pinFile = false;
        this.userTaskActivities = [];
        this.userTaskSolvers = [];
        this.load(loadOverride);
        this.loadActivities();
    }

    createTaskWithState (state: string) {
        this.id = Guid.EmptyGuid();
        this.dialog = true;
        this.load({ taskStateId: state });
    }

    load(v: any = null) {
        this.showFiles = false;
        this.newComment = null;
        this.userTaskActivities = [];
        this.loadOverride = v ?? null;
        if (this.id != null && this.id != Guid.EmptyGuid()) {
            this.loading = true;
            tasksStore.dispatch(CrudAction.Get, { id: this.id, key: this.id });
            this.subscribe(tasksStore, CrudReponse.Get, this.id).then((task: UserTaskModel) => {
                this.id = task.id;
                this.task = task;
                this.userTaskActivities = task.userTaskActivities != null ? task.userTaskActivities?.sort((a: UserTaskActivityModel, b: UserTaskActivityModel) => moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1) : [];
                this.userTaskSolvers = task.userTaskSolvers != null ? task.userTaskSolvers?.orderBy(x => x.party.displayName).toArray() : [];
                this.loading = false;
                this.$nextTick(() => {
                    this.showFiles = true;
                })
            }).catch((e) => {
                this.loading = false;
                console.error(e);
                this.close();
            })
        }
        else {
            const taskStateModels = codebooksStore.useGetter(CrudGetter.Data).filter((e: CodebookModel) => { return e.type == CodebookType.TaskState; }).sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; });
            const taskTypeModels = codebooksStore.useGetter(CrudGetter.Data).filter((e: CodebookModel) => { return e.type == CodebookType.TaskType; }).sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; });
            const userModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
            let value = {
                id: Guid.EmptyGuid(),
                name: this.name,
                taskStateId: taskStateModels.length > 0 ? taskStateModels[0].id : null,
                taskTypeId: taskTypeModels.length > 0 ? taskTypeModels[0].id : null,
                ownerId: userModel.partyId,
                taskDate: "",
                userTaskActivities: [],
                partyId: null,
                subTasks: [],
                userTaskSolvers: [{
                    id: Guid.EmptyGuid(),
                    userTaskId: Guid.EmptyGuid(),
                    partyId: userModel.partyId
                }]
            } as UserTaskModel;
            if (this.loadOverride) {
                Object.assign(value, this.loadOverride);
            }
            this.task = value;
            this.userTaskSolvers = [...value.userTaskSolvers];
        }
    }

    loadActivities () {
        userTaskActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<UserTaskActivityModel>([{field: "createdAt", index: 1, order: "desc"}], [{field: "userTaskId", op: "eq", comparand: this.id!}], undefined, false, this.id!));
    }

    save () {
        if (this.form.validate()) {
            const task = cloneDeep(this.task);
            task.userTaskActivities = this.commentsList;
            task.userTaskSolvers = this.userTaskSolvers;
            task.subTasks = task.subTasks.where(x => x.description != null && x.description.length > 0).toArray();
            if (this.id == null || this.id == Guid.EmptyGuid()) {
                tasksStore.dispatch(CrudAction.Create, { item: task });
                const self = this;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === tasksStore.getActionName(CrudResponseAction.CreateSuccess)) {
                        subscribtion();
                        this.open(mutation.payload.item.id);
                        self.$emit("updated");
                    }
                    else if (mutation.type === tasksStore.getActionName(CrudResponseAction.CreateFailed)) {
                        subscribtion();
                    }
                });
            }
            else {
                tasksStore.dispatch(CrudAction.Update, { item: task });
                const self = this;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === tasksStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                        subscribtion();
                        self.$emit("updated");
                    }
                    else if (mutation.type === tasksStore.getActionName(CrudResponseAction.UpdateFailed)) {
                        subscribtion();
                        self.close();
                    }
                });
            }
        }
    }

    deleteUserTask () {
        tasksStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [ { field: "id", op: "eq", comparand: this.id! } ]));
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === tasksStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                subscribtion();
                self.close();
            }
            else if (mutation.type === tasksStore.getActionName(CrudResponseAction.DeleteFailed)) {
                subscribtion();
            }
        });
    }

    close () {
        this.dialog = false;
        this.id = null;
        this.task = null;
    }

    createComment () {
        const newItem = {
            id: Guid.EmptyGuid(),
            description: this.newComment,
            fileId: this.id,
            createdById: this.user.partyId,
            createdBy: this.user,
            createdAt: moment().format("YYYY-MM-DDTHH:mm:ss"),
            userTaskId: this.id == null ? Guid.EmptyGuid() : this.id
        } as UserTaskActivityModel;
        if (this.isNewItem) {
            this.commentsList.splice(0, 0, newItem);
            this.newComment = null;
        }
        else {
            userTaskActivitiesStore.dispatch(CrudAction.Create, { item: newItem, key: this.id });
            this.creatingComment = true;
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === userTaskActivitiesStore.getActionName(CrudResponseAction.CreateSuccess)) {
                    subscribtion();
                    self.newComment = null;
                    this.creatingComment = false;
                }
                else if (mutation.type === userTaskActivitiesStore.getActionName(CrudResponseAction.CreateFailed)) {
                    subscribtion();
                    this.creatingComment = false;
                }
            });
        }
    }

    deleteComment (item: UserTaskActivityModel) {
        if (item.id == Guid.EmptyGuid()) {
            this.commentsList.splice(this.commentsList.indexOf(item), 1);
        }
        else {
            userTaskActivitiesStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [{ field: "id", op: "eq", comparand: item.id! }], undefined, false, this.id!));
            const self = this;
            this.deletingComment = item!.id!;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === userTaskActivitiesStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                    subscribtion();
                    self.deletingComment = null;
                }
                else if (mutation.type === userTaskActivitiesStore.getActionName(CrudResponseAction.DeleteFailed)) {
                    subscribtion();
                    console.log("Comment update failed");
                    self.deletingComment = null;
                }
            });
        }
    }

    editComment (item: UserTaskActivityModel) {
        this.itemToEdit = item.id!;
    }

    saveComment () {
        if (this.itemToEdit != null) {
            if (this.itemToEdit == Guid.EmptyGuid()) {
                this.itemToEdit = "";
            }
            else {
                this.savingComment = this.itemToEdit;
                const item = this.commentsList.find(x => x.id == this.itemToEdit);
                userTaskActivitiesStore.dispatch(CrudAction.Update, { item: item, key: this.id });
                const self = this;
                this.savingComment = item!.id!;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === userTaskActivitiesStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                        subscribtion();
                        self.itemToEdit = null;
                        this.savingComment = null;
                    }
                    else if (mutation.type === userTaskActivitiesStore.getActionName(CrudResponseAction.UpdateFailed)) {
                        subscribtion();
                        console.log("Comment update failed");
                        this.savingComment = null;
                    }
                });
            }
        }
    }

    uploadFiles () {
        this.cloudFiles.uploadFiles();
    }

    archiveTaskClick () {
        this.archiveTaskDialog = true;
    }
    
    archiveTask () {
        const task = {...this.task};
        task.isArchived = !task.isArchived;
        tasksStore.dispatch(CrudAction.Update, { item: task, id: this.task.id });
        const self = this;
        this.archivingTask = true;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === tasksStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                subscribtion();
                self.archivingTask = false;
                self.archiveTaskDialog = false;
                self.task.isArchived = task.isArchived;
                self.$emit("updated");
            }
            else if (mutation.type === tasksStore.getActionName(CrudResponseAction.UpdateFailed)) {
                self.archivingTask = false;
                subscribtion();
            }
        });
    }
    
    deleteTaskClick () {
        this.deleteTaskDialog = true;
    }

    deleteTask () {
        tasksStore.dispatch(CrudAction.Delete, createCrudQueryPayload<PartyModel>(undefined, [ { field: "id", op: "eq", comparand: this.task.id! } ]));
        const self = this;
        this.deletingTask = true;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === tasksStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                subscribtion();
                self.deletingTask = false;
                self.close();
                self.$emit("updated");
            }
            else if (mutation.type === tasksStore.getActionName(CrudResponseAction.DeleteFailed)) {
                self.deletingTask = false;
                subscribtion();
            }
        });
    }

    searchFiles () {
        var query = [
            {
                field: "id",
                op: "wrap",
                junction: "and",
                comparand: [
                    {field: "folder.entityType", op: "eq", comparand: "Root", junction: "or"}
                ]
            }
        ] as any;
        if (this.task.partyId != null) {
            query[0].comparand.push({field: "partyId", op: "eq", comparand: this.task.partyId!, junction: "or" });
        }
        if (this.searchFile != null && this.searchFile.length > 0)
            query.push({field: "fileName", op: "c", comparand: this.searchFile!, junction: "and" });
        filesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<FileModel>([{field: "fileName", index: 1, order: "asc" }], query));
    }

    attachFile (file: FileModel) {
        const fileCopyModel = {
            fileId: file.id,
            copyToFolderId: this.task.id
        } as FileCopyModel;
        this.attachingFiles.push(file.id!);
        ApiService.api.azureCopy(fileCopyModel).then((e: any) => {
            this.pinFile = false;
            this.cloudFiles.refresh();
            this.attachingFiles.splice(this.attachingFiles.indexOf(file.id!), 1);
        }).catch((e: any) => {
            this.attachingFiles.splice(this.attachingFiles.indexOf(file.id!), 1);
            throw e;
        });
    }

    textAreaToHtml (value : string, length = 0) {
        if (value) {
            value = value.replaceAll("\n", "<br />");
        }
        if (value) {
            if (length > 0) {
                if (value.length > length) {
                    value = value.substring(0, length).concat("...");
                }
            }
        }
        return value;
    }

    addUserTaskSolver () {
        this.userTaskSolvers!.push({
            id: Guid.EmptyGuid(),
            partyId: null,
            userTaskId: this.task!.id!
        } as UserTaskSolverModel)
    }

}
