import {RouteConfig} from 'vue-router';
import Tasks from './pages/Tasks.vue';

export default [
    {
        path: '/tasks',
        name: 'Tasks',
        component: Tasks
    }
] as RouteConfig[];
