























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';


@Component({
  components: {
   
  }
})
export default class Toast extends Vue {

    innerValue = false;
    timeOut = 8000;

    @Prop() value!: boolean;
    @Prop() title!: string | null;
    @Prop() content!: string | null;
    @Prop() cssClass: string | undefined;
    
    mounted () {
        this.innerValue = this.value;
    }

    @Watch("innerValue")
    innerValueChanged(v: boolean, p: boolean) {
        if (v == p)
            return;
        this.$emit("input", v);
        //if (v)
            //this.setValueToFalse();
    }

    @Watch("value")
    valueChanged(v: boolean, p: boolean) {
        if (v == p)
            return;
        
        this.innerValue = v;
    }

    setValueToFalse() {
        this.innerValue = false;
    }

}
