



























import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({ })
export default class NDialog extends Vue {

    dialog = null;
    targetDiv = null;

    @VModel() show!: boolean;

    @Prop() header!: string;
    @Prop() fullscreen!: boolean;
    @Prop() fixed!: boolean;
    @Prop() hideHeader!: boolean;
    @Prop({ default: '600' }) width!: string;
    @Prop({ default: true }) closeOnEscape!: boolean;
    @Prop({ default: true }) closeButton!: boolean;
    
    closeClick () {
      this.$emit("input", false);
    }

}
