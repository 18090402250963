


































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';

@Component
export default class CloudNewFolderDialog extends Vue {

  show = false;
  valid = false;
  name: string | null = null;
  basicRules = [
    (v: any) => !!v || 'Toto pole je povinné.'
  ];

  @Ref() form!: any;

  open () {
      this.name = "";
      this.show = true;
  }

  addFolderClick () {
      this.$emit("addFolder", this.name);
      this.show = false;
  }
    
}
