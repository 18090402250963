import {RouteConfig} from 'vue-router';
import Calendar from './pages/Calendar.vue';

export default [
    {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar
    }
] as RouteConfig[];
