


















































































































import {
	GroupByProjectionDefinition, PartyModel,
	ProjectionAggregationEnum,
	QueryOperator, ShareModel,
	TransactionModel,
	WalletModel,
	WalletTransactionModel,
	WalletTransactionTypeEnum
} from '@/libs/Api';
import {Component, Ref, Watch} from 'vue-property-decorator';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload, createGrouppedQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';

import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Constants} from '@/libs/constants/constants';
import {walletTransactionsStore} from '@/libs/wallet-transactions/+state/store';
import Page from '@/Page.vue';
import {walletsStore} from "@/libs/wallets/+state/store";
import ApiService from '@/libs/core/api-service';
import {CrudDataStats} from "@/libs/core/+state/models/crud-data-stats";
import {sharesStore} from "@/libs/shares/+state/store";
import {partiesStore} from "@/libs/parties/+state/store";

@Component({
    components: {
        CrudTable
    }
})
export default class ProductNremShares extends Page {

    headers = [
        { text: 'Ev. číslo', align: 'left', sortable: false, value: 'evidenceNumber', type: "text", width: "20%" },
        { text: 'Aktuální majitel', align: 'left', sortable: false, value: 'owner.party.displayName', type: "text", width: "20%" },
        { text: 'Prodáno dne', align: 'left', sortable: false, value: 'soldAt', type: "datetime", width: "20%" }
    ];

    clientId: string | null = null;
	fromId: number | null = null;
	toId: number | null = null;
    since: string | null = null;
    until: string | null = null;
    showFilters = false;
    productId = Constants.NremProductId;
    partyProductId = null;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];

	pageIndex = 1;
	pageSize = 50;


	@Ref() crudTable!: CrudTable;

    @Watch("clientId")
    @Watch("fromId")
    @Watch("toId")
    @Watch("since")
    @Watch("until")
    filtersChanged(): void {
        this.loadData();
    }

	@Watch("pageIndex")
	@Watch("pageSize")
	paginationChanged() {
		this.loadData();
	}

    get items(): ShareModel[] {
		return [...sharesStore.useGetter(CrudGetter.Data)];
    }

    get totalShareCount(): number {
	    const data = sharesStore.useGetter(CrudGetter.GrouppedData, 'TotalCount');
	    return data && data?.length > 0 ? data[0].count : null;
    }

    get totalSoldShareCount(): any {
	    const data = sharesStore.useGetter(CrudGetter.GrouppedData, 'SoldCount');
	    return data && data?.length > 0 ? data[0].count : null;
    }

	get totalFreeShareCount(): any {
		const data = sharesStore.useGetter(CrudGetter.GrouppedData, 'FreeCount');
		return data && data?.length > 0 ? data[0].count : null;
	}

	get totalRows (): number {
		return ({...sharesStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
	}

	get isMobile(): boolean {
		return window.outerWidth <= 1140;
	}

    mounted(): void {
        this.loadData();
    }

    loadData(): void {
        let filters: CrudQueryPredicate[] = [
            { field: "productId", op: "eq", comparand: Constants.NremProductId }
        ];
        filters = filters.concat(this.getFilters());
        sharesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ShareModel>([ { field: "evidenceNumber", index: 1, order: "asc" } ], filters, { pageIndex: this.pageIndex, pageSize: this.pageSize }));
        this.loadGrouppedData();
    }

    getFilters(): CrudQueryPredicate[] {
		let filters: CrudQueryPredicate[] = [];
		if (this.clientId) {
			filters.push({ field: "owner.partyId", op: "eq", comparand: this.clientId });
		}
		if (this.since) {
			filters.push({ field: "soldAt", op: "gte", comparand: this.since });
		}
		if (this.until) {
			filters.push({ field: "soldAt", op: "lte", comparand: this.until });
		}
	    if (this.fromId && !isNaN(this.fromId)) {
		    filters.push({ field: "evidenceNumber", op: "gte", comparand: Number(this.fromId) });
	    }
	    if (this.toId && !isNaN(this.toId)) {
		    filters.push({ field: "evidenceNumber", op: "lte", comparand: Number(this.toId) });
	    }
      return filters;
    }

    loadGrouppedData() {
	    sharesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<WalletTransactionModel>([
            { field: 'productId', op: QueryOperator.Eq, comparand: Constants.NremProductId }
        ].concat(this.getFilters() as any), [], [
            {
                field: "id",
                aggregation: ProjectionAggregationEnum.Count,
                outputField: "count"
            } as GroupByProjectionDefinition
        ], 'TotalCount'));
	    sharesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<WalletTransactionModel>([
		    { field: 'productId', op: QueryOperator.Eq, comparand: Constants.NremProductId },
            { field: 'ownerId', op: QueryOperator.Ne, comparand: null }
        ].concat(this.getFilters() as any), [], [
            {
	            field: "id",
	            aggregation: ProjectionAggregationEnum.Count,
	            outputField: "count"
            } as GroupByProjectionDefinition
        ], 'SoldCount'));
        sharesStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<WalletTransactionModel>([
	        { field: 'productId', op: QueryOperator.Eq, comparand: Constants.NremProductId },
	        { field: 'ownerId', op: QueryOperator.Eq, comparand: null }
        ].concat(this.getFilters() as any), [], [
            {
	            field: "id",
	            aggregation: ProjectionAggregationEnum.Count,
	            outputField: "count"
            } as GroupByProjectionDefinition
        ], 'FreeCount'));
    }

	download () {
		const payload = createCrudQueryPayload<ShareModel>([
			{ field: "evidenceNumber", index: 1, order: "asc" }
		], this.getFilters(), { pageIndex: this.pageIndex, pageSize: 9999999 });
		sharesStore.dispatch(CrudAction.Download, payload);
	}

}
