

















































































































































import {ExchangeRateModel, QueryOperator, TransactionModel} from '@/libs/Api';
import {Component, Ref, Watch} from 'vue-property-decorator';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';

import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import {transactionsStore} from '@/libs/transactions/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Constants} from '@/libs/constants/constants';
import {exchangeRatesStore} from '@/libs/exchange-rates/+state/store';
import Page from '@/Page.vue';
import moment from 'moment';
import {CrudDataStats} from '@/libs/core/+state/models/crud-data-stats';
import {createAggregationQueryPayload} from "@/libs/core/+state/models/aggregation-query-payload";

@Component({
    components: {
        CrudTable
    }
})
export default class ProductWealthTransactions extends Page {

    headers = [
        { text: 'Klient', align: 'left', sortable: false, value: 'party.displayName', type: "text" },
        { text: 'Výše vkladu', align: 'left', sortable: false, value: 'deposit', suffix: "Kč", type: "money" },
        { text: 'Výše výběru', align: 'left', sortable: false, value: 'withdrawal', suffix: "Kč", type: "money" },
        { text: 'Počet podílů', align: 'left', sortable: false, value: 'share', suffix: "", type: "number" },
        { text: 'Kurz', align: 'left', sortable: false, value: 'exchangeRate.rate', suffix: "", type: "number" },
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "date" },
    ];

    clientId: string | null = null;
    since: string | null = null;
    until: string | null = null;
    showFilters = false;
    productId = Constants.WealthProductId;
    loadingExchangeRate = false;
    notAllowedEditedValue = false;
    exchangeRate: ExchangeRateModel | null = null;
    exchangeRates: ExchangeRateModel[] = [];
    pageIndex = 1;
    pageSize = 50;
    
    @Ref() crudTable!: CrudTable;

    @Watch("clientId")
    @Watch("since")
    @Watch("until")
    filtersChanged () {
        this.pageIndex = 1;
        this.loadData();
        this.loadExchangeRates();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged() {
        this.loadData();
    }

    get items(): TransactionModel[] {
        return transactionsStore.useGetter(CrudGetter.Data);
    }

    get shareSum(): number {
        //return this.items?.sum(x => x.share ?? 0) ?? 0;
        return transactionsStore.useGetter(CrudGetter.Sum) ?? 0;
    }

    get investmentSum(): number {
        const exchangeRate = this.exchangeRates?.where(x => moment(x.fromDate).startOf("month").isSameOrBefore(moment().startOf("month"))).orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray().firstOrDefault();
        return (transactionsStore.useGetter(CrudGetter.Sum) ?? 0) * (exchangeRate?.rate ?? 1);
    }

    get totalRows (): number {
        return ({...transactionsStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
    }

    mounted() {
        this.loadData();
        this.loadExchangeRates();
    }

    getPredicates(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [
            { field: "partyProduct.productId", op: "eq", comparand: Constants.WealthProductId }
        ];
        if (this.clientId) {
            filters.push({ field: "partyProduct.partyId", op: "eq", comparand: this.clientId });
        }
        if (this.since) {
            filters.push({ field: "time", op: "gte", comparand: this.since });
        }
        if (this.until) {
            filters.push({ field: "time", op: "lte", comparand: this.until });
        }
        return filters;
    }
    
    loadData () {
        transactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TransactionModel>([ { field: "time", index: 1, order: "desc" } ], this.getPredicates(), { pageIndex: this.pageIndex, pageSize: this.pageSize }));
        this.loadSum();
    }
    
    loadSum(): void {
        transactionsStore.dispatch(CrudAction.GetSum, createAggregationQueryPayload(this.getPredicates(), "share"));
    }

    loadExchangeRates() {
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
          { field: "createdAt", order: "desc", index: 0 }
        ], [
          { field: "productId", op: "eq", comparand: Constants.WealthProductId }
        ]))
        this.subscribe(exchangeRatesStore, CrudReponse.GetAll).then((e: ExchangeRateModel[]) => {
          this.exchangeRates = e;
        }).catch((e) => {
          console.log(e);
        });
    }

    addTransactionClick () {
        this.loadingExchangeRate = true;
        this.crudTable.addItemClick();
    }

    saveItem (e: TransactionModel) {
        if (e.id == Guid.EmptyGuid() || e.id == null) {
            transactionsStore.dispatch(CrudAction.Create, { item: e });
        }
        else {
            transactionsStore.dispatch(CrudAction.Update, { item: e });
        }
    }

    deleteItem (e: TransactionModel) {
        transactionsStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TransactionModel>(undefined, [ { field: "id", op: "eq", comparand: e.id! } ]));
    }

    dateChanged(item: TransactionModel) {
        this.loadingExchangeRate = true;
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>(undefined, [
            { field: 'productId', op: QueryOperator.Eq, comparand: Constants.WealthProductId },
            { field: "fromDate", op: "lte", comparand: item!.time! },
            { field: "toDate", op: "gte", comparand: item!.time! }
        ], undefined, false, "ProductWealthTransactions"));
        this.subscribe(exchangeRatesStore, CrudReponse.GetAll, "ProductWealthTransactions").then((rates: ExchangeRateModel[]) => {
            this.loadingExchangeRate = false;
            this.exchangeRate = rates.firstOrDefault();
            item.exchangeRate = this.exchangeRate;
            item.exchangeRateId = this.exchangeRate?.id;
            this.computeShare(item);
        }).catch((e) => {
            this.loadingExchangeRate = false;
            console.log(e);
        });
    }

    computeShare(item: TransactionModel) {
        if ((item.deposit || item.withdrawal) && this.exchangeRate) {
            item.share = Number(Number((Number(item.deposit ?? 0) - Number(item.withdrawal ?? 0)) / Number(this.exchangeRate.rate)).toFixed(3));
        }
        else {
            item.share = null;
        }
    }

    savePromise(editedItem: TransactionModel): Promise<boolean> {
        this.notAllowedEditedValue = false;
        return new Promise<boolean>((resolve, reject) => {
            transactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TransactionModel>([ { field: "time", index: 1, order: "desc" } ], [
                { field: "partyProductId", op: "eq", comparand: editedItem!.partyProductId }
            ], undefined, false, "TransactionSaveCheck"));
            this.subscribe(transactionsStore, CrudReponse.GetAll, "TransactionSaveCheck").then((e: TransactionModel[]) => {
                const totalMoney = e.where(x => x.id! != editedItem.id!).sum(x => (x.deposit ?? 0) - (x.withdrawal ?? 0));
                const editedMoney = (editedItem.deposit ?? 0) - (editedItem.withdrawal ?? 0);
                if (totalMoney + editedMoney < 0) {
                    resolve(false);
                    this.notAllowedEditedValue = true;
                }
                else {
                    resolve(true);
                }
            });
        });
    }
    
}
