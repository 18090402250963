






































import { SetPasswordModel } from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {Component, Ref, Vue} from 'vue-property-decorator';

@Component
export default class UserSettingsPasswordChange extends Vue {

    saving = false;
    saved = false;
    error = false;
    invalidPasswordAgain = false;
    valid = false;
    user: any = {};
    oldPassword = "";
    password = "";
    passwordAgain = "";
    basicRules = [
        (v: any) => (!!v) || 'Toto pole je povinné.'
    ];
    
    @Ref() form!: any;

    save () {
        if (this.password != this.passwordAgain)
            this.invalidPasswordAgain = true;
        if (this.form.validate() && !this.invalidPasswordAgain) {
            this.saving = true;
            const model = {
                currentPassword: this.oldPassword,
                newPassword: this.password
            } as SetPasswordModel;
            ApiService.api.setPassword(model).then((e: any) => {
                this.saved = e.data.succeeded;
                if (!e.data.succeeded)
                    this.error = true;
                this.saving = false;
            }).catch((reason: any) => {
                this.saving = false;
                throw reason;
            });
        }
    }

}
