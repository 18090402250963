









































import { PartyModel, StateOfCapitalModel, TransactionModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { stateOfCapitalStore } from '@/libs/state-of-capital/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Constants } from '@/libs/constants/constants';

@Component({
    components: {
        CrudTable
    }
})
export default class ProductReOpportunitiesStateOfCapital extends Vue {

    headers = [
        { text: 'Datum', align: 'left', sortable: false, value: 'date', type: "date" },
        { text: 'Objem vkladů', align: 'left', sortable: false, value: 'value', suffix: "Kč", type: "money" },
        { text: 'Hodnota investic', align: 'left', sortable: false, value: 'investmentValue', suffix: "", type: "money" },
        { text: 'Zhodnocení', align: 'left', sortable: false, value: 'evaluation', suffix: "", type: "text", readonly: true }
    ];
    defaultItem = { id: Guid.EmptyGuid()!, value: undefined, date: undefined, productId: Constants.ReOpportunitiesId.toString() } as StateOfCapitalModel;

    @Ref() crudTable!: CrudTable;

    get items(): any[] {
        const items = stateOfCapitalStore.useGetter(CrudGetter.Data) as StateOfCapitalModel[];
        return items.map(x => {
            var e = {...x} as any;
            e.evaluation = this.evaluation(x.value, x.investmentValue);
            return e;
        });
    }

    mounted() {
        this.loadData();
    }

    evaluation (value: number | undefined, investmentValue: number | undefined) {
        if (value && investmentValue && value != null && investmentValue != null && Number(investmentValue) > 0) {
            return ((Number(investmentValue) / Number(value) - 1) * 100).toFixed(2) + " %";
        }
        return "--";
    }

    loadData () {
        stateOfCapitalStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<StateOfCapitalModel>([ { field: "date", index: 1, order: "desc" } ], [ { field: "productId", op: "eq", comparand: Constants.ReOpportunitiesId } ], undefined, false));
    }

    addStateOfCapital () {
        this.crudTable.addItemClick();
    }

    saveItem (e: TransactionModel) {
        if (e.id == Guid.EmptyGuid()) {
            stateOfCapitalStore.dispatch(CrudAction.Create, { item: e });
        }
        else {
            stateOfCapitalStore.dispatch(CrudAction.Update, { item: e });
        }
    }

    deleteItem (e: TransactionModel) {
        stateOfCapitalStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TransactionModel>(undefined, [ { field: "id", op: "eq", comparand: e.id! } ], undefined, false));
    }
    
}
