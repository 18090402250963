





























































































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Guid } from '@/libs/common/functions/guid';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { ApplicationUserModel, FileActivityModel, FileModel } from '@/libs/Api';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { filesStore, fileActivitiesStore } from '@/libs/files/+state/store';
import ApiService from "@/libs/core/api-service"

import ConsultantSelect from '@/libs/common/components-smart/ConsultantSelect.vue';
import CloudFiles from '@/libs/cloud/components/CloudFiles.vue';

import moment from 'moment';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { cloneDeep } from 'lodash';
import { getAccessToken } from 'axios-jwt';
import axios from 'axios';

@Component({
    components: { ConsultantSelect, CloudFiles }
})
export default class CloudFileDetailDialog extends Vue {

  show = false;
  valid = false;
  basicRules = [
    (v: any) => !!v || 'Toto pole je povinné.'
  ];
  newComment: string | null = null;
  comments: Array<any> = [];
  id: string | null = null;
  fileActivities: FileActivityModel[] = [];
  itemToEdit: string | null = null;
  creating = false;
  saving: string | null = Guid.EmptyGuid();
  deleting: string | null = Guid.EmptyGuid();
  commentsList: FileActivityModel[] = [];
  downloading = false;
  openingInNewTab = false;
  downloadingErrorDialog = false;
  
  @Watch("show")
  showChanged(v: boolean) {
    if (!v)
      this.close();
  }

  @Prop({ default: true }) redirect!: boolean;
  @Ref() form!: any;
  @Ref() cloudFiles!: CloudFiles;

  get user(): ApplicationUserModel {
    return applicationUserStore.useGetter(CrudGetter.Detail);
  }

  get today(): string {
    return moment().format("DD.MM.YYYY");
  }

  get item() : FileModel {
    return {...filesStore.useGetter(CrudGetter.Detail, this.id)} as FileModel;
  }

  get activities(): FileActivityModel[] {
    return [...fileActivitiesStore.useGetter(CrudGetter.Data, this.id)];
  }

  get savingFile() {
    return filesStore.useGetter(CrudGetter.Saving, this.id);
  }

  get canOpenInNewWindow() {
    const file = {...filesStore.useGetter(CrudGetter.Detail, this.id)} as FileModel;
    if (file) {
      const allowedOpenExtensions = [ 'pdf', 'png', 'jpg', 'jpeg', 'bmp' ];
      const fileExtension = file.fileName?.split('.').pop()?.toLowerCase() ?? "";
      if (allowedOpenExtensions.contains(fileExtension)) {
          return true;
      }
    }
    return false;
  }
  
  @Watch("activities")
  commentsChanged (v: FileActivityModel[]) {
      this.commentsList = cloneDeep(v);
  }

  open (id: string) {
    debugger;
      this.show = true;
      this.id = id;
      filesStore.dispatch(CrudAction.Get, { id: id, key: this.id });
      this.loadActivities();
  }

  save () {
    if (this.form.validate()) {
      filesStore.dispatch(CrudAction.Update, { item: this.item, key: this.id });
    }
  }

  close () {
    this.show = false;
  }

  uploadFiles () {
    this.cloudFiles.uploadFiles();
  }
  
  loadActivities () {
    fileActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<FileActivityModel>([{field: "createdAt", index: 1, order: "desc"}], [{field: "fileId", op: "eq", comparand: this.id!}], undefined, false, this.id!));
  }

  createComment () {
      const newItem = {
          id: Guid.EmptyGuid(),
          description: this.newComment,
          fileId: this.id,
          createdById: this.user.partyId,
          createdBy: this.user,
          createdAt: moment().format("YYYY-MM-DDTHH:mm:ss"),
          userTaskId: this.id == null ? Guid.EmptyGuid() : this.id
      } as FileActivityModel;
      fileActivitiesStore.dispatch(CrudAction.Create, { item: newItem, key: this.id });
      this.creating = true;
      const self = this;
      const subscribtion = this.$store.subscribe((mutation, _state) => {
        if (mutation.type === fileActivitiesStore.getActionName(CrudResponseAction.CreateSuccess)) {
          subscribtion();
          self.newComment = null;
          this.creating = false;
        }
        else if (mutation.type === fileActivitiesStore.getActionName(CrudResponseAction.CreateFailed)) {
          subscribtion();
          this.creating = false;
        }
      });
    }

    deleteComment (item: FileActivityModel) {
      fileActivitiesStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [{ field: "id", op: "eq", comparand: item.id! }], undefined, false, this.id!));
      const self = this;
      this.deleting = item!.id!;
      const subscribtion = this.$store.subscribe((mutation, _state) => {
        if (mutation.type === fileActivitiesStore.getActionName(CrudResponseAction.DeleteSuccess)) {
          subscribtion();
          self.deleting = null;
        }
        else if (mutation.type === fileActivitiesStore.getActionName(CrudResponseAction.DeleteFailed)) {
          subscribtion();
          console.log("Comment update failed");
          self.deleting = null;
        }
      });
    }

    editComment (item: FileActivityModel) {
      this.itemToEdit = item.id!;
    }

    saveComment () {
      if (this.itemToEdit != null) {
        this.saving = this.itemToEdit;
        const item = this.commentsList.find(x => x.id == this.itemToEdit);
        fileActivitiesStore.dispatch(CrudAction.Update, { item: item, key: this.id });
        const self = this;
        this.saving = item!.id!;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === fileActivitiesStore.getActionName(CrudResponseAction.UpdateSuccess)) {
            subscribtion();
            self.itemToEdit = null;
            this.saving = null;
          }
          else if (mutation.type === fileActivitiesStore.getActionName(CrudResponseAction.UpdateFailed)) {
            subscribtion();
            console.log("Comment update failed");
            this.saving = null;
          }
        });
      }
    }
    
    textAreaToHtml (value : string, length = 0) {
        if (value) {
            value = value.replaceAll("\n", "<br />");
        }
        if (value) {
            if (length > 0) {
                if (value.length > length) {
                    value = value.substring(0, length).concat("...");
                }
            }
        }
        return value;
    }

    download () {
      let fdata: FormData = new FormData();
      fdata.append('downloadInput', JSON.stringify({
          action: "download",
          data: [
              {
                id: this.item.id,
                isFile: true
              }
          ],
          names: [
              this.item.id
          ],
          path: ""
      }));
      
      const baseUrl = (window as any).ApiService.baseUrl;
      const token = getAccessToken();

      this.downloading = true;
      axios({
        url: baseUrl + '/api/files/download',
        method: 'POST',
        responseType: 'blob', // important
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": "Bearer " + token
        },
        data: fdata
      }).then((response) => {
        this.downloading = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.item.fileName);
        document.body.appendChild(link);
        link.click();
      }).catch((e) => {
        console.error(e);
        this.downloading = false;
        this.downloadingErrorDialog = true;
      });
    }

    openInNewTab() {
      let fdata: FormData = new FormData();
      fdata.append('downloadInput', JSON.stringify({
          action: "download",
          data: [
            {
              id: this.item.id,
              isFile: true
            }
          ],
          names: [
              this.item.id
          ],
          path: ""
      }));

      const baseUrl = (window as any).ApiService.baseUrl;
      const token = getAccessToken();

      this.openingInNewTab = true;
      axios({
          url: baseUrl + '/api/files/prepare-download',
          method: 'POST',
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Authorization": "Bearer " + token
          },
          data: fdata
      }).then((response) => {
          window.open(ApiService.baseUrl + "/api/cache-download/" + response.data, "_blank");
          this.openingInNewTab = false;
      }).catch((e) => {
        console.error(e);
        this.openingInNewTab = true;
      });
    }

}
