
export enum AuthAction {
    SignIn = 'SIGN_IN',
    SignInResponseSuccess = 'SIGN_IN_RESPONSE_SUCCESS',
    SignInResponseFailed = 'SIGN_IN_RESPONSE_FAILED',
    SignOut = 'SIGN_OUT',
    ActivateAccount = 'ACTIVATE_ACCOUNT',
    ActivateAccountResponse = 'ACTIVATE_ACCOUNT_RESPONSE',
    ResetPassword = 'RESET_PASSWORD',
    ResetPasswordResponse = 'RESET_PASSWORD_RESPONSE',
    ResetSetNewPassword = 'RESET_SET_NEW_PASSWORD',
    SetPassword = 'SET_PASSWORD',
    SetPasswordResponse = 'SET_PASSWORD_RESPONSE',
    SetNewPassword = 'SET_NEW_PASSWORD',
    ResetSetNewPasswordResponse = 'RESET_SET_NEW_PASSWORD_RESPONSE',
    UserInfo = 'USER_INFO'
}

export enum AuthGetter {
    GetIsLoggedIn = 'GET_IS_LOGGED_IN',
    GetUserInfo = 'GET_USER_INFO'
}

export class AuthState {
    isLoggedIn = false;
    userInfo: any = null;
    tenant: string | null = null;
}