





















































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { FileManagerPlugin, DetailsView, NavigationPane, Toolbar, BeforeDownloadEventArgs, FileLoadEventArgs, MenuOpenEventArgs, FileOpenEventArgs, NavigationPaneSettingsModel, FileManagerComponent, MenuClickEventArgs, FileSelectEventArgs } from "@syncfusion/ej2-vue-filemanager";
Vue.use(FileManagerPlugin);

import ApiService from "@/libs/core/api-service"
import {getAccessToken} from 'axios-jwt'
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import axios from 'axios';
import fileDownload from 'js-file-download';

import CloudFileDetailDialog from '@/libs/cloud/components/CloudFileDetailDialog.vue';
import CloudNewFolderDialog from '@/libs/cloud/components/CloudNewFolderDialog.vue';
import CloudFileRenameDialog from '@/libs/cloud/components/CloudFileRenameDialog.vue';

import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { FolderModel } from '@/libs/Api';

import { loadCldr,L10n } from "@syncfusion/ej2-base";

L10n.load({
    "en": {
        "filemanager": {
            "Rename": "Přejmenovat",
        }
    }
});

const pause = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

@Component({
    provide: {
        filemanager: [DetailsView, NavigationPane, Toolbar]
    },
    components: {
        CloudFileDetailDialog, CloudNewFolderDialog
    }
})
export default class CloudFiles extends Vue {
  
    ajaxSettings = { 
        url: (window as any).ApiService.baseUrl + "/api/files/operations",
        getImageUrl: (window as any).ApiService.baseUrl + "/api/files/image",
        uploadUrl: (window as any).ApiService.baseUrl + "/api/files/upload",
        downloadUrl: (window as any).ApiService.baseUrl + "/api/files/download"
    };
    detailsViewSettings = {

    };
    contextMenuSettings = {
        //file: [ 'Open', 'Download', '|', 'Delete' ],
        file: [ 'Detail', '|', 'Otevřít v novém okně', 'Stáhnout', '|', 'Smazat' ],
        folder: [ "Stáhnout", '|', 'Přejmenovat složku', 'Smazat složku' ],
        //folder: [ 'Delete', 'Rename' ],
        layout: [],
        visible: true
    };
    typeFilter: string | null = null;
    ownerFilter: string | null = null;
    partyFilter: string | null = null;
    uploadSettings = {
        autoUpload: true, 
        minFileSize: 0, 
        maxFileSize: 200000000, 
        autoClose: false
    }
    
    active = [];
    items = [];
    open = [];
    deleteFolderDialog = false;
    fileDetail: any = null;
    renameFileDialog = false;
    newFileName = "";
    uploadingFile = false;
    uploadedFileDialog = false;
    uploadedFile: any = null;
    id: string | null = null;
    parentalPath = "";
    downloadingFolder = false;
    downloadingErrorDialog = false;
    
    get isRootFolder() {
        return this.fileManager?.ej2Instances?.pathNames.length <= 1;
    }

    get navigationPaneSettings(): NavigationPaneSettingsModel {
        return {
            visible: true
        } as NavigationPaneSettingsModel;
    }

    get allowMultiselection() {
        //return window.innerWidth > 991;
		return false;
    }

    @Prop() path!: string;
    @Prop() partyId!: string;
    @Prop() taskId!: string;
    @Prop({ default: true }) displayHeader!: boolean;
    @Prop({ default: true }) showNavigation!: boolean;
    @Prop({ default: "LargeIcons" }) view!: string;

    @Ref() fileManager!: FileManagerComponent;
    @Ref() detailDialog!: CloudFileDetailDialog;
    @Ref() newFolderDialog!: CloudNewFolderDialog;

    beforeSend (args: any) {

        const token = getAccessToken();

        let folder = this.path ?? "Root";
        const data = JSON.parse(args.ajaxSettings.data);
        
        if (data.length > 2 && data[2].data) {

            const innerData = data.length > 2 && data[2].data ? JSON.parse(data[2].data) : null;

            if (this.partyId)
                innerData.partyId = this.partyId;

            if (this.taskId)
                innerData.taskId = this.taskId;

            data[2].data = JSON.stringify(data[2].data);
            
        }
        
        if (Array.isArray(data) && data.find((x: any) => x.action != undefined && (x.action == "save" || x.action == "keepboth"))) {
            const pathData = data.find((x: any) => x.path != undefined);
            folder += "/" + pathData.path.replace("\\", "/");
            if (folder.endsWith("/"))
                folder = folder.substring(0, folder.length - 1);
        }

        args.ajaxSettings.data = JSON.stringify(data);
        
        args.ajaxSettings.beforeSend = function(args: any) {
            args.httpRequest.setRequestHeader("Authorization", "Bearer " + token);
            args.httpRequest.setRequestHeader("Folder", folder);
        }

        if (args.action == "Upload") {
            this.uploadingFile = true;
        }

    }

    menuOpen (args: MenuOpenEventArgs) {
        if (args.target.closest(".e-navigation")) {
            args.cancel = true;
        }
        else {
            const menuElement = args.element as HTMLElement;
            const fileDetails = args.fileDetails as any;
            if (fileDetails.filter((x: any) => x.id != null && x.id.startsWith("$")).length > 0) {
                menuElement.classList.add("system-folder");
            }
            else {
                menuElement.classList.remove("system-folder");
            }
            if (!fileDetails.any(x => x.path != null)) {
                args.cancel = true;
            }
            if (this.fileManager.getSelectedFiles().count() > 1) {
                args.items = args.items.where(x => x.text == "Stáhnout").toArray();
            }
            const allowedOpenExtensions = [ '.pdf', '.png', '.jpg', '.jpeg', '.bmp' ];
            if (!fileDetails.firstOrDefault() || !allowedOpenExtensions.contains(fileDetails.first().type)) {
                args.items = args.items.where(x => x.text !== "Otevřít v novém okně").toArray();
            }
        }
    }

    openFileInNewTab (fileDetail: any) {
        
        let fdata: FormData = new FormData();
        fdata.append('downloadInput', JSON.stringify({
            action: "download",
            data: [
                fileDetail
            ],
            names: [
                fileDetail.id
            ],
            path: this.path
        }));

        const baseUrl = (window as any).ApiService.baseUrl;
        const token = getAccessToken();

        axios({
            url: baseUrl + '/api/files/prepare-download',
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + token
            },
            data: fdata
        }).then((response) => {
            window.open(ApiService.baseUrl + "/api/cache-download/" + response.data, "_blank");
        });

    }

    downloadFile (fileDetail: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            let fdata: FormData = new FormData();
            fdata.append('downloadInput', JSON.stringify({
                action: "download",
                data: [
                    fileDetail
                ],
                names: [
                    fileDetail.id
                ],
                path: this.path
            }));
            
            const baseUrl = (window as any).ApiService.baseUrl;
            const token = getAccessToken();

            axios({
                url: baseUrl + '/api/files/download',
                method: 'POST',
                responseType: 'blob', // important
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + token
                },
                data: fdata
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (fileDetail.isFile) {
                    link.setAttribute('download', fileDetail.name);
                }
                else {
                    link.setAttribute('download', fileDetail.name + ".zip");
                }
                document.body.appendChild(link);
                link.click();
                resolve(true);
            }).catch((ex) => {
                debugger;
                this.downloadingErrorDialog = true;
                reject(ex);
            });

        });
    }

    fileOpen (args: FileOpenEventArgs) {
		const fileDetail = args?.fileDetails as any;
        if (fileDetail.isFile) {
            if (!this.isMobile()) {
                args.cancel = true;
                this.openFileInNewTab(args);
            }
            else {
                args.cancel = true;
                const fileDetail = args.fileDetails as any;
                const fileDetailId = fileDetail.id;
                this.detailDialog.open(fileDetailId);
            }
        }
        else {
            const folders = this.fileManager.ej2Instances.path.split("/").where(x => x != "").toArray();
            if (folders.length > 1) {
                this.parentalPath = folders[folders.length - 2];
            }
            else {
                this.parentalPath = "Root";
            }
        }
    }

    fileSelect(args: FileSelectEventArgs) {
        if (window.innerWidth <= 991) {
            const fileDetails = args.fileDetails as any;
            if (fileDetails.isFile) {
                this.fileManager.ej2Instances.openFile(fileDetails.id);
            }
        }
    }

    menuClick(args: MenuClickEventArgs) {
        if (args.item.text == "Detail") {
            args.cancel = true;
            if (args.fileDetails.length > 0) {
                const fileDetail = args.fileDetails[0] as any;
                this.detailDialog.open(fileDetail.id);
            }
        }
        else if (args.item.text == "Otevřít v novém okně") {
            args.cancel = true;
            if (args.fileDetails.length > 0) {
                const fileDetail = args.fileDetails[0] as any;
                this.openFileInNewTab(fileDetail);
            }
        }
        else if (args.item.text == "Stáhnout") {
            args.cancel = true;
            if (args.fileDetails.length > 0) {
                for (const fileDetail of args.fileDetails) {
                    this.downloadFile(fileDetail);
                }
            }
        }
        else if (args.item.text == "Smazat") {
            args.cancel = true;
            if (args.fileDetails.length > 0) {
                const fileDetail = args.fileDetails[0] as any;
                this.fileDetail = fileDetail;
                this.deleteFolderDialog = true;
            }
        }
        else if (args.item.text == "Přejmenovat složku") {
            args.cancel = true;
            if (args.fileDetails.length > 0) {
                const fileDetail = args.fileDetails[0] as any;
                this.fileDetail = fileDetail;
                this.renameFileDialog = true;
                this.newFileName = fileDetail.name;
            }
        }
        else if (args.item.text == "Smazat složku") {
            args.cancel = true;
            if (args.fileDetails.length > 0) {
                const fileDetail = args.fileDetails[0] as any;
                this.fileDetail = fileDetail;
                this.deleteFolderDialog = true;
            }
        }
    }

    uploadFiles () {
        this.fileManager.uploadFiles();
    }

    addFileClick () {
        this.uploadFiles();
    }

    refresh () {
        this.fileManager.refreshFiles();
    }

    createFolderClick () {
        this.newFolderDialog.open();
    }

    createFolder (name: string | null) {
        if (name != null && name != "") {
            this.fileManager.createFolder(name);
        }
    }

    deleteFolder () {
        this.deleteFolderDialog = false;
        this.fileManager.deleteFiles(this.fileDetail.id);
    }

    renameFile () {
        this.renameFileDialog = false;
        this.fileManager.renameFile(this.fileDetail.id, this.newFileName);
    }

    success (args: any) {
        if (args.action == "Upload") {
            this.$nextTick(() => {
                this.uploadingFile = false;
                this.uploadedFileDialog = true;
                this.uploadedFile = args.result.file;
            });
        }
    }

    beforePopupOpen (args: any) {
        if (args.popupName == "Upload") {
            args.cancel = true;
        }
    }

    isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    downloadFolderClick () {
        const folderId = this.fileManager.ej2Instances.path.split("/").where(x => x != "").toArray().last();
        this.downloadingFolder = true;
        this.downloadFile({
            id: folderId,
            name: this.fileManager.ej2Instances.pathNames.last()
        }).then(() => {
            this.downloadingFolder = false;
        }).catch(() => {
            this.downloadingFolder = false;
        });
    }

    openParentalFolder () {
        const folders = this.fileManager.ej2Instances.path.split("/").where(x => x != "").toArray();
        const parents = folders.slice(0, folders.length - 1).toArray();
        const folderId = parents.join("/") + "/";
        debugger;
        this.fileManager.ej2Instances.setPath("");
        this.fileManager.ej2Instances.refreshLayout();
    }

    goHome() {
        this.$emit("restart");
    }

}
