





































































































import { PartyModel, PartyProductModel, TransactionModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import ClientAddDialog from '@/libs/clients/components/ClientAddDialog.vue';
import { transactionsStore } from '@/libs/transactions/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Constants } from '@/libs/constants/constants';
import { partyProductsStore } from '@/libs/parties/+state/store';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';

@Component({
    components: {
        CrudTable
    }
})
export default class ProductNoifTransactions extends Vue {

    headers = [
        { text: 'Klient', align: 'left', sortable: false, value: 'party.displayName', type: "text" },
        { text: 'Výše vkladu', align: 'left', sortable: false, value: 'deposit', suffix: "Kč", type: "money" },
        { text: 'Výše výběru', align: 'left', sortable: false, value: 'withdrawal', suffix: "Kč", type: "money" },
        { text: 'Stav kapitálu', align: 'left', sortable: false, value: 'stateOfCapital', suffix: "Kč", type: "money" },
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "date" },
    ];

    clientId: string | null = null;
    since: string | null = null;
    until: string | null = null;
    showFilters = false;
	productId = Constants.NoifProductId;

    pageIndex = 1;
    pageSize = 50;
    
    @Ref() crudTable!: CrudTable;

    @Watch("clientId")
    @Watch("since")
    @Watch("until")
    filtersChanged () {
        this.pageIndex = 1;
        this.loadData();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged() {
        this.loadData();
    }

    get items(): TransactionModel[] {
        return transactionsStore.useGetter(CrudGetter.Data);
    }

    get totalRows (): number {
        return ({...transactionsStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
    }

    mounted() {
        this.loadData();
    }

    loadData () {
        let filters: CrudQueryPredicate[] = [
            { field: "partyProduct.productId", op: "eq", comparand: Constants.NoifProductId }
        ];
        if (this.clientId) {
            filters.push({ field: "partyProduct.partyId", op: "eq", comparand: this.clientId });
        }
        if (this.since) {
            filters.push({ field: "time", op: "gte", comparand: this.since });
        }
        if (this.until) {
            filters.push({ field: "time", op: "lte", comparand: this.until });
        }
        transactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TransactionModel>([ { field: "time", index: 1, order: "desc" } ], filters, { pageIndex: this.pageIndex, pageSize: this.pageSize }));
    }

    addTransactionClick () {
        this.crudTable.addItemClick();
    }

    saveItem (e: TransactionModel) {
        if (e.id == Guid.EmptyGuid() || e.id == null) {
            transactionsStore.dispatch(CrudAction.Create, { item: e });
        }
        else {
            transactionsStore.dispatch(CrudAction.Update, { item: e });
        }
    }

    deleteItem (e: TransactionModel) {
        transactionsStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TransactionModel>(undefined, [ { field: "id", op: "eq", comparand: e.id! } ]));
    }
    
}
