













































import {ExchangeRateModel, ExchangeRateTypeEnum, TransactionModel} from '@/libs/Api';
import {Component, Ref, Vue} from 'vue-property-decorator';
import {CrudAction} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';

import {exchangeRatesStore} from '@/libs/exchange-rates/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Constants} from '@/libs/constants/constants';
import moment from 'moment';

@Component({
	components: {
		CrudTable
	}
})
export default class ProductNremExchangeRates extends Vue {

	headers = [
		{ text: 'Od', align: 'left', sortable: false, value: 'fromDate', type: "date" },
		{ text: 'Do', align: 'left', sortable: false, value: 'toDate', type: "date" },
		{ text: 'Kurz pro zhodnocení účtu', align: 'left', sortable: false, value: 'rate', suffix: "", type: "number" },
		{ text: 'Kurz pro nákup a prodej cenných papírů', align: 'left', sortable: false, value: 'secondaryRate', suffix: "", type: "number" }
	];
	defaultItem = { id: Guid.EmptyGuid()!, fromDate: undefined, toDate: undefined, rate: undefined, productId: Constants.NremProductId.toString() } as ExchangeRateModel;

	@Ref() crudTable!: CrudTable;

	get items(): any[] {
		return exchangeRatesStore.useGetter(CrudGetter.Data, Constants.NremProductId) as ExchangeRateModel[];
	}

	mounted() {
		this.loadData();
	}

	loadData () {
		exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([ { field: "fromDate", index: 1, order: "desc" } ], [ { field: "productId", op: "eq", comparand: Constants.NremProductId } ], undefined, false, Constants.NremProductId));
	}

	addExchangeRate () {
		this.crudTable.addItemClick();
	}

	saveItem (e: ExchangeRateModel) {
		const date = e.fromDate;
		e.type = ExchangeRateTypeEnum.Product;
		e.isPublic = true;
		e.fromDate = moment(date).startOf("month").format("YYYY-MM-DDT00:00:00");
		e.toDate = moment(date).endOf("month").format("YYYY-MM-DDT00:00:00");
		if (e.id == Guid.EmptyGuid()) {
			exchangeRatesStore.dispatch(CrudAction.Create, { item: e, key: Constants.NremProductId });
		}
		else {
			exchangeRatesStore.dispatch(CrudAction.Update, { item: e, key: Constants.NremProductId });
		}
	}

	deleteItem (e: TransactionModel) {
		exchangeRatesStore.dispatch(CrudAction.Delete, createCrudQueryPayload<ExchangeRateModel>(undefined, [ { field: "id", op: "eq", comparand: e.id! } ], undefined, false, Constants.NremProductId));
	}

}
