import {
    HttpResponse,
    UserTaskModel,
    QueryModel,
    RequestParams,
    UserTaskModelIEnumerableApiResultSuccess,
    UserTaskModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    FullRequestParams,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";
import {CrudPartialUpdatePatch} from '@/libs/core/+state/models/crud-partial-update-patch';

export class UserTasksDataService implements CrudService<UserTaskModel, UserTaskModel> {
    
    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error("Method not implemented.");
    }
    
    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error("Method not implemented.");
    }
    
    getAll(query: CrudDataQuery<UserTaskModel>): Promise<CrudEnumerableResponse<UserTaskModel>> {
        return ApiService.api.getUserTaskBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<UserTaskModel>> {
        return ApiService.api.getUserTaskById(id, params);
    }

    create(item: UserTaskModel, params?: RequestParams): Promise<CrudResponse<UserTaskModel>> {
        return ApiService.api.insertUserTask(item, params);
    }

    update(item: UserTaskModel, params?: RequestParams): Promise<UserTaskModel> {
        return ApiService.api.updateUserTask(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<UserTaskModel> {
        return ApiService.api.patchUserTask(id, patches).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<UserTaskModel>): Promise<CrudEnumerableResponse<UserTaskModel>> {
        return ApiService.api.deleteUserTasks(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    download(query: CrudDataQuery<UserTaskModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        return ApiService.api.exportUserTaskBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

}
