





















import {Component, Vue, Prop, Watch, Emit, VModel} from 'vue-property-decorator';

@Component({})
export default class Slider extends Vue {

    @VModel() innerValue: any;
    
    @Prop() items!: Array<any>;
    @Prop() label!: string;
    @Prop() itemText!: string;
    @Prop() itemValue!: string;
    @Prop({ default: true }) enabled!: boolean;
    @Prop() readonly!: boolean;
    @Prop() clearable!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: undefined }) height!: boolean;

}
