import {
    HttpResponse,
    ProductActivityModel,
    QueryModel,
    RequestParams,
    ProductActivityModelIEnumerableApiResultSuccess,
    ProductActivityModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    FullRequestParams,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";

export class ProductActivitiesDataService implements CrudService<ProductActivityModel, ProductActivityModel> {
    
    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error("Method not implemented.");
    }
    
    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error("Method not implemented.");
    }
    
    getAll(query: CrudDataQuery<ProductActivityModel>): Promise<CrudEnumerableResponse<ProductActivityModel>> {
        return ApiService.api.getProductActivityBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<ProductActivityModel>> {
        return ApiService.api.getProductActivityById(id, params);
    }

    create(item: ProductActivityModel, params?: RequestParams): Promise<CrudResponse<ProductActivityModel>> {
        return ApiService.api.insertProductActivity(item, params);
    }

    update(item: ProductActivityModel, params?: RequestParams): Promise<ProductActivityModel> {
        return ApiService.api.updateProductActivity(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<ProductActivityModel> {
        return ApiService.api.patchProductActivity(id, patches).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<ProductActivityModel>): Promise<CrudEnumerableResponse<ProductActivityModel>> {
        return ApiService.api.deleteProductActivities(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    download(query: CrudDataQuery<ProductActivityModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw "NotImplemented";
    }

}
