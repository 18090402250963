




























import ApiService from "@/libs/core/api-service"
import { UpdateLogModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { updateLogsStore } from '@/libs/update-log/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { Constants } from "@/libs/constants/constants";

@Component({
    components: {
        CrudTable
    }
})
export default class ProductNreiTransactions extends Vue {

    downloading = false;
    headers = [
        { text: 'Uživatel', align: 'left', sortable: false, value: 'createdBy.displayName', type: "text" },
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime" },
        { text: 'Stav', align: 'left', sortable: false, value: 'state', type: "text" }
    ];

    @Ref() crudTable!: CrudTable;

    get items(): UpdateLogModel[] {
        return updateLogsStore.useGetter(CrudGetter.Data);
    }

    mounted() {
        this.loadData();
    }

    loadData () {
        updateLogsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<UpdateLogModel>([{ field: "time", index: 1, order: "desc" }], [ { field: "entityId", op: "eq", comparand: Constants.NreiProductId } ]));
    }

    updateData () {
        this.downloading = true;
        ApiService.api.downloadTransactions().then((e) => {
            this.downloading = false;
            this.loadData();
        }).catch((e) => {
            this.downloading = false;
            this.loadData();
        });
        setTimeout((e) => {
            this.loadData();
        }, 2000);
    }

}
