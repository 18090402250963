



























import { PartyProductModel, ApplicationUserModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import NWDialog from '@/libs/clients/components/NWDialog.vue';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import RequestForQuotationDialog from '@/libs/account/components/RequestForQuotationDialog.vue';

@Component({
    components: {
        NWDialog,
        RequestForQuotationDialog
    }
})
export default class ClientNWCard extends Vue {

    @Prop() partyProduct!: PartyProductModel;
    @Prop() clientId!: string;
    @Ref() dialog!: NWDialog;
    @Prop({ default: false }) accountSection!: boolean;
    
    newId: string | null = Guid.EmptyGuid();

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    openPartyProduct() {
        this.dialog.open();
    }

}

