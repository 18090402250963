import {registerCrudStore} from '@/libs/core/+state/register-crud-store';
import {PartyActivityModel, PartyCreditModel, PartyModel, PartyProductModel, PartyProductQuotationModel} from '@/libs/Api';
import {PartiesDataService} from '@/libs/parties/parties-data-service';
import {PartyProductsDataService} from '@/libs/parties/party-products-data-service';
import {PartyActivitiesDataService} from '@/libs/parties/party-comments-data-service';
import {PartyProductQuotationsDataService} from '@/libs/parties/party-product-quotation-data-service';
import {BusinessModule} from '@/libs/core/models/business-module';
import { PartyCreditsDataService } from '../party-credits-data-service';

export const partiesStore = registerCrudStore<PartyModel, PartyModel>(BusinessModule.Party, new PartiesDataService());
export const partyProductsStore = registerCrudStore<PartyProductModel, PartyProductModel>(BusinessModule.PartyProduct, new PartyProductsDataService());
export const partyActivitiesStore = registerCrudStore<PartyActivityModel, PartyActivityModel>(BusinessModule.PartyActivity, new PartyActivitiesDataService());
export const partyProductQuotationsStore = registerCrudStore<PartyProductQuotationModel, PartyProductQuotationModel>(BusinessModule.PartyProductQuotation, new PartyProductQuotationsDataService());
export const partyCreditsStore = registerCrudStore<PartyCreditModel, PartyCreditModel>(BusinessModule.PartyCredit, new PartyCreditsDataService());
