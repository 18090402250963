






































/* eslint-disable no-mixed-spaces-and-tabs */

import { CodebookModel, CodebookType, PartyModel, PartyProductModel, ProductTypeModel } from '@/libs/Api';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { isArray } from 'lodash';
import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import ClientAddDialog from '@/libs/clients/components/ClientAddDialog.vue';
import Autocomplete from '../components/Autocomplete.vue';
import { Constants } from '@/libs/constants/constants';

@Component({
    components: {
        ClientAddDialog
    }
})
export default class PartySelect extends Vue {

    @VModel() innerValue!: string;
    @Prop({ default: "" }) label!: string;
    @Prop() type!: string | Array<string>;
    @Prop() readonly!: boolean;
    @Prop() rules!: any[];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) createParty!: boolean;
    @Prop({ default: Constants.NoifProductId }) productId!: string;

    @Ref() clientAddDialog!: ClientAddDialog;
    
    get items(): PartyProductModel[] {
        const key = isArray(this.type) ? this.type.join(";") : this.type;
        const items = partyProductsStore.useGetter(CrudGetter.Data, key) as PartyProductModel[];
        return items.orderBy(x => x.party!.displayName!).thenBy(x => x.investmentStrategy?.name).toArray();
    }

    mounted() {
        this.loadItems();
    }

    loadItems() {
        const key = isArray(this.type) ? this.type.join(";") : this.type;
        partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyProductModel>([
        ], [
            {
                field: "productId",
                op: "eq",
                comparand: this.productId
            },
	        {
		        field: "party.isDeleted",
		        op: "eq",
		        comparand: false
	        }
        ], undefined, false, key));
    }

}
