























































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { KanbanColumn, KanbanColumnWithItems } from './Kanban/KanbanColumn';
import draggable from 'vuedraggable';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

@Component({
    components: {
        draggable, Swiper, SwiperSlide
    },
    directives: {
      swiper: directive
    }
})
export default class Kanban extends Vue {

    columnsWithItems: KanbanColumnWithItems[] = [];
    swiperOptions = {};

    @Prop() columns!: KanbanColumn[];
    @Prop() items!: Array<any>;
    @Prop({ default: true }) enableSameColumn!: boolean;

    @Watch("columns")
    onColumnChanged(v: KanbanColumn[]) {
      this.tranformColumns();
      this.transformItems();
    }

    @Watch("items", { immediate: true, deep: true })
    onItemsChanged() {
        this.transformItems();
    }

    mounted () {
      this.tranformColumns();
      this.transformItems();
    }

    tranformColumns () {
      this.columnsWithItems = this.columns.map((e: KanbanColumn) => {
        return {
          name: e.name,
          color: e.color,
          propertyName: e.propertyName,
          propertyValue: e.propertyValue,
          items: []
        } as KanbanColumnWithItems
      });
    }

    transformItems () {
      this.columnsWithItems.forEach((e: KanbanColumnWithItems) => {
        e.items = this.items.filter((x: any) => {
          return typeof x[e.propertyName!] != "undefined" && x[e.propertyName!] == e.propertyValue;
        });
      });
    }

    onEdit(item: any) {
        console.log("onEdit", item);
    }

    onDelete(item: any) {
        console.log("onDelete", item);
    }

    change (evt: any, column: KanbanColumnWithItems) {
      if (evt.added) {
        const item = evt.added.element;
        item[column.propertyName!] = column.propertyValue;
        this.$emit("change", item);
      }
    }

    footerButtonClick (column: KanbanColumn) {
      this.$emit("footerButtonClick", column.propertyValue);
    }

    move (evt, originalEvent) {
      if (!this.enableSameColumn && evt.from == evt.to) {
        return false;
      }
    }

}
