




































































































































































































































































































































































































































import { EventModel, CodebookModel, CodebookType, ProductTypeModel, ApplicationUserModel, UserTaskModel, NotificationModel, EventParticipantModel, PartyModel } from '@/libs/Api';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { eventsStore } from '@/libs/events/+state/store';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import CodebookSelect from '@/libs/common/components-smart/CodebookSelect.vue';
import EventDialog from '@/libs/events/components/EventDialog.vue';
import TaskDialog from '@/libs/tasks/components/TaskDialog.vue';
import NotificationDialog from '@/libs/notifications/components/NotificationDialog.vue';
import moment from 'moment';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { tasksStore } from '@/libs/tasks/+state/store';
import { notificationsStore } from '@/libs/notifications/+state/store';
import { Guid } from '@/libs/common/functions/guid';
import { useObjectKey } from '@/libs/common/functions/use-object-key';
import { CrudPartialUpdatePatch } from '@/libs/core/+state/models/crud-partial-update-patch';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import Page from '@/Page.vue';
@Component({
    components: {
        CodebookSelect,
        EventDialog,
        TaskDialog,
        NotificationDialog,
        Swiper, 
        SwiperSlide
    },
    directives: {
      swiper: directive
    }
})
export default class Dashboard extends Page {

    currentDate = moment().toDate();
    taskId: string | null = null;
    notificationId: string | null = null;
    eventId: string | null = null;
    partyId: string | null = null;
    ownerId: string | null = null;
    eventTypeId: any = null;
    headers = [
        { text: 'Jméno a příjmení', value: 'name' }
    ];
    calendarTitle: string | null = null;
    type: string | null = "week";
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    swiperOptions = {};
    
    @Ref() calendar!: any;
    @Ref() calendarMobile!: any;
    @Ref() eventDialog!: EventDialog;
    @Ref() taskDialog!: TaskDialog;
    @Ref() notificationDialog!: NotificationDialog;

    @Watch("codebooks")
    codebooksChanged (v: any) {
        if (v.length > 0) {
            this.eventTypeId = v[0].id
        }
    }

    calendarInstance (): Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => any; title: string } {
        return this.$refs.calendar as Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => any; title: string }
    }

    get codebooks(): CodebookModel[] {
        const type = "EventType";
        return codebooksStore.useGetter(CrudGetter.Data)?.filter((e: CodebookModel) => {
            return e.type == CodebookType[type as keyof typeof CodebookType];
        }).sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; });
    }

    get events(): EventModel[] {
        return eventsStore.useGetter(CrudGetter.Data).map((e: EventModel) => {
            return {
                id: e.id,
                name: e.name,
                start: e.start ? moment(e.start).toDate() : null,
                end: e.start ? moment(e.end).toDate() : null,
                color: e.color ? "#".concat(e.color!.replaceAll("#", "").slice(0, 6) ?? "43D01A") : "43D01A",
                timed: true,
                startDate: moment(e.start).format("YYYY-MM-DD"),
                endDate: moment(e.end).format("YYYY-MM-DD"),
                item: e
            }
        });
    }

    get tasks(): UserTaskModel[] {
        return tasksStore.useGetter(CrudGetter.Data);
    }

    get notifications(): NotificationModel[] {
        return notificationsStore.useGetter(CrudGetter.Data);
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get pendingEvents(): boolean {
        return eventsStore.useGetter(CrudGetter.Pending);
    }

    get pendingNotifications(): boolean {
        return notificationsStore.useGetter(CrudGetter.Pending);
    }

    get pendingTasks(): boolean {
        return tasksStore.useGetter(CrudGetter.Pending);
    }

    mounted () {
        this.loadEvents();
        this.loadTasks();
        this.loadNotifications();
        this.calendarTitle = this.calendarInstance().title;
        if (this.codebooks.length > 0) {
            this.eventTypeId = this.codebooks[0].id
        }
    }

    loadEvents () {
        eventsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<EventModel>(undefined, [
            {
                field: "id", op: "wrap", comparand: [
                    {
                        field: "id", op: "wrap", comparand: [
                            { field: "start", op: "gte", comparand: moment(this.currentDate).format("YYYY-MM-DDT00:00:00") },
                            { field: "start", op: "lte", comparand: moment(this.currentDate).format("YYYY-MM-DDT23:59:59") }
                        ], junction: "or"
                    },
                    {
                        field: "id", op: "wrap", comparand: [
                            { field: "end", op: "gte", comparand: moment(this.currentDate).format("YYYY-MM-DDT00:00:00") },
                            { field: "end", op: "lte", comparand: moment(this.currentDate).format("YYYY-MM-DDT23:59:59") }
                        ], junction: "or"
                    }
                ],
            },
            {
                field: "id",
                op: "wrap",
                comparand: [
                    { field: "EventParticipants.PartyId", op: "eq", comparand: this.applicationUser.partyId!, junction: "or" },
                    { field: "isPublic", op: "eq", comparand: true, junction: "or" }
                ]
            }
        ], undefined, false));
    }

    loadTasks () {
        tasksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<UserTaskModel>(undefined, [
            {
                field: "id", 
                op: "wrap",
                comparand: [
                    { field: "ownerId", op: "eq", comparand: this.applicationUser.partyId!, junction: "or" },
                    { field: "userTaskSolvers.partyId", op: "eq", comparand: this.applicationUser.partyId!, junction: "or" }
                ]
            },
            {
                field: "taskDate",
                op: "gte",
                comparand: moment().format("YYYY-MM-DDT00:00:00")
            },
            {
                field: "taskDate",
                op: "lte",
                comparand: moment().format("YYYY-MM-DDT23:59:59")
            }
        ], undefined, false));
    }

    loadNotifications () {
        notificationsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<NotificationModel>([{ field: "time", index: 1, order: "desc" }], [
            { field: "ownerId", op: "eq", comparand: this.applicationUser.partyId! },
            { field: "time", op: "lte", comparand: moment().format("YYYY-MM-DDT23:59:59") },
            { field: "isConfirmed", op: "eq", comparand: false },
        ], undefined, false));
    }

    openEvent (e: any) {
        this.eventDialog.open(e.event.id);
    }

    prev () {
        this.currentDate = moment(this.currentDate).add(-1, 'days').toDate();
        this.$nextTick(() => {
            this.calendarTitle = this.calendarInstance().title;
            this.loadEvents();
        });
    }

    next () {
        this.currentDate = moment(this.currentDate).add(1, 'days').toDate();
        this.$nextTick(() => {
            this.calendarTitle = this.calendarInstance().title;
            this.loadEvents();
        });
    }

    openTask (v: string) {
        this.taskDialog.open(v);
    }

    openNotification (e: any) {
        this.notificationDialog.open(e);
    }

    createEvent () {
        this.eventDialog.open(Guid.EmptyGuid(), {
            start: moment().add(2, "hour").format("YYYY-MM-DDTHH:00:00"),
            end: moment().add(3, "hour").format("YYYY-MM-DDTHH:00:00"),
            participants: [
                {
                    id: Guid.EmptyGuid(),
                    partyId: this.user.partyId,
                    party: {
                        id: this.user.partyId,
                        firstName: this.user.firstName,
                        lastName: this.user.lastName
                    } as PartyModel
                } as EventParticipantModel
            ]
        });
    }

    createTask () {
        this.taskDialog.open(Guid.EmptyGuid(), {
            taskDate: moment().format("YYYY-MM-DDT00:00:00")
        });
    }

    createNotification () {
        this.notificationDialog.open(Guid.EmptyGuid(), {
            time: moment().add(1, "day").format("YYYY-MM-DDT08:00:00")
        });
    }

    confirmNotification (event: MouseEvent, e: NotificationModel) {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        notificationsStore.dispatch(CrudAction.PartialUpdate, { id: e.id, diff: [
            { op: 'replace', path: useObjectKey<NotificationModel>('isConfirmed'), value: true } as CrudPartialUpdatePatch
        ] });
    }

    isBeforeToday (e: any) {
        return moment(e).startOf("day").isBefore(moment().startOf("day"));
    }

    getSubTaskTotalCount(v: UserTaskModel) {
        return v.subTasks?.length ?? 0;
    }

    getSubTaskCompletedCount(v: UserTaskModel) {
        return v.subTasks?.where(x => x.isChecked == true).toArray().length ?? 0;
    }

}
