



























import { ApplicationUserModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import {Component, Prop, Vue} from 'vue-property-decorator';
import { applicationUserStore } from '../+state/store';

@Component({ })
export default class UserSettings extends Vue {

    get user(): ApplicationUserModel {
        return {...applicationUserStore.useGetter(CrudGetter.Detail)};
    }

}
