
import Page from "@/Page.vue";
import {Component} from "vue-property-decorator";
import moment from "moment";
import cronParser from 'cron-parser';
//const cronPrser = require('cron-parser');
@Component({})
export default class Chart extends Page {
	getChartOptions(minDate: string, maxDate: string, minValue: number, maxValue: number): any {
		return {
			chart: {
				animations: {
					enabled: true,
					dynamicAnimation: {
						enabled: false
					}
				},
				type: 'line',
				background: "transparent",
				toolbar: {
					show: false
				},
				zoom: {
					enabled: false
				}
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				type: 'datetime',
				tickAmount: 6,
				labels: {
					formatter: (value: Date) => {
						return moment(value).format("DD.MM.YYYY HH:mm");
					}
				},
				min: moment(minDate).toDate().getTime(),
				max: moment(maxDate).toDate().getTime()
			},
			yaxis: [
				{
					labels: {
						formatter: function (value: number) {
							return `${Number(value).toFixed(3)} USD`;
						}
					},
					forceNiceScale: false,
					min: Math.floor(minValue * 0.99 * 100) / 100,
					max: Math.ceil(maxValue * 1.01 * 100) / 100,
				}
			],
			tooltip: {
				y: {
					formatter: function (value: number) {
						return `1 token = ${Number(value).toFixed(3)} USD`;
					}
				}
			},
			theme: {
				mode: "dark"
			},
			dataLabels: {
				enabled: false
			}
		};
	}

	getRangeFromDate(dateRange: string, fromDate: string): string {
		switch (dateRange) {
			case "last24hours":
				return this.alignDateToServiceDate(moment().add(-24, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "yesterday":
				return this.alignDateToServiceDate(moment().add(-1, "day").startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "last7days":
				return this.alignDateToServiceDate(moment().add(-7, "day").startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "last3days":
				return this.alignDateToServiceDate(moment().add(-3, "day").startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "thisWeek":
				return this.alignDateToServiceDate(moment().startOf("week").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "thisMonth":
				return this.alignDateToServiceDate(moment().startOf("month").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "last30days":
				return this.alignDateToServiceDate(moment().add(-30, "day").startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "last3months":
				return this.alignDateToServiceDate(moment().add(-3, "month").startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "last6months":
				return this.alignDateToServiceDate(moment().add(-6, "month").startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "last12months":
				return this.alignDateToServiceDate(moment().add(-12, "month").startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
			case "thisYear":
				return this.alignDateToServiceDate(moment().startOf("year").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
		}
		return this.alignDateToServiceDate(moment(fromDate).startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss"));
	}

	getRangeToDate(dateRange: string, toDate: string): string {
		switch (dateRange) {
			case "last24hours":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "yesterday":
				return this.alignDateToServiceDate(moment().add(-1, "day").endOf("day").format("YYYY-MM-DDTHH:mm:ss"));
			case "last7days":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "last3days":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "thisWeek":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "thisMonth":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "last30days":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "last3months":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "last6months":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "last12months":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
			case "thisYear":
				return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
		}
		if (moment(toDate).startOf("day").isSameOrAfter(moment().startOf("day"))) {
			return this.alignDateToServiceDate(moment().format("YYYY-MM-DDTHH:mm:ss"));
		}
		return this.alignDateToServiceDate(moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"));
	}

	private alignDateToServiceDate(date: string): string {
		const cronInterval = cronParser.parseExpression('*/15,30,45,00 * * * *', {
			currentDate: moment(date).add(-10, "second").toDate(),
			iterator: false
		});
		const nextDate = cronInterval.next().toDate();
		console.log("alignDateToServiceDate", date, moment(nextDate).format("YYYY-MM-DDTHH:mm:ss"));
		return moment(nextDate).format("YYYY-MM-DDTHH:mm:ss");
	}

}
