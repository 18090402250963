import moment from "moment";

export function minDate(firstValue: string | number | Date | null, secondValue: string | number | Date | null): Date | null {
	if (firstValue == null && secondValue == null) {
		return null;
	}
	else if (firstValue == null) {
		return moment(secondValue).toDate();
	}
	else if (secondValue == null) {
		return moment(firstValue).toDate();
	}
	else {
		if (moment(firstValue).isBefore(moment(secondValue))) {
			return moment(firstValue).toDate();
		}
		else {
			return moment(secondValue).toDate();
		}
	}
}

export function maxDate(firstValue: string | number | Date | null, secondValue: string | number | Date | null): Date | null {
	if (firstValue == null && secondValue == null) {
		return null;
	}
	else if (firstValue == null) {
		return moment(secondValue).toDate();
	}
	else if (secondValue == null) {
		return moment(firstValue).toDate();
	}
	else {
		if (moment(firstValue).isAfter(moment(secondValue))) {
			return moment(firstValue).toDate();
		}
		else {
			return moment(secondValue).toDate();
		}
	}
}

export function isBefore(firstValue: string | number | Date | null, secondValue: string | number | Date | null): boolean {
	if (firstValue == null || secondValue == null) {
		return false;
	}
	return moment(firstValue).isBefore(moment(secondValue));
}

export function isAfter(firstValue: string | number | Date | null, secondValue: string | number | Date | null): boolean {
	if (firstValue == null || secondValue == null) {
		return false;
	}
	return moment(firstValue).isAfter(moment(secondValue));
}
