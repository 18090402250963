
















































































































































import {Component, Vue, Prop} from 'vue-property-decorator';

import VueApexCharts from 'vue-apexcharts';
import { productActivitiesStore, productsStore } from '@/libs/products/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { Constants } from '@/libs/constants/constants';
import { PartyModel, PartyProductModel, ProductActivityModel, ProductModel, StateOfCapitalModel, TransactionModel, StateOfCapitalYearModel } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import NOIFDialog from '@/libs/clients/components/NOIFDialog.vue';
import { transactionsStore } from '@/libs/transactions/+state/store';
import { Guid } from '@/libs/common/functions/guid';
import moment, { months } from 'moment';
import { startsWith } from 'lodash';
import { StateOfTransactionModel } from '@/libs/clients/models/StateOfTransactionModel';
import { stateOfCapitalStore, stateOfCapitalYearStore } from '@/libs/state-of-capital/+state/store';
import {maxDate, minDate} from "@/libs/common/functions/date-comparison";

@Component({
    components: {
        VueApexCharts,
        NOIFDialog
    }
})
export default class ClientProductNOIF extends Vue {

    get chartOptions() {
        const n = {
            chart: {
                height: 300,
                type: 'line',
                background: "transparent",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                tickAmount: 6,
                labels: {
                    formatter: (value: Date) => {
                        if (moment(value).isBefore(moment(this.year + "-01-01 00:00:00"))) {
                            return "Zahájení roku";
                        }
                        else {
                            return moment(value).format("MM.YYYY");
                        }
                    }
                },
                min: moment(this.year + "-01-01 00:00:00").add(-1, "month").toDate().getTime(),
                max: moment(this.year + "-12-31 23:59:59").toDate().getTime()
            },
            yaxis: [
                {
                    min: 0,
                    labels: {
                        formatter: function (value: number) {
                            return Number(value).toFixed(2).concat(" %");
                        }
                    },
                    forceNiceScale: true
                }
            ],
            theme: {
                mode: "dark"
            },
            dataLabels: {
                enabled: false
            }
        };
        return n;
    }
    headersTransactions = [
        { text: 'Klient', align: 'left', sortable: false, value: 'party.displayName', suffix: "Kč", type: "money" },
        { text: 'Vklad', align: 'left', sortable: false, value: 'deposit', suffix: "Kč", type: "money" },
        { text: 'Výnos', align: 'left', sortable: false, value: 'earnings', suffix: "Kč", type: "money" },
        { text: 'Úrok', align: 'left', sortable: false, value: 'interestRate', suffix: "%", type: "number" },
        { text: 'Výběr', align: 'left', sortable: false, value: 'withdrawal', suffix: "Kč", type: "money" },
        { text: 'Stav kapitálu', align: 'left', sortable: false, value: 'stateOfCapital', suffix: "Kč", type: "money" },
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "date" },
    ];
    items = [];
    product: ProductModel | null = null;
    partyProduct: PartyProductModel | null = null;
    stateOfCapital : number | null = null;
    stateOfCapitalIncrease : number | null = null;
    totalDeposit: number | null = null;
    monthlyEarnings: number | null = null;
    totalWithdrawal: number | null = null;
    totalEarnings: number | null = null;
    history: StateOfTransactionModel[] = [];
    clientEvaluations: Array<any> = [];
    year = moment().year();

    get years () {
        let d = 2022;
        const out: Array<number> = [];
        while (d <= moment().year()) {
            out.push(d);
            d++;
        }
        return out;
    }

    get ProductActivities () : ProductActivityModel[] {
        return productActivitiesStore.useGetter(CrudGetter.Data);
    }

    get transactions () : TransactionModel[] {
        return transactionsStore.useGetter(CrudGetter.Data).filter((e: TransactionModel) => { return moment(e.time).isBefore(moment().endOf("month")); });
    }

    get showChart(): boolean {
		const transactions =
            this.history
                .filter(e => { return moment(e.time).isBefore(moment()); })
                .sort((a: StateOfTransactionModel, b: StateOfTransactionModel) => { return a.time.getTime() - b.time.getTime(); });
		if (transactions.length <= 0)
            return false;
        return true;
    }
    
    get chartSeries () : any[] {
        const stateOfCapitalItems = stateOfCapitalStore.useGetter(CrudGetter.Data) as StateOfCapitalModel[];
        if (this.partyProduct == null)
            return [];
        let dataSeriesFund: Array<any> = [ [ moment(this.year + '-01-01').add(-1, 'month').startOf("month").toDate().getTime(), 0 ] ];
        let dataSeriesClient: Array<any> = [ [ moment(this.year + '-01-01').add(-1, 'month').startOf("month").toDate().getTime(), 0 ] ];
        let fromDate = moment(this.year + '-01-01').startOf("year").toDate();
		if (this.product.startDate != null && moment(this.product.startDate).startOf("month").toDate() > fromDate) {
			fromDate = moment(this.product.startDate).startOf("month").toDate();
			dataSeriesFund = [ [ moment(fromDate).add(-1, 'month').startOf("month").toDate().getTime(), 0 ] ];
			dataSeriesClient = [ [ moment(fromDate).add(-1, 'month').startOf("month").toDate().getTime(), 0 ] ];
		}
        let clientFromDate = moment(this.year + '-01-01').startOf("year").toDate();
        let toDate = moment(this.year + '-12-31').endOf("year").toDate();
        if (moment(fromDate).startOf("month").isBefore(moment(this.partyProduct!.dateOfStart))) {
            clientFromDate = moment(this.partyProduct!.dateOfStart).add(1, "month").startOf("month").toDate();
        }
        if (moment(toDate).startOf("month").isAfter(moment(this.partyProduct!.dateOfEnding))) {
            toDate = moment(this.partyProduct!.dateOfEnding).endOf("month").toDate();
        }
		if (this.product.startDate != null) {
			clientFromDate = maxDate(clientFromDate, this.product.startDate);
		}
		if (this.product.endDate != null) {
			toDate = minDate(toDate, this.product.endDate);
		}
        let currentDate = fromDate;
        let i = 0;
        while (currentDate < toDate) {
            if (currentDate >= clientFromDate) {
                i++;
            }
            if (moment(currentDate).startOf("month").isSameOrAfter(moment().startOf("month"))) {
                break;
            }
            const d = moment(currentDate).startOf("month").toDate().getTime();
            const stateOfCapitalItemsToDate = stateOfCapitalItems.where(x => moment(x.date).startOf("month").isSameOrAfter(moment(fromDate).startOf("month")) && moment(x.date).endOf("month").isSameOrBefore(moment(currentDate).endOf("month"))).toArray();
            const fundEvaluation = stateOfCapitalItemsToDate.sum(x => (x.investmentValue! / x.value!) - 1) * 100;
            const clientEvaluation = this.partyProduct!.interestRate * i;
            dataSeriesFund.push([ d, fundEvaluation ]);
            dataSeriesClient.push([ d, clientEvaluation ]);
            currentDate = moment(currentDate).add(1, "month").toDate();
        }
        this.clientEvaluations = dataSeriesClient;
        return [
            {
                name: 'Zhodnocení fondu',
                data: dataSeriesFund
            },
            {
                name: 'Zhodnocení klienta '.concat(this.partyProduct.party.firstName).concat(" ").concat(this.partyProduct.party.lastName),
                data: dataSeriesClient
            }
        ];
    }

    get pendingTransactions () : boolean {
        return transactionsStore.useGetter(CrudGetter.Pending);
    }

    get savingTransactions () : boolean {
        return transactionsStore.useGetter(CrudGetter.Saving);
    }

    get client () : PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.id);
    }
    
    get stateOfCapitalYear () : StateOfCapitalYearModel[] {
        return stateOfCapitalYearStore.useGetter(CrudGetter.Data);
    }

	get partyProductEndDate(): string {
		const d = minDate(this.partyProduct?.dateOfEnding, this.product?.endDate);
		if (d == null)
			return null;
		return moment(d).format("YYYY-MM-DDT00:00:00");
	}
    
    @Prop() id!: string;
    @Prop() partyProductId!: string;

    mounted () {
        this.load();
    }

    load () {
        this.loadProduct();
        this.loadProductActivities();
        this.loadStateOfCapital();
        this.loadStateOfCapitalYear();
    }

    loadProduct () {
        productsStore.dispatch(CrudAction.Get, { id: Constants.NoifProductId, key: Constants.NoifProductId });
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === productsStore.getActionName(CrudResponseAction.GetSuccess)) {
                subscriber();
                self.loadPartyProduct();
            }
        });
    }

    loadPartyProduct () {
        partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
            { field: "id", op: "eq", comparand: this.partyProductId }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.makeProducts();
                if (this.partyProduct != null) {
                    self.loadTransactions();
                }
            }
        });
    }

    loadTransactions () {
        transactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TransactionModel>([{field: "time", order: "desc", index: 0}], [
            { field: "partyProductId", op: "eq", comparand: this.partyProduct!.id! }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === transactionsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.computeHistory();
            }
        });
    }

    makeProducts () {
        this.product = productsStore.useGetter(CrudGetter.Detail, Constants.NoifProductId);
        const partyProducts = partyProductsStore.useGetter(CrudGetter.Data);
        if (partyProducts.length > 0) {
            this.partyProduct = partyProducts[0];
        }
    }

    loadProductActivities () {
        productActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductActivityModel>([
            { field: "createdAt", order: "desc", index: 0 }
        ], [
            { field: "productId", op: "eq", comparand: Constants.NoifProductId }
        ]))
    }

    computeSummary (items: StateOfTransactionModel[]) {
        const transactions = [...items].sort((a: StateOfTransactionModel, b: StateOfTransactionModel) => { return moment(a.time).toDate().getTime() - moment(b.time).toDate().getTime(); }); 
        this.stateOfCapital = transactions.length > 0 ? transactions[transactions.length - 1].stateOfCapital! : 0;
        this.stateOfCapitalIncrease = transactions.length > 1 ? 
                (transactions[transactions.length - 1].stateOfCapital! / transactions[transactions.length - 2].stateOfCapital! - 1) * 100 :
                null
        this.totalDeposit = transactions.reduce((s: number, a: StateOfTransactionModel) => s + a.deposit!, 0);
        this.totalWithdrawal = transactions.reduce((s: number, a: StateOfTransactionModel) => s + a.withdrawal!, 0);
        this.totalEarnings = transactions.reduce((s: number, a: StateOfTransactionModel) => s + a.earnings!, 0);
    }

    computeHistory () {
        let transactions = transactionsStore.useGetter(CrudGetter.Data) as TransactionModel[];
		let start = moment(this.partyProduct?.dateOfStart).toDate();
		if (this.product.startDate != null) {
			start = maxDate(start, this.product.startDate);
		}
		let end = this.partyProduct?.dateOfEnding != null ? moment(this.partyProduct?.dateOfEnding).toDate() : new Date();
		if (this.product.endDate != null) {
			end = minDate(end, this.product.endDate);
		}
        let output: StateOfTransactionModel[] = [];
        let stateOfCapital = 0;
        
        const firstTransaction = transactions.where(x => moment(x.time).isBefore(moment(start).startOf('month'))).orderBy(x => moment(x.time).toDate().getTime()).firstOrDefault();
        if (firstTransaction != null) {
            let prependStart = moment(firstTransaction.time).startOf('month').toDate();
            const prependEnd = moment(start).add(-1, 'month').startOf('month').toDate();
            while (prependStart <= prependEnd) {
                const party = this.partyProduct!.party!;
                const monthEnd = moment(prependStart).endOf('month');
                const monthTransactions = transactions.filter((e: TransactionModel) => moment(e.time).isSameOrAfter(prependStart) && moment(e.time).isSameOrBefore(monthEnd)) as TransactionModel[];
                const deposit = monthTransactions.map((e) => e.deposit!).reduce((a, b) => a + b, 0);
                const withdrawal = monthTransactions.map((e) => e.withdrawal!).reduce((a, b) => a + b, 0);
                stateOfCapital = stateOfCapital + deposit - withdrawal;
                output.splice(0, 0, {
                    party: party,
                    time: monthEnd.endOf("month").toDate(),
                    deposit: deposit,
                    withdrawal: withdrawal,
                    interestRate: 0,
                    earnings: 0,
                    stateOfCapital: stateOfCapital,
                    displayEarnings: 0
                } as any);
                prependStart = moment(prependStart).add(1, "month").startOf("month").toDate()
            }
        }
        
        const endOfThisMonth = moment().endOf("month");
        let previousEarnigns = 0;
        while (start <= end)
        {
            const monthStart = moment(start).startOf('month');
            if (monthStart > endOfThisMonth)
                break;
            const monthEnd = moment(start).endOf('month');
            const monthTransactions = transactions.filter((e: TransactionModel) => moment(e.time).isSameOrAfter(monthStart) && moment(e.time).isSameOrBefore(monthEnd)) as TransactionModel[];
            const deposit = monthTransactions.map((e) => e.deposit!).reduce((a, b) => a + b, 0);
            const withdrawal = monthTransactions.map((e) => e.withdrawal!).reduce((a, b) => a + b, 0);
            const earnings = Math.round((stateOfCapital) * (this.partyProduct!.interestRate! / 100));
            stateOfCapital = stateOfCapital + deposit - withdrawal;
            const party = this.partyProduct!.party!;
            output.splice(0, 0, {
                party: party,
                time: monthEnd.endOf("month").toDate(),
                deposit: deposit,
                withdrawal: withdrawal,
                interestRate: this.partyProduct!.interestRate!,
                earnings: earnings,
                stateOfCapital: stateOfCapital,
                displayEarnings: previousEarnigns
            } as any);
            start = moment(start).add(1, "month").startOf("month").toDate();
            previousEarnigns += this.partyProduct!.interestRate!;
        }
        
        const lastTransaction = transactions.where(x => moment(x.time).isAfter(moment(end).endOf('month'))).orderBy(x => moment(x.time).toDate().getTime()).lastOrDefault();
        if (lastTransaction != null) {
            let appendStart = moment(end).add(1, 'month').startOf('month').toDate();
            const appendEnd = moment(lastTransaction.time).startOf('month').toDate();
            while (appendStart <= appendEnd) {
                const party = this.partyProduct!.party!;
                const monthEnd = moment(appendStart).endOf('month');
                const monthTransactions = transactions.filter((e: TransactionModel) => moment(e.time).isSameOrAfter(appendStart) && moment(e.time).isSameOrBefore(monthEnd)) as TransactionModel[];
                const deposit = monthTransactions.map((e) => e.deposit!).reduce((a, b) => a + b, 0);
                const withdrawal = monthTransactions.map((e) => e.withdrawal!).reduce((a, b) => a + b, 0);
                stateOfCapital = stateOfCapital + deposit - withdrawal;
                output.splice(0, 0, {
                    party: party,
                    time: monthEnd.endOf("month").toDate(),
                    deposit: deposit,
                    withdrawal: withdrawal,
                    interestRate: 0,
                    earnings: 0,
                    stateOfCapital: stateOfCapital,
                    displayEarnings: 0
                } as any);
                appendStart = moment(appendStart).add(1, "month").startOf("month").toDate()
            }
        }
        
        this.stateOfCapital = stateOfCapital;
        this.history = output;
        this.computeSummary(output);
        
    }

    loadStateOfCapital () {
        stateOfCapitalStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<StateOfCapitalModel>([{ field: "date", index: 1, order: "asc" }], [
            { field: "productId", op: "eq", comparand: Constants.NoifProductId }
        ]))
    }
    

    loadStateOfCapitalYear () {
        stateOfCapitalYearStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<StateOfCapitalYearModel>([{ field: "date", index: 1, order: "desc" }], [
            { field: "productId", op: "eq", comparand: Constants.NoifProductId }
        ]))
    }

    changed () {
        this.load();
    }
    
    removed () {
        this.$router.push({ name: "ClientProducts", params: { id: this.id } })
    }

}
