











































































import { PartyModel } from '@/libs/Api';
import { partiesStore } from '@/libs/parties/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import AccountDialog from '@/libs/system-settings/components/AccountDialog.vue';
import AccountTypeSelect from '@/libs/common/components-smart/AccountTypeSelect.vue';
import { Guid } from '@/libs/common/functions/guid';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';

@Component({
    components: { AccountDialog, AccountTypeSelect }
})
export default class SystemSettingsAccounts extends Vue {
  
    dialogItem: PartyModel | null = null;
    tableHeaders = [
        { text: 'Jméno a příjmení', align: 'left', sortable: false, value: 'displayName' },
        { text: 'Telefon', align: 'left', sortable: false, value: 'phoneNumber' },
        { text: 'Email', align: 'left', sortable: false, value: 'email' },
        { text: 'Typ účtu', align: 'left', sortable: false, value: 'accountType' },
        { text: 'Stav', align: 'left', sortable: false, value: 'clientState.name' }
    ];
    tableHeadersMobile = [
        { text: 'Jméno a příjmení', align: 'left', sortable: false, value: 'displayName' },
        { text: 'Typ účtu', align: 'left', sortable: false, value: 'accountType' }
    ];
    filterAccountType = null;
    filterState = null;
    showFilters = false;

    get pending() : boolean {
        return partiesStore.useGetter(CrudGetter.Pending);
    }

    get tableItems() : PartyModel[] {
        return partiesStore.useGetter(CrudGetter.Data);
    }

    @Watch("filterAccountType")
    @Watch("filterState")
    filtersChanged() {
        this.loadParties();
    }
    
    mounted () {
        this.loadParties();
    }

    loadParties() {
        
        const filters: CrudQueryPredicate[] = [
            { field: "AccountType", op: "in", comparand: [ 'Admin', 'Manager', 'Consultant', 'Assistant' ] }
        ];
        
        if (this.filterAccountType != null)
            filters.push({ field: "AccountType", op: "eq", comparand: this.filterAccountType! });
        
        
        if (this.filterState != null)
            filters.push({ field: "ClientStateId", op: "eq", comparand: this.filterState! });
        
        partiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyModel>(undefined, filters, undefined, false));

    }

    createAccount () {
        this.dialogItem = {
            id: Guid.EmptyGuid(),
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            clientCode: ""
        };
    }

    editItem (args: any) {
        this.dialogItem = args;
    }

    deleteItem () {
        console.log("deleteItem");
    }

}
