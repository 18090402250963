



















































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { PartyProductModel, ProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { productsStore } from '@/libs/products/+state/store';
import { CrudAction, CrudReponse, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { ProductTypeId } from '@/libs/products/product-types';
import Page from '@/Page.vue';
import { partyProductsStore } from '@/libs/parties/+state/store';

@Component({ })
export default class ProductDialog extends Page {

  isActive = true;
  show = true;
  innerValue: ProductModel | null = null;
  valid = false;
  basicRules = [
      (v: any) => !!v || 'Toto pole je povinné.'
  ];
  deleting = false;
  hasComeClientsError = false;

  @VModel() item!: ProductModel;

  @Ref() form!: any;

  @Watch("show")
  showChanged(v: boolean) {
    if (!v)
      this.close();
  }

  @Watch("item")
  itemChanged (v: ProductModel) {
    this.innerValue = v;
  }
  
  get isNewItem(): boolean {
    return this.innerValue?.id == Guid.EmptyGuid();
  }

  get saving(): boolean {
    return productsStore.useGetter(CrudGetter.Saving);
  }

  get canDelete(): boolean {
    return this.innerValue?.productTypeId != ProductTypeId.InvestmentTypeId;
  }

  mounted () {
    this.innerValue = this.item;
  }

  save () {
    if (this.form.validate()) {
      if (this.innerValue?.id == Guid.EmptyGuid()) {
        productsStore.dispatch(CrudAction.Create, { item: this.innerValue });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === productsStore.getActionName(CrudResponseAction.CreateSuccess)) {
              subscribtion();
              self.close();
              self.$emit("created");
          }
          else if (mutation.type === productsStore.getActionName(CrudResponseAction.CreateFailed)) {
              subscribtion();
          }
        });
      }
      else {
        productsStore.dispatch(CrudAction.Update, { item: this.innerValue });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === productsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
              subscribtion();
              self.close();
              self.$emit("updated");
          }
          else if (mutation.type === productsStore.getActionName(CrudResponseAction.UpdateFailed)) {
              subscribtion();
              self.close();
          }
        });
      }
    }
  }

  deleteProduct () {
    this.hasComeClientsError = false;
    this.deleting = true;
    partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyProductModel>(undefined, [
      { field: "productId", op: "eq", comparand: this.innerValue!.id! },
      { field: "isActive", op: "eq", comparand: true },
    ], { pageIndex: 1, pageSize: 1 }));
    this.subscribe(partyProductsStore, CrudReponse.GetAll).then((e: PartyProductModel[]) => {
      if (e.length > 0) {
        this.hasComeClientsError = true;
        this.deleting = false;
      }
      else {
        productsStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [ { field: "id", op: "eq", comparand: this.innerValue!.id! } ]));
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === productsStore.getActionName(CrudResponseAction.DeleteSuccess)) {
              subscribtion();
              this.deleting = false;
              self.close();
              self.$emit("deleted");
          }
          else if (mutation.type === productsStore.getActionName(CrudResponseAction.DeleteFailed)) {
              subscribtion();
          }
        });
      }
    });
  }

  close () {
    this.$emit("input", null);
  }
    
}
