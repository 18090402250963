export class Constants {
    
    public static NfgProductTypeId: string = "EB5F9259-3262-467D-B98E-8CB1034369E1".toLowerCase();
    public static InsuranceProductTypeId: string = "E24BB865-2CDF-4D60-90ED-4CC7089AEA40".toLowerCase();
    public static LoanProductTypeId: string = "D0CB93DA-F8AB-4DF5-A0E3-523E7FC51A00".toLowerCase();
    public static InvesmentProductTypeId: string = "39bae83e-38af-4ea6-bde8-d7943881b7ab".toLowerCase();
    public static CreditProductTypeId: string = "9805cbb7-6a1c-4a06-9c85-ffa6f895402f".toLowerCase();

    public static NoifProductId: string = "196C92D4-9D3B-46B2-876E-204E4228ED50".toLowerCase();
    public static ReOpportunitiesId: string = "2aae8ed6-d537-473f-8a92-d3467b61618c".toLowerCase();
    public static NreiProductId: string = "751764BA-9169-455F-96CA-4BC58E26325C".toLowerCase();
    public static NcifProductId: string = "CE1CBC08-EA23-4725-B5A4-3215FFB4C365".toLowerCase();
    public static WealthProductId: string = "5b7c1495-2b97-4abd-bc74-95b5b6a9cc0f".toLowerCase();
    public static CreditProductId: string = "55b8fcd4-02a6-4b07-8b23-875a632f7681".toLowerCase();
    public static CltProductId: string = "067aaf0d-acd7-48df-85b8-8dbfb7459b4c".toLowerCase();
    public static CftProductId: string = "a61530de-c79c-4743-9e53-034416c4a1e9".toLowerCase();
    public static NremProductId: string = "fce6e05a-487c-424d-a5ae-b1936336ff83".toLowerCase();

    public static CurrencyCzkId: string = "37e8cf19-a360-435f-96b5-b9e76cab06e2".toLowerCase();
    public static CurrencyUsdId: string = "f19f97a0-04b2-44a6-8665-794f79aa274f".toLowerCase();
    public static CurrencyCltId: string = "b6fe3d33-efbe-4957-966e-9b5e761b53a7".toLowerCase();
    public static CurrencyCftId: string = "100dcb1f-93f4-4318-9ed2-c5265a072515".toLowerCase();
    public static CurrencyNremId: string = "23a0fc45-4906-47f1-9640-a40019c2ee76".toLowerCase();

}
