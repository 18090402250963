














































































































































































import { Component, Vue, Watch, Prop, PropSync, Ref } from 'vue-property-decorator';
import { CrudTableEnumerator, CrudTableEnumeratorItem } from '../models/crud-table-enumerator'

@Component
export default class CrudTable extends Vue {

    dialog = false;
    dialogDelete = false;
    editedIndex = -1;
    editedItem: any = null;
    edit = false;
    tableHeaders: Array<any> = [];
    valid = false;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];

    rules = {
        required: [ (value: any) => !!value || 'Pole je vyžadováno.' ]
    };
    saving = false;
    
    @Prop() name!: string;
    @Prop() headers!: Array<any>;
    @Prop({ type: Function, default: (e: any) => { return e; } }) beforeInsert!: any;
    @Prop({ type: Function, default: (e: any) => { return e; } }) beforeAdd!: any;
    @Prop({ type: Function, default: (e: any) => { return e; } }) beforeEdit!: any;
    @Prop() defaultItem: any;

    @Prop({ default: true }) disablePagination!: boolean;
    @Prop({ default: true }) hideDefaultFooter!: boolean;
    @Prop({ default: false }) maxHeight!: boolean;
    
    @Prop({ default: true }) editable!: boolean;
    @Prop({ default: true }) addable!: boolean;
    @Prop({ default: true }) deleteable!: boolean;
    @Prop({ default: true }) autoClose!: boolean;
    @Prop({ default: false }) isSaving!: boolean;
    @Prop({ default: false }) isDeleting!: boolean;
    @Prop({ default: false }) smallDialog!: boolean;
    @Prop({ default: false }) enumerators!: CrudTableEnumerator;
    @Prop({ default: undefined }) enableActions!: (item: any) => boolean | boolean;
    @PropSync('items', { default: () => { return []; } }) tableItems!: Array<any>;
    @Ref() form!: any;
    @Prop() savePromise!: any;
    
    @Prop() serverItemsLength!: any;
    @PropSync('page') pageValue!: number;
    @PropSync('items-per-page') itemsPerPageValue!: number;
    @Prop() footerProps!: any;


    @Watch("headers")
    headersChanged (v: Array<any>) {
        this.tableHeaders = [
            ...v,
            { text: '', value: 'actions', sortable: false, align: 'right', type: "actions" }
        ];
    }

    @Watch("dialog")
    dialogChanged (v: boolean) {
        if (!v) {
            this.editedItem = null;
        }
    }

    mounted () {
        this.tableHeaders = [
            ...this.headers,
            { text: '', value: 'actions', sortable: false, align: 'right', type: "actions" }
        ];
    }

    addItemClick () {
        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.beforeAdd) {
            this.beforeAdd(this.editedItem);
        }
        this.edit = false;
        this.dialog = true;
    }

    editItem (item : any) {
        this.editedIndex = this.tableItems.indexOf(item);
        this.editedItem = Object.assign({}, item);
        if (this.beforeEdit) {
            this.beforeEdit(this.editedItem);
        }
        this.dialog = true;
        this.edit = true;
    }

    deleteItem (item : any) {
        this.editedIndex = this.tableItems.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
    }

    deleteItemConfirm () {
        this.tableItems.splice(this.editedIndex, 1);
        if (this.autoClose) {
	        this.closeDelete();
        }
        this.$emit("delete", this.editedItem);
    }

    close () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
    }

    closeDelete () {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        })
    }

    save () {
        if (this.form.validate()) {
            this.saving = true;
            if (this.savePromise) {
                this.savePromise(this.editedItem).then((e: boolean) => {
                    if (e) {
                        this.makeSave();
                    }
                    else {
                        this.saving = false;
                    }
                }).catch(() => {
                    this.saving = false;
                });
            }
            else {
                this.makeSave();
            }
        }
    }

    makeSave () {
        if (this.editedIndex > -1) {
            Object.assign(this.tableItems[this.editedIndex], this.editedItem);
            this.$emit("save", this.editedItem);
            this.saving = false;
        }
        else {
            this.editedItem = this.beforeInsert(this.editedItem);
            this.tableItems.push(this.editedItem);
            this.$emit("save", this.editedItem);
            this.saving = false;
        }
		if (this.autoClose) {
			this.close();
		}
    }

    getEnumeratorValue(enumeratorName: string, value: string) {
        return this.enumerators[enumeratorName]?.firstOrDefault(x => x.value == value)?.text || "";
    }

    getEnumeratorColor(enumeratorName: string, value: string) {
        return this.enumerators[enumeratorName]?.firstOrDefault(x => x.value == value)?.color || "";
    }

    canEnableActions (item: any) {
        if (typeof this.enableActions == "boolean") {
            return this.enableActions == true;
        }
        else if (typeof this.enableActions == "function") {
            return this.enableActions(item);
        }
        return true;
    }

}
