
























































































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { AccountTypeEnum, ApplicationUserModel, PartyModel, PartyProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import moment from 'moment';
import { applicationUserStore } from '@/libs/user-settings/+state/store';

@Component({
    components: {
        
    }
})
export default class NNremDialog extends Vue {

  isActive = true;
  dialog = false;
  innerValue: string | null = null;
  partyProduct: PartyProductModel | null = null;
  @VModel() item!: string;
  @Prop() clientId!: string;
  @Prop() productId!: string;
  @Prop() name!: string;
  @Prop() activator!: string;
  @Prop() readonly!: string;
  @Prop({ default: "green" }) activatorColor!: string;
  @Ref() form!: any;
  valid = false;
  basicRules = [
    (v: any) => !!v || 'Toto pole je povinné.'
  ];
  
  @Watch("dialog")
  dialogChanged(v: boolean) {
    if (!v)
        this.close();
    else
        this.load();
  }

  @Watch("item")
  itemChanged (v: string) {
    this.innerValue = v;
  }
  
  get isNewItem(): boolean {
    return this.innerValue == Guid.EmptyGuid();
  }

  get saving(): boolean {
    return partyProductsStore.useGetter(CrudGetter.Saving);
  }

  get canDelete(): boolean {
    return true;
  }

  get client() : PartyModel {
      return partiesStore.useGetter(CrudGetter.Detail, this.clientId!);
  }

  get canEditClientCode () {
    const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    return user.accountType == AccountTypeEnum.Admin;
  }

  mounted () {
    if (this.activator) {
        this.dialog = false;
        this.innerValue = this.item;
    }
    else {
        this.load();
    }
  }

  open () {
      this.dialog = true;
      this.load();
  }

  load() {
      if (this.item == null || this.item == Guid.EmptyGuid()) {
        this.partyProduct = {
            id: Guid.EmptyGuid(),
            partyProductName: null,
            partyProducCode: null,
            name: this.name,
            partyId: this.clientId,
            productId: this.productId,
            dateOfStart: moment().format("YYYY-MM-DDT00:00:00"),
            dateOfSignature: moment().format("YYYY-MM-DDT00:00:00"),
            dateOfEnding: null,
            insurancePrice: null,
            description: null,
            depositAmount: null,
            monthlyRevenue: null,
            revenueAmount: null,
            definedRevenue: null,
            currentCapitalAmount: null,
            pickAmount: null,
            isActive: true,
            bankCompanyId: null,
            investmentStrategyId: null,
            interestRate: null
        } as PartyProductModel;
        this.isActive = true;
    }
    else {
        partyProductsStore.dispatch(CrudAction.Get, { id: this.item, key: this.item });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetSuccess)) {
                subscribtion();
                self.partyProduct = mutation.payload.item;
                self.isActive = self.partyProduct.isActive;
            }
        });
    }
  }

  save () {
    if (this.form.validate()) {
        this.partyProduct.isActive = this.isActive;
        if (this.item == null || this.innerValue == Guid.EmptyGuid()) {
            partyProductsStore.dispatch(CrudAction.Create, { item: this.partyProduct });
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.CreateSuccess)) {
                    subscribtion();
                    self.close();
                    this.$emit("changed");
	                partiesStore.dispatch(CrudAction.Get, { id: this.partyProduct.partyId, key: this.partyProduct.partyId });
                }
                else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.CreateFailed)) {
                    subscribtion();
                }
            });
        }
        else {
            partyProductsStore.dispatch(CrudAction.Update, { item: this.partyProduct });
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                    subscribtion();
                    self.close();
                    this.$emit("changed");
	                partiesStore.dispatch(CrudAction.Get, { id: this.partyProduct.partyId, key: this.partyProduct.partyId });
                }
                else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.UpdateFailed)) {
                    subscribtion();
                    self.close();
                }
            });
        }
    }
  }

  close () {
    this.dialog = false;
  }

  deletePartyProduct () {
    partyProductsStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [ { field: "id", op: "eq", comparand: this.innerValue! } ]));
    const self = this;
    const subscribtion = this.$store.subscribe((mutation, _state) => {
      if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.DeleteSuccess)) {
          subscribtion();
          self.close();
          this.$emit("removed");
	      partiesStore.dispatch(CrudAction.Get, { id: this.clientId, key: this.clientId });
      }
      else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.DeleteFailed)) {
          subscribtion();
      }
    });
  }
    
}
