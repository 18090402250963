































































































































































































































import {Component, Prop} from 'vue-property-decorator';

import VueApexCharts from 'vue-apexcharts';
import {productActivitiesStore, productsStore} from '@/libs/products/+state/store';
import {CrudAction, CrudReponse, CrudResponseAction} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {partiesStore, partyProductsStore} from '@/libs/parties/+state/store';
import {Constants} from '@/libs/constants/constants';
import {
	ExchangeRateModel,
	ExchangeRateTypeEnum,
	PartyModel,
	PartyProductModel,
	ProductActivityModel,
	ProductModel,
	StateOfCapitalYearModel,
	TradeModel,
	TransactionModel,
	WalletModel,
	WalletTransactionModel,
	WalletTransactionTypeEnum
} from '@/libs/Api';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import NWDialog from '@/libs/clients/components/NWDialog.vue';
import {transactionsStore} from '@/libs/transactions/+state/store';
import {exchangeRatesStore} from '@/libs/exchange-rates/+state/store';
import moment from 'moment';
import {stateOfCapitalYearStore} from '@/libs/state-of-capital/+state/store';
import Page from "@/Page.vue";
import {walletTransactionsStore} from "@/libs/wallet-transactions/+state/store";
import {tradesStore} from "@/libs/trades/+state/store";
import {walletsStore} from "@/libs/wallets/+state/store";

@Component({
	components: {
		VueApexCharts,
		NWDialog
	}
})
export default class ClientProductNrem extends Page {

	headersTrades = [
		{ text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime", width: "16%" },
		{ text: 'Typ', align: 'left', sortable: false, value: 'type', type: "text", width: "16%" },
		{ text: 'Převod z', align: 'left', sortable: false, value: 'fromAmount', type: "text", width: "16%" },
		{ text: 'Převod na', align: 'left', sortable: false, value: 'toAmount', type: "text", width: "16%" },
		{ text: 'Kurz IM4', align: 'left', sortable: false, value: 'exchangeRateIm4', type: "text", width: "16%" }
	];
	headersTransactions = [
		{ text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime", width: "15%" },
		{ text: 'Typ', align: 'left', sortable: false, value: 'type', type: "text", width: "20%" },
		{ text: 'Částka', align: 'right', sortable: false, value: 'amount', type: "text", width: "20%" },
		{ text: '', align: 'left', sortable: false, value: 'id', type: "text" }
	];
	headersRewards = [
		{ text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime", width: "15%" },
		{ text: 'Typ', align: 'left', sortable: false, value: 'type', type: "text", width: "20%" },
		{ text: 'Počet tokenů', align: 'right', sortable: false, value: 'amount', type: "text", width: "20%" },
		{ text: '', align: 'left', sortable: false, value: 'id', type: "text" }
	];
	fundHeaders = [
		{ text: 'Datum', align: 'left', sortable: false, value: 'fromDate', type: "date", width: "20%", format: "MMMM YYYY" },
		{ text: 'Zhodnocení fondu aktuální', align: 'left', sortable: false, value: 'rate', suffix: "", type: "number", width: "20%" },
		{ text: 'Zhodnocení fondu kumulativní', align: 'left', sortable: false, value: 'rateCumulative', suffix: "", type: "number" },
	];
	items = [];
	product: ProductModel | null = null;
	partyProduct: PartyProductModel | null = null;
	clientEvaluations: Array<any> = [];
	year = moment().year();
	history: TransactionModel[] = [];
	exchangeRates: ExchangeRateModel[] = [];
	wallets: WalletModel[] = [];

	get years () {
		let d = 2024;
		const out: Array<number> = [];
		while (d <= moment().year()) {
			out.push(d);
			d++;
		}
		return out;
	}

	get ProductActivities () : ProductActivityModel[] {
		return productActivitiesStore.useGetter(CrudGetter.Data);
	}

	get transactions() : TransactionModel[] {
		return walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions');
	}

	get rewards() : TransactionModel[] {
		return walletTransactionsStore.useGetter(CrudGetter.Data, 'Rewards');
	}

	get pendingTransactions(): boolean {
		return walletTransactionsStore.useGetter(CrudGetter.Pending, 'CzkTransactions');
	}

	get trades() : TradeModel[] {
		return tradesStore.useGetter(CrudGetter.Data);
	}

	get pendingTrades(): boolean {
		return tradesStore.useGetter(CrudGetter.Pending);
	}

	get stateOfCapital(): number {
		const walletTransactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
		return walletTransactions.where(x => x.transactionType == WalletTransactionTypeEnum.Standard && x.amount > 0 && x.tradeId == null).select(x => x.amount).sum();
	}

	get totalDeposit(): number {
		const walletTransactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
		return walletTransactions.where(x => x.transactionType == WalletTransactionTypeEnum.Standard && x.amount > 0 && x.tradeId == null).select(x => x.amount).sum();
	}

	get totalWithdrawal(): number {
		const walletTransactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
		return Math.abs(walletTransactions.where(x => x.transactionType == WalletTransactionTypeEnum.Standard && x.amount < 0 && x.tradeId == null).select(x => x.amount).sum());
	}

	get showChart(): boolean {
		return this.exchangeRates.length > 0;
	}

	get fundExchangeRates() {
		return this.getFundExchangeRates();
	}

	get currentInvestmentValue () {
		const exchangeRate = this.exchangeRates.where(x => moment(x.fromDate).startOf("month").isSameOrBefore(moment().startOf("month"))).orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray().firstOrDefault();
		const shareSum = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyNremId)?.currentValue ?? 0;
		return shareSum * (exchangeRate?.rate ?? 0);
	}

	get currentInvestmentPercentValue () {
		return this.getFundExchangeRates().orderByDescending(x => moment(x.fromDate).toDate().getTime()).firstOrDefault()?.rateCumulativeValue;
	}

	get previousMonthInvestmentPercentValue () {
		return this.getFundExchangeRates().orderByDescending(x => moment(x.fromDate).toDate().getTime()).firstOrDefault()?.rateValue;
	}

	get currentEvaluation () {
		const totalCapital = this.investedCapital + this.nonInvestedCapital;
		const currentInvestmentValue = this.currentInvestmentValue;
		if (totalCapital > 0) {
			return (currentInvestmentValue - totalCapital) / totalCapital * 100;
		}
		return 0;
	}

	get chartSeries () : any[] {
		if (this.partyProduct == null)
			return [];
		let dataSeriesFund: Array<any> = [ [ moment(this.year + '-01-01').add(-1, 'month').startOf("month").toDate().getTime(), 0 ] ];
		let fromDate = moment(this.year + '-01-01').startOf("year").toDate();
		let clientFromDate = moment(this.year + '-01-01').startOf("year").toDate();
		let toDate = moment(this.year + '-12-31').endOf("year").toDate();
		if (moment(fromDate).startOf("month").isBefore(moment(this.partyProduct!.dateOfSignature))) {
			clientFromDate = moment(this.partyProduct!.dateOfSignature).startOf("month").toDate();
		}
		if (moment(toDate).startOf("month").isAfter(moment(this.partyProduct!.dateOfEnding))) {
			toDate = moment(this.partyProduct!.dateOfEnding).endOf("month").toDate();
		}
		const fundExchangeRates = this.getFundExchangeRates().where(x => moment(x.fromDate).startOf("month").isSameOrAfter(moment(clientFromDate).startOf("month")) && moment(x.fromDate).startOf("month").isSameOrBefore(moment(toDate).startOf("month")));
		dataSeriesFund = dataSeriesFund.concat(fundExchangeRates.select(x => [ moment(x.fromDate).startOf("month").toDate().getTime(), x.rateCumulativeValue / 100 ]).orderBy(x => x[0]).toArray());
		return [
			{
				name: 'Zhodnocení fondu kumulativní',
				data: dataSeriesFund
			}
		];
	}

	get savingTransactions () : boolean {
		return transactionsStore.useGetter(CrudGetter.Saving);
	}

	get client () : PartyModel {
		return partiesStore.useGetter(CrudGetter.Detail, this.id);
	}

	get stateOfCapitalYear () : StateOfCapitalYearModel[] {
		return stateOfCapitalYearStore.useGetter(CrudGetter.Data);
	}

	get currentExchangeRate(): ExchangeRateModel {
		return this.exchangeRates.firstOrDefault(x => moment(x.fromDate).isSameOrBefore(moment()) && moment(x.toDate).isSameOrAfter(moment())) ?? null;
	}

	get nonInvestedCapital(): number {
		return this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId)?.currentValue;
	}

	get investedCapital(): number {
		const deposit = this.trades.where(x => x.fromCurrencyId == Constants.CurrencyCzkId && x.toCurrencyId == Constants.CurrencyNremId).select(x => x.fromAmount ?? 0).sum();
		const withdrawal = this.trades.where(x => x.fromCurrencyId == Constants.CurrencyNremId && x.toCurrencyId == Constants.CurrencyCzkId).select(x => x.toAmount ?? 0).sum();
		return Math.max(deposit - withdrawal, 0);
	}

	get currentTokenCount(): number {
		return this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyNremId)?.currentValue;
	}

	get moneyToBuyNextToken(): number {

		const nonInvestedCapital = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId)?.currentValue ?? 0;
		const currentExchangeRate = this.exchangeRates.firstOrDefault(x => moment(x.fromDate).isSameOrBefore(moment()) && moment(x.toDate).isSameOrAfter(moment()));

		if (nonInvestedCapital > 0 && currentExchangeRate != null && currentExchangeRate.secondaryRate) {
			const mod = nonInvestedCapital % currentExchangeRate.secondaryRate!;
			if (mod > 0) {
				return currentExchangeRate.secondaryRate! - mod;
			}
		}

		return 0;

	}

	@Prop() id!: string;
	@Prop() partyProductId!: string;

	getFundExchangeRates(): { fromDate: string, rate: string, rateCumulative: string, rateValue: number, rateCumulativeValue: number, exchangeRate: number }[] {

		let items: { fromDate: string, rate: string, rateCumulative: string, rateValue: number, rateCumulativeValue: number, exchangeRate: number }[] = [];
		let fundValue = 1;

		let previousExchangeRate = 0;
		let cumulativeFundValue = 0;

		this.exchangeRates.where(x => moment(x.fromDate).toDate() >= moment(this.product.startDate ?? "2024-01-01T00:00:00").startOf("day").toDate() && moment(x.fromDate).startOf("month").isSameOrBefore(moment().startOf("month"))).orderBy(x => moment(x.fromDate).toDate().getTime()).toArray().forEach((x) => {
			const exchangeRate = x!.rate;
			if (previousExchangeRate == 0) {
				previousExchangeRate = exchangeRate;
				return;
			}
			let monthFundValueRate = 0;
			monthFundValueRate = (exchangeRate / previousExchangeRate) - 1;
			previousExchangeRate = exchangeRate;
			cumulativeFundValue += monthFundValueRate;
			items.push({
				fromDate: x.fromDate,
				exchangeRate,
				rate: Number(monthFundValueRate * 100).toFixed(2) + " %",
				rateCumulative: Number(cumulativeFundValue * 100).toFixed(2) + " %",
				rateValue: Number(Number(monthFundValueRate * 100).toFixed(2)),
				rateCumulativeValue: Number(Number(cumulativeFundValue * 100).toFixed(2))
			});
			fundValue = monthFundValueRate;
		});

		if (items.any()) {
			items.last().rate = Number(items.last().rateValue).toFixed(2) + " %";
		}

		return items.orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray();

	}

	mounted () {
		this.load();
	}

	load () {
		this.loadProduct();
		this.loadProductActivities();
		this.loadRewards();
		this.loadTrades();
		this.loadExchangeRates();
	}

	loadProduct () {
		productsStore.dispatch(CrudAction.Get, { id: Constants.NremProductId, key: Constants.NremProductId });
		const self = this;
		const subscriber = this.$store.subscribe((mutation, _state) => {
			if(mutation.type === productsStore.getActionName(CrudResponseAction.GetSuccess)) {
				subscriber();
				self.loadPartyProduct();
			}
		});
	}

	loadPartyProduct () {
		partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
			{ field: "id", op: "eq", comparand: this.partyProductId }
		]));
		const self = this;
		const subscriber = this.$store.subscribe((mutation, _state) => {
			if(mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
				subscriber();
				self.makeProducts();
				if (this.partyProduct != null) {
					self.loadTransactions();
					self.loadWallets();
				}
			}
		});
	}

	loadTransactions(): void {
		walletTransactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<WalletTransactionModel>([
			{ field: "time", order: "desc", index: 0 }
		], [
			{ field: "wallet.partyProductId", op: "eq", comparand: this.partyProductId },
			{ field: "wallet.currencyId", op: "eq", comparand: Constants.CurrencyCzkId }
		], undefined, false, 'CzkTransactions'));
	}

	loadRewards(): void {
		walletTransactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<WalletTransactionModel>([
			{ field: "time", order: "desc", index: 0 }
		], [
			{ field: "wallet.partyProductId", op: "eq", comparand: this.partyProductId },
			{ field: "wallet.currencyId", op: "eq", comparand: Constants.CurrencyCltId },
			{ field: "transactionType", op: "eq", comparand: WalletTransactionTypeEnum.Reward }
		], undefined, false, 'Rewards'));
	}

	loadTrades(): void {
		tradesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TradeModel>([
			{ field: "time", order: "desc", index: 0 }
		], [
			{ field: "partyProductId", op: "eq", comparand: this.partyProductId }
		]));
	}

	loadWallets(): void {
		walletsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
			{ field: "partyProductId", op: "eq", comparand: this.partyProductId }
		]));
		const self = this;
		const subscriber = this.$store.subscribe((mutation, _state) => {
			if(mutation.type === walletsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
				this.wallets = walletsStore.useGetter(CrudGetter.Data);
				subscriber();
				self.loadTransactions();
			}
		});
	}

	makeProducts () {
		this.product = productsStore.useGetter(CrudGetter.Detail, Constants.NremProductId);
		const partyProducts = partyProductsStore.useGetter(CrudGetter.Data);
		if (partyProducts.length > 0) {
			this.partyProduct = partyProducts[0];
		}
	}

	loadProductActivities () {
		productActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductActivityModel>([
			{ field: "createdAt", order: "desc", index: 0 }
		], [
			{ field: "productId", op: "eq", comparand: Constants.NremProductId }
		]))
	}

	loadExchangeRates() {
		exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
			{ field: "createdAt", order: "desc", index: 0 }
		], [
			{ field: "productId", op: "eq", comparand: Constants.NremProductId }
		], undefined, false, Constants.NremProductId))
		this.subscribe(exchangeRatesStore, CrudReponse.GetAll, Constants.NremProductId).then((e: ExchangeRateModel[]) => {
			this.exchangeRates = e;
		}).catch((e) => {
			console.log(e);
		});
	}

	changed () {
		this.load();
	}

	removed () {
		this.$router.push({ name: "ClientProducts", params: { id: this.id } })
	}

	get chartOptions() {
		let maxY = this.chartSeries.firstOrDefault()?.data.select(x => x[1]).toArray().max() ?? 0;
		if (maxY == 0) {
			maxY = 0.2
		}
		const n = {
			chart: {
				height: 300,
				type: 'line',
				background: "transparent",
				toolbar: {
					show: false
				},
				zoom: {
					enabled: false
				}
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				type: 'datetime',
				tickAmount: 6,
				labels: {
					formatter: (value: Date) => {
						if (moment(value).isBefore(moment(this.year + "-01-01 00:00:00"))) {
							return "Zahájení roku";
						}
						else {
							return moment(value).format("MM.YYYY");
						}
					}
				},
				min: moment(this.year + "-01-01 00:00:00").add(-1, "month").toDate().getTime(),
				max: moment(this.year + "-12-31 23:59:59").toDate().getTime()
			},
			yaxis: [
				{
					labels: {
						formatter: function (value: number) {
							return Number(value * 100).toFixed(2).concat(" %");
						}
					},
					forceNiceScale: true,
					max: maxY * 1.1
				},
				{
					opposite: true,
					labels: {
						formatter: function (value: number) {
							return Number(value).toString().concat(" Kč");
						}
					},
					forceNiceScale: true,
				}
			],
			theme: {
				mode: "dark"
			},
			dataLabels: {
				enabled: false
			},
			tooltip: {
				custom: (data) => {
					if (data && data.w) {
						const date = data.w.globals.initialSeries[0].data[data.dataPointIndex][0];
						const value = data.w.globals.initialSeries[0].data[data.dataPointIndex][1];
						const exchangeRate = this.fundExchangeRates.firstOrDefault(x => moment(x.fromDate).startOf("month").toDate().getTime() == date);
						let html = `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">04.2024</div>
<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
   <span class="apexcharts-tooltip-marker" style="background-color: rgb(78, 205, 196);"></span>
   <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
      <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Zhodnocení fondu kumulativní: </span><span class="apexcharts-tooltip-text-y-value">${(value * 100).toFixed(2)} %</span></div>
      <div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span></div>
      <div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div>
   </div>
</div>
`;
						if (exchangeRate && exchangeRate.exchangeRate) {
							html += `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 2; display: flex;">
   <span class="apexcharts-tooltip-marker" style="background-color: rgb(199, 244, 100);"></span>
   <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
      <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Cena za cenný papír: </span><span class="apexcharts-tooltip-text-y-value">${exchangeRate.exchangeRate} Kč</span></div>
      <div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span></div>
      <div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div>
   </div>
</div>`;
							return html;
						}
					}
					return '';
				}
			}
		};
		return n;
	}

	getIm4RateOfTrade(item: TradeModel): number | null {
		return item.tradeConversions.firstOrDefault(x => x.exchangeRate.type == ExchangeRateTypeEnum.Product)?.exchangeRate.secondaryRate ?? null;
	}

}
