import {RouteConfig} from 'vue-router';

import Clients from './pages/Clients.vue';
import Client from './pages/Client.vue';
import ClientGeneral from './pages/ClientGeneral.vue';
import ClientProducts from './pages/ClientProducts.vue';
import ClientFiles from './pages/ClientFiles.vue';
import ClientComments from './pages/ClientComments.vue';
import ClientCredits from './pages/ClientCredits.vue';
import ClientProductNOIF from './pages/ClientProductNOIF.vue';
import ClientProductReOpportunities from './pages/ClientProductReOpportunities.vue';
import ClientProductNREI from './pages/ClientProductNREI.vue';
import ClientProductWealth from './pages/ClientProductWealth.vue';
import ClientProductClt from './pages/ClientProductClt.vue';
import ClientProductCft from './pages/ClientProductCft.vue';
import ClientProductNrem from "@/libs/clients/pages/ClientProductNrem.vue";

export default [
    {
        path: '/clients',
        name: 'Clients',
        component: Clients
    },
    {
        path: '/clients/:id',
        name: 'Client',
        component: Client,
        props: (route) => ({id: route.params.id}),
        children: [
            {
                path: 'info',
                name: 'ClientGeneral',
                component: ClientGeneral,
                props: (route) => ({id: route.params.id}),
            },
            {
                path: 'products',
                name: 'ClientProducts',
                component: ClientProducts,
                props: (route) => ({id: route.params.id}),
            },
            {
                path: 'files',
                name: 'ClientFiles',
                component: ClientFiles,
                props: (route) => ({id: route.params.id}),
            },
            {
                path: 'comments',
                name: 'ClientComments',
                component: ClientComments,
                props: (route) => ({id: route.params.id}),
            },
            {
                path: 'credits',
                name: 'ClientCredits',
                component: ClientCredits,
                props: (route) => ({id: route.params.id}),
            },
            {
                path: 'products/noif/:partyProductId',
                name: 'ClientProductNOIF',
                component: ClientProductNOIF,
                props: (route) => ({id: route.params.id, partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/re-opportunities/:partyProductId',
                name: 'ClientProductReOpportunities',
                component: ClientProductReOpportunities,
                props: (route) => ({id: route.params.id, partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/nrei/:partyProductId',
                name: 'ClientProductNREI',
                component: ClientProductNREI,
                props: (route) => ({id: route.params.id, partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/wealth/:partyProductId',
                name: 'ClientProductWealth',
                component: ClientProductWealth,
                props: (route) => ({id: route.params.id, partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/clt/:partyProductId',
                name: 'ClientProductClt',
                component: ClientProductClt,
                props: (route) => ({id: route.params.id, partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/cft/:partyProductId',
                name: 'ClientProductCft',
                component: ClientProductCft,
                props: (route) => ({id: route.params.id, partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/nrem/:partyProductId',
                name: 'ClientProductNrem',
                component: ClientProductNrem,
                props: (route) => ({id: route.params.id, partyProductId: route.params.partyProductId}),
            }
        ]
    }
] as RouteConfig[];
