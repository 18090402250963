






































import {
	ApplicationUserModel,
	ExchangeRateModel,
	PartyProductModel,
	WalletModel,
	WalletTransactionModel,
	WalletTransactionTypeEnum
} from '@/libs/Api';
import {Guid} from '@/libs/common/functions/guid';
import {Component, Prop, Ref} from 'vue-property-decorator';
import NWDialog from '@/libs/clients/components/NWDialog.vue';
import {applicationUserStore} from '@/libs/user-settings/+state/store';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import NNremDialog from "@/libs/clients/components/NNremDialog.vue";
import moment from "moment/moment";
import {Constants} from "@/libs/constants/constants";
import {walletTransactionsStore} from "@/libs/wallet-transactions/+state/store";
import {walletsStore} from "@/libs/wallets/+state/store";
import {CrudAction, CrudReponse, CrudResponseAction} from "@/libs/core/+state/models/crud-action";
import {createCrudQueryPayload} from "@/libs/core/+state/models/crud-query-payload";
import {exchangeRatesStore} from "@/libs/exchange-rates/+state/store";
import Page from "@/Page.vue";
import RequestForQuotationDialog from "@/libs/account/components/RequestForQuotationDialog.vue";

@Component({
    components: {
	    RequestForQuotationDialog,
	    NNremDialog
    }
})
export default class NNremCard extends Page {

    @Prop() partyProduct!: PartyProductModel;
    @Prop() clientId!: string;
    @Ref() dialog!: NWDialog;
    @Prop({ default: false }) accountSection!: boolean;
    
    newId: string | null = Guid.EmptyGuid();
	wallets: WalletModel[] = [];
	exchangeRates: ExchangeRateModel[] = [];

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

	mounted() {
		if (this.partyProduct.id != Guid.EmptyGuid()) {
			this.loadWallets();
			this.loadTransactions();
			this.loadExchangeRates();
		}
	}

    openPartyProduct() {
        this.dialog.open();
    }

	get currentInvestmentValue () {
		const exchangeRate = this.exchangeRates.where(x => moment(x.fromDate).startOf("month").isSameOrBefore(moment().startOf("month"))).orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray().firstOrDefault();
		const shareSum = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyNremId)?.currentValue ?? 0;
		return shareSum * (exchangeRate?.rate ?? 0);
	}

	get currentInvestmentPercentValue () {
		return this.getFundExchangeRates().orderByDescending(x => moment(x.fromDate).toDate().getTime()).firstOrDefault()?.rateCumulativeValue;
	}

	getFundExchangeRates(): { fromDate: string, rate: string, rateCumulative: string, rateValue: number, rateCumulativeValue: number, exchangeRate: number }[] {

		let items: { fromDate: string, rate: string, rateCumulative: string, rateValue: number, rateCumulativeValue: number, exchangeRate: number }[] = [];
		let fundValue = 1;

		let previousExchangeRate = 0;
		let cumulativeFundValue = 0;

		this.exchangeRates.orderBy(x => moment(x.fromDate).toDate().getTime()).toArray().forEach((x) => {
			const exchangeRate = x!.rate;
			if (previousExchangeRate == 0) {
				previousExchangeRate = exchangeRate;
				return;
			}
			let monthFundValueRate = 0;
			monthFundValueRate = (exchangeRate / previousExchangeRate) - 1;
			previousExchangeRate = exchangeRate;
			cumulativeFundValue += monthFundValueRate;
			items.push({
				fromDate: x.fromDate,
				exchangeRate,
				rate: Number(monthFundValueRate * 100).toFixed(2) + " %",
				rateCumulative: Number(cumulativeFundValue * 100).toFixed(2) + " %",
				rateValue: Number(Number(monthFundValueRate * 100).toFixed(2)),
				rateCumulativeValue: Number(Number(cumulativeFundValue * 100).toFixed(2))
			});
			fundValue = monthFundValueRate;
		});

		if (items.any()) {
			items.last().rate = Number(items.last().rateValue).toFixed(2) + " %";
		}

		return items.orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray();

	}

	get currentTokenCount(): number {
		return this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyNremId)?.currentValue;
	}

	loadWallets(): void {
		walletsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
			{ field: "partyProductId", op: "eq", comparand: this.partyProduct.id }
		], undefined, false, 'NNremCard'));
		this.subscribe(walletsStore, CrudReponse.GetAll, 'NNremCard').then(x => {
			this.wallets = x;
		});
	}

	loadExchangeRates() {
		exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
			{ field: "createdAt", order: "desc", index: 0 }
		], [
			{ field: "productId", op: "eq", comparand: Constants.NremProductId }
		], undefined, false, Constants.NremProductId));
		const self = this;
		const subscriber = this.$store.subscribe((mutation, _state) => {
			if(mutation.type === exchangeRatesStore.getActionName(CrudResponseAction.GetAllSuccess)) {
				this.exchangeRates = exchangeRatesStore.useGetter(CrudGetter.Data, Constants.NremProductId);
			}
		});
	}

	loadTransactions(): void {
		walletTransactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<WalletTransactionModel>([
			{ field: "time", order: "desc", index: 0 }
		], [
			{ field: "wallet.partyProductId", op: "eq", comparand: this.partyProduct.id! },
			{ field: "wallet.currencyId", op: "eq", comparand: Constants.CurrencyCzkId }
		], undefined, false, 'CzkTransactions'));
	}

}

