import {RouteConfig} from 'vue-router';

import Dashboard from './pages/Dashboard.vue';

export default [
    {
        path: '/',
        name: 'Home',
        component: Dashboard
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    }
] as RouteConfig[];
