








































































































































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { ApplicationUserModel, CodebookModel, CodebookType, PartyModel, EventModel, EventParticipantModel, AccountTypeEnum } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { eventsStore } from '@/libs/events/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import moment from 'moment';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';

@Component({
})
export default class EventDialog extends Vue {

    isActive = true;
    dialog = false;
    innerValue: string | null = null;
    item: string | null = null;
    loadOverride: any = null;
    event: EventModel | null = null;
    valid = false;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];
    deletingEvent = false;
    deleteEventDialog = false;

    @Prop() name!: string;
    @Prop() activator!: string;
    @Ref() form!: any;
    
    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    get isNewItem(): boolean {
        return this.item == null || this.item == Guid.EmptyGuid();
    }

    get saving(): boolean {
        return eventsStore.useGetter(CrudGetter.Saving);
    }

    get canDelete(): boolean {
        return true;
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get isAdminOrManager () {
        const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        return user.accountType == AccountTypeEnum.Admin || user.accountType == AccountTypeEnum.Manager;
    }

    get isReadonly () {
        const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        if (user.accountType != AccountTypeEnum.Admin && user.accountType != AccountTypeEnum.Manager && this.event.isPublic == true) {
            return true;
        }
        return false;
    }

    mounted () {
        if (this.activator) {
            this.dialog = false;
            this.innerValue = this.item;
        }
        else {
            this.load();
        }
    }

    createItem () {
        this.open(null);
    }

    open (v: string | null, loadOverride: any = null) {
        this.dialog = true;
        this.item = v ?? Guid.EmptyGuid();
        this.load(loadOverride);
    }

    load(v: any = null) {
        this.loadOverride = v ?? null;
        if (this.item == null || this.item == Guid.EmptyGuid()) {
            const eventTypeModels = codebooksStore.useGetter(CrudGetter.Data).filter((e: CodebookModel) => { return e.type == CodebookType.EventType; }).sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; });
            const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
            let value = {
                id: Guid.EmptyGuid(),
                name: "",
                eventTypeId: eventTypeModels.length > 0 ? eventTypeModels[0].id : null,
                start: "",
                end: "",
                description: "",
                color: "43D01A",
                eventParticipants: this.user.accountType == "Consultant" ? [{
                    id: Guid.EmptyGuid(),
                    partyId: user.partyId,
                    eventId: Guid.EmptyGuid()
                } as EventParticipantModel] : [{
                    id: Guid.EmptyGuid(),
                    partyId: undefined,
                    eventId: Guid.EmptyGuid()
                } as EventParticipantModel],
                isPublic: false
            } as EventModel;
            if (this.loadOverride) {
                Object.assign(value, this.loadOverride);
            }
            this.event = value;
        }
        else {
            eventsStore.dispatch(CrudAction.Get, { id: this.item, key: this.item });
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === eventsStore.getActionName(CrudResponseAction.GetSuccess)) {
                    subscribtion();
                    self.event = mutation.payload.item;
                }
            });
        }
        
    }

    save () {
        if (this.form.validate() && (this.event!.eventParticipants!.length > 0 || this.event!.isPublic)) {
            if (this.event!.isPublic) {
                this.event!.eventParticipants = [];
            }
            if (this.item == null || this.item == Guid.EmptyGuid()) {
                eventsStore.dispatch(CrudAction.Create, { item: this.event });
                const self = this;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === eventsStore.getActionName(CrudResponseAction.CreateSuccess)) {
                        subscribtion();
                        self.close();
                    }
                    else if (mutation.type === eventsStore.getActionName(CrudResponseAction.CreateFailed)) {
                        subscribtion();
                    }
                });
            }
            else {
                eventsStore.dispatch(CrudAction.Update, { item: this.event });
                const self = this;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === eventsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                        subscribtion();
                        self.close();
                    }
                    else if (mutation.type === eventsStore.getActionName(CrudResponseAction.UpdateFailed)) {
                        subscribtion();
                        self.close();
                    }
                });
            }
        }
    }

    deleteEventClick() {
        this.deleteEventDialog = true;
    }

    deleteEvent () {
        this.deletingEvent = true;
        eventsStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [ { field: "id", op: "eq", comparand: this.item! } ]));
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === eventsStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                self.deleteEventDialog = false;
                self.deletingEvent = false;
                subscribtion();
                self.close();
            }
            else if (mutation.type === eventsStore.getActionName(CrudResponseAction.DeleteFailed)) {
                self.deletingEvent = false;
                subscribtion();
            }
        });
    }

    close () {
        this.dialog = false;
        this.event = null;
    }

    startFullDateChanged (v: string) {
        if (this.event != null && (this.event!.end == null || this.event!.end == "")) {
            this.event!.end = moment(v).add(1, "hours").format("YYYY-MM-DDTHH:mm:00Z");
        }
    }

    addParticipant () {
        this.event!.eventParticipants!.push({
            id: Guid.EmptyGuid(),
            partyId: undefined,
            eventId: this.event!.id!
        } as EventParticipantModel)
    }
    
}
