





















































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { PartyModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { partiesStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';

import AccountTypeSelect from '@/libs/common/components-smart/AccountTypeSelect.vue';

@Component({
    components: { AccountTypeSelect }
})
export default class PartyDialog extends Vue {

  isActive = true;
  show = true;
  innerValue: PartyModel | null = null;
  valid = false;
  basicRules = [
      (v: any) => !!v || 'Toto pole je povinné.'
  ];
  @VModel() item!: PartyModel;

  @Ref() form!: any;

  @Watch("show")
  showChanged(v: boolean) {
    if (!v)
      this.close();
  }

  @Watch("item")
  itemChanged (v: PartyModel) {
    this.innerValue = v;
  }
  
  get isNewItem(): boolean {
    return this.innerValue?.id == Guid.EmptyGuid();
  }

  get saving(): boolean {
    return partiesStore.useGetter(CrudGetter.Saving);
  }

  get canDelete(): boolean {
    return true;
  }

  mounted () {
    this.innerValue = this.item;
  }

  save () {
    if (this.form.validate()) {
      if (this.innerValue?.id == Guid.EmptyGuid()) {
        partiesStore.dispatch(CrudAction.Create, { item: this.innerValue });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === partiesStore.getActionName(CrudResponseAction.CreateSuccess)) {
              subscribtion();
              self.close();
          }
          else if (mutation.type === partiesStore.getActionName(CrudResponseAction.CreateFailed)) {
              subscribtion();
          }
        });
      }
      else {
        partiesStore.dispatch(CrudAction.Update, { item: this.innerValue });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === partiesStore.getActionName(CrudResponseAction.UpdateSuccess)) {
              subscribtion();
              self.close();
          }
          else if (mutation.type === partiesStore.getActionName(CrudResponseAction.UpdateFailed)) {
              subscribtion();
              self.close();
          }
        });
      }
    }
  }

  deleteParty () {
    partiesStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [ { field: "id", op: "eq", comparand: this.innerValue!.id! } ]));
    const self = this;
    const subscribtion = this.$store.subscribe((mutation, _state) => {
      if (mutation.type === partiesStore.getActionName(CrudResponseAction.DeleteSuccess)) {
          subscribtion();
          self.close();
      }
      else if (mutation.type === partiesStore.getActionName(CrudResponseAction.DeleteFailed)) {
          subscribtion();
      }
    });
  }

  close () {
    this.$emit("input", null);
  }
    
}
