































































import moment from 'moment';
import { Component, Vue, Watch, Prop, VModel } from 'vue-property-decorator';

@Component
export default class DateTimePicker extends Vue {

    menu = false;
    text = "";

    date: string | null = null;
    modal = false;
    menu2 = false;
    time: string | null = null;
    innerValueComputed = false;
    @Prop() min!: string;
    @Prop() max!: string;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) readonly!: boolean;

    options: string[] = [
      "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30",
      "05:00", "05:30", "06:00", "06:30",
      "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
      "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30",
      "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30",
      "22:00", "22:30", "23:00", "23:30"
    ]
    
    @VModel() innerValue!: string;
    @Prop() label!: [ string ];
    @Prop() placeholder!: [ string ];

    mounted () {
      if (this.innerValue) {
        this.date = moment(this.innerValue).format("YYYY-MM-DD");
        this.time = moment(this.innerValue).format("HH:mm");
        this.text = moment(this.innerValue).format("DD.MM.YYYY");
      }
    }

    @Watch("date")
    dateChanged (v: string, p: string) {
      this.computeDateTime();
      this.computeFullDateChanged();
    }

    @Watch("time")
    timeChanged (v: string, p: string) {
      if (v == p)
        return;
      this.computeDateTime();
      this.computeFullDateChanged();
    }

    @Watch("innerValue")
    innerValueChanged (v: string, p: string) {
      if (v == p || this.innerValueComputed) {
        this.innerValueComputed = false;
        return;
      }
      this.date = moment(v).format("YYYY-MM-DD");
      this.time = moment(v).format("HH:mm");
      this.text = moment(v).format("DD.MM.YYYY");
    }

    @Watch("text")
    textChanged (v: string) {
      const dateRegEx = /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/;
      if (moment(v, "DD.MM.YYYY").isValid() && dateRegEx.test(v)) {
        this.date = moment(v, "DD.MM.YYYY").format("YYYY-MM-DD");
      }
    }

    get rules() : any[] {
      let rules: any[] = [];
      if (this.required) {
        rules.push((v: any) => !!v || 'Toto pole je povinné.');
        if (this.min && moment(this.min).isValid()) {
          rules.push((v: any) => (moment(v, "DD.MM.YYYY").isValid() && moment(v, "DD.MM.YYYY").isSameOrAfter(moment(this.min).startOf("day"))) || 'Minimální datum ' + moment(this.min).format("DD.MM.YYYY"));
        }
        if (this.max && moment(this.max).isValid()) {
          rules.push((v: any) => (moment(v, "DD.MM.YYYY").isValid() && moment(v, "DD.MM.YYYY").isSameOrBefore(moment(this.max).endOf("day"))) || 'Maximální datum ' + moment(this.max).format("DD.MM.YYYY"));
        }
      }
      return rules;
    }

    computeDateTime () {
      let dateTime: string | null = null;
      let fullDateChanged = false;
      if (this.date != null) {
        let timeValue = "00:00";
        if (this.time != null && this.time.replaceAll(":", "").length == 4) {
          const tempTimeValue = this.time.replaceAll(":", "").substring(0, 4);
          const tempHour = tempTimeValue.substring(0, 2);
          const tempMinute = tempTimeValue.substring(2, 4);
          if (Number(tempHour) < 24) {
            if (Number(tempMinute) < 60) {
              timeValue = tempHour.concat(":").concat(tempMinute);
              fullDateChanged = true;
            }
          }
        }
        dateTime = moment(this.date.concat("T").concat(timeValue).concat(":00")).format("YYYY-MM-DDTHH:mm:00Z");
      }
      this.text = dateTime ? moment(dateTime).format("DD.MM.YYYY") : "";
      this.innerValueComputed = true;
      this.$emit("input", dateTime);
      if (fullDateChanged) {
		this.$emit("fullDateChanged", dateTime);
      }
    }

    computeFullDateChanged () {
      if (this.date != null && this.date.length > 0 && this.time != null && this.time != "") {
        console.log("");
        //this.$emit("fullDateChanged", this.date.concat("T").concat(this.time).concat(":00"));
      }
    }

}
