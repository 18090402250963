























import {CodebookModel, ProductModel} from '@/libs/Api';
import {Component, Vue} from 'vue-property-decorator';
import {CrudAction} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Constants} from '@/libs/constants/constants';
import {codebooksStore} from '@/libs/codebooks/+state/store';
import {productsStore} from '@/libs/products/+state/store';

@Component({
	computed: {
		Constants() {
			return Constants
		}
	},
    components: {
        CrudTable
    }
})
export default class ProductCltGeneral extends Vue {

	firstLoad = true;

	get pending(): boolean {
		const pending = codebooksStore.useGetter(CrudGetter.Pending);
		if (!pending) {
			this.firstLoad = false;
		}
		return pending;
	}

	get codebooks(): any {
		return codebooksStore.useGetter(CrudGetter.Data);
	}

	get product(): ProductModel {
		return {...productsStore.useGetter(CrudGetter.Detail, Constants.CltProductId)};
	}

	get savingProduct(): boolean {
		return productsStore.useGetter(CrudGetter.Saving, Constants.CltProductId);
	}

	mounted() {
		this.getCodebooks();
		this.getProduct();
	}

	getProduct() {
		productsStore.dispatch(CrudAction.Get, { id: Constants.CltProductId, key: Constants.CltProductId });
	}

	saveProduct() {
		productsStore.dispatch(CrudAction.Update, { item: this.product, key: Constants.CltProductId });
	}

	getCodebooks() {
		codebooksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CodebookModel>({
			field: 'name',
			order: 'asc'
		}));
	}
    
}
