








import {CodebookModel, PartyModel, StateOfCapitalModel, TransactionModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { stateOfCapitalStore } from '@/libs/state-of-capital/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Constants } from '@/libs/constants/constants';
import { codebooksStore } from '@/libs/codebooks/+state/store';

@Component({
	computed: {
		Constants() {
			return Constants
		}
	},
    components: {
        CrudTable
    }
})
export default class ProductNoifGeneral extends Vue {

	firstLoad = true;

	get pending(): boolean {
		const pending = codebooksStore.useGetter(CrudGetter.Pending);
		if (!pending) {
			this.firstLoad = false;
		}
		return pending;
	}

	get codebooks(): any {
		return codebooksStore.useGetter(CrudGetter.Data);
	}

	mounted() {
		this.getCodebooks();
	}

	getCodebooks() {
		codebooksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CodebookModel>({
			field: 'name',
			order: 'asc'
		}));
	}
    
}
