import {
    HttpResponse,
    NotificationModel,
    QueryModel,
    RequestParams,
    NotificationModelIEnumerableApiResultSuccess,
    NotificationModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    FullRequestParams,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";

export class NotificationsDataService implements CrudService<NotificationModel, NotificationModel> {
    
    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error("Method not implemented.");
    }
    
    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error("Method not implemented.");
    }
    
    getAll(query: CrudDataQuery<NotificationModel>): Promise<CrudEnumerableResponse<NotificationModel>> {
        return ApiService.api.getNotificationBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<NotificationModel>> {
        return ApiService.api.getNotificationById(id, params);
    }

    create(item: NotificationModel, params?: RequestParams): Promise<CrudResponse<NotificationModel>> {
        return ApiService.api.insertNotification(item, params);
    }

    update(item: NotificationModel, params?: RequestParams): Promise<NotificationModel> {
        return ApiService.api.updateNotification(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<NotificationModel> {
        return ApiService.api.patchNotification(id, patches).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<NotificationModel>): Promise<CrudEnumerableResponse<NotificationModel>> {
        return ApiService.api.deleteNotifications(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    download(query: CrudDataQuery<NotificationModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw "NotImplemented";
    }

}
