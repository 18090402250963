




















import { Component, Prop, PropSync, VModel, Vue, Watch } from 'vue-property-decorator';
import { PartyProductModel, ProductModel } from '@/libs/Api';

@Component({ })
export default class ProductCard extends Vue {

    @VModel() item!: ProductModel;
    @Prop() link!: any;
    
}
