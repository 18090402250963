import { AuthState, AuthAction, AuthGetter } from '@/libs/auth/models/auth-state';
import { Module } from 'vuex';
import store from '@/libs/core/+state/store';
import { isFunction } from 'lodash';

import ApiService from '@/libs/core/api-service';
import { ConfirmEmailModel, IdentityResult, ResetPasswordModel, ConfirmResetPasswordModel, SetPasswordModel } from '@/libs/Api';

import {setAuthTokens, getAccessToken } from 'axios-jwt'
import axios from 'axios'

import { SignInTokensModel, SignInModel } from '@/libs/auth/models/auth-models';

export interface IAuthStore {
    dispatch (action: AuthAction, payload: ConfirmEmailModel | SignInModel | ResetPasswordModel | ConfirmResetPasswordModel | SetPasswordModel | string | null): void;
    dispatch (action: AuthAction): void;
    useGetter (getter: AuthGetter): any;
    getActionName (action: string): string;
}

function registerAuthStore(): IAuthStore {
    const authStoreObject: Module<AuthState, unknown> = {
        namespaced: true,
        state: {
            isLoggedIn: false,
            userInfo: null,
            tenant: null
        },
        actions: {
            [AuthAction.ActivateAccount]: ({commit, state}, payload: ConfirmEmailModel) =>{
                ApiService.api.activateAccount(payload).then((e: any) => {
                    const data = e.returnValue as IdentityResult;
                    console.log(data);
                    commit(AuthAction.ActivateAccountResponse, payload);
                });
            },
            [AuthAction.SignIn]: ({commit, state}, payload: SignInModel) => {
                const params = new URLSearchParams();
                params.append('client_id', 'Web');
                params.append('grant_type', 'password');
                params.append('username', payload.email!);
                params.append('password', payload.password!);
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
                const baseUrl = (window as any).SaasApiEndpoint;
                const axiosInstance = axios.create({ baseURL: baseUrl });
                const login = async (params: any, config: any = null) => {
                    await axiosInstance.post('/connect/token', params, config).then((e: any) => {
                        const payload = new SignInTokensModel();
                        payload.accessToken = e.data.access_token;
                        payload.refreshToken = e.data.access_token;
                        commit(AuthAction.SignInResponseSuccess, payload);
                        authStore.dispatch(AuthAction.UserInfo);
                    }).catch((e: any) => {
                        commit(AuthAction.SignInResponseFailed, e?.response?.data);
                    });
                    
                }
                login(params, config);
            },
            [AuthAction.SignOut]: ({commit, state}) => {
                const baseUrl = (window as any).SaasApiEndpoint;
                const axiosInstance = axios.create({ baseURL: baseUrl });
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
                const signout = async (config: any = null) => {
                    commit(AuthAction.SignOut);
                }
                signout(config);
            },
            [AuthAction.UserInfo]: ({commit, state}) => {
                const token = getAccessToken();
                const baseUrl = (window as any).SaasApiEndpoint;
                const axiosInstance = axios.create({ baseURL: baseUrl });
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    maxRedirects: 0
                }
                const getUserInfo = async (config: any) => {
                    try {
                        const response = await axiosInstance.get('/connect/userinfo', config);
                        commit(AuthAction.UserInfo, response.data);
                    }
                    catch (e) {
                        console.log(e);
                        commit(AuthAction.UserInfo, null);
                    }
                }
                getUserInfo(config);
            },
            [AuthAction.ResetPassword]: ({commit, state}, payload: ResetPasswordModel) =>{
                ApiService.api.resetPassword(payload).then((e: any) => {
                    const data = e.data as IdentityResult;
                    commit(AuthAction.ResetPasswordResponse, data);
                });
            },
            [AuthAction.ResetSetNewPassword]: ({commit, state}, payload: ConfirmResetPasswordModel) =>{
                ApiService.api.confirmResetPassword(payload).then((e: any) => {
                    const data = e.data as IdentityResult;
                    commit(AuthAction.ResetSetNewPasswordResponse, data);
                });
            },
            [AuthAction.SetPassword]: ({commit, state}, payload: SetPasswordModel) =>{
                ApiService.api.setPassword(payload).then((e: any) => {
                    const data = e.data as IdentityResult;
                    commit(AuthAction.SetPasswordResponse, data);
                });
            },
        },
        mutations: {
            [AuthAction.ActivateAccount]: (state: AuthState, payload: SignInModel) => {
                state.isLoggedIn = true;
            },
            [AuthAction.SignInResponseSuccess]: (state: AuthState, payload: any) => {
                state.isLoggedIn = true;
                setAuthTokens({
                    accessToken: payload.accessToken!,
                    refreshToken: payload.refreshToken!
                });
            },
            [AuthAction.SignInResponseFailed]: (state: AuthState, payload: any) => {
                state.isLoggedIn = false;
            },
            [AuthAction.SignOut]: (state: AuthState) => {
                state.isLoggedIn = false;
                setAuthTokens({
                    accessToken: "",
                    refreshToken: ""
                });
            },
            [AuthAction.UserInfo]: (state: AuthState, payload: any) => {
                console.log(payload);
                if (payload != null && state.isLoggedIn == false) {
                    state.isLoggedIn = true;
                }
                else if (payload == null) {
                    state.isLoggedIn = false;
                }
                state.userInfo = payload;
            },
            [AuthAction.ResetPasswordResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
            [AuthAction.ResetSetNewPasswordResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
            [AuthAction.SetPasswordResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
            [AuthAction.ActivateAccountResponse]: (state: AuthState, payload: any) => {
                console.log(payload);
            },
        },
        getters: {
            [AuthGetter.GetIsLoggedIn]: (state: AuthState): boolean => {
                return state.isLoggedIn;
            },
            [AuthGetter.GetUserInfo]: (state: AuthState): any => {
                return state.userInfo;
            }
        }
    };
    store.registerModule("AUTH", authStoreObject);
    return {
        dispatch: (action: AuthAction, payload: ConfirmEmailModel | SignInModel | ResetPasswordModel | ConfirmResetPasswordModel | SetPasswordModel | string | null = null) => {
            store.dispatch(`AUTH/${action}`, payload);
        },
        useGetter: (getter: AuthGetter) => {
            const val = store.getters[`AUTH/${getter}`];
            return isFunction(val) ? val() : val;
        },
        getActionName: (action: string) => `AUTH/${action}`,
    }
}

export const authStore = registerAuthStore();
