import {
    HttpResponse,
    EventParticipantModel,
    QueryModel,
    RequestParams,
    EventParticipantModelIEnumerableApiResultSuccess,
    EventParticipantModelApiResultSuccess,
    ApiResultFailed,
    Operation,
    FullRequestParams,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";

export class EventParticipantsDataService implements CrudService<EventParticipantModel, EventParticipantModel> {
    
    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error("Method not implemented.");
    }
    
    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error("Method not implemented.");
    }
    
    getAll(query: CrudDataQuery<EventParticipantModel>): Promise<CrudEnumerableResponse<EventParticipantModel>> {
        return ApiService.api.getEventParticipantBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<EventParticipantModel>> {
        return ApiService.api.getEventParticipantById(id, params);
    }

    create(item: EventParticipantModel, params?: RequestParams): Promise<CrudResponse<EventParticipantModel>> {
        return ApiService.api.insertEventParticipant(item, params);
    }

    update(item: EventParticipantModel, params?: RequestParams): Promise<EventParticipantModel> {
        return ApiService.api.updateEventParticipant(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<EventParticipantModel> {
        return ApiService.api.patchEventParticipant(id, patches).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<EventParticipantModel>): Promise<CrudEnumerableResponse<EventParticipantModel>> {
        return ApiService.api.deleteEventParticipants(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    download(query: CrudDataQuery<EventParticipantModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw "NotImplemented";
    }

}
