





















































































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { ApplicationUserModel, CodebookModel, CodebookType, PartyModel, NotificationModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { notificationsStore } from '@/libs/notifications/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import moment from 'moment';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { authStore } from '@/libs/auth/+store/store';

@Component({})
export default class NotificationDialog extends Vue {

    isActive = true;
    dialog = false;
    innerValue: string | null = null;
    item: string | null = null;
    loadOverride: any = null;
    notification: NotificationModel | null = null;
    valid = false;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];
    now = moment().format("YYYY-MM-DDTHH:MM:ss");
    newId = Guid.EmptyGuid();
    @Prop() name!: string;
    @Prop() activator!: string;
    @Prop() activatorIcon!: string;
    @Prop() activatorIconRight!: string;
    @Ref() form!: any;
    
    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    get isNewItem(): boolean {
        return this.item == null || this.item == Guid.EmptyGuid();
    }

    get saving(): boolean {
        return notificationsStore.useGetter(CrudGetter.Saving);
    }

    get canDelete(): boolean {
        return true;
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    mounted () {
        if (this.activator) {
            this.dialog = false;
            this.innerValue = this.item;
        }
        else {
            this.load();
        }
    }

    createItem () {
        this.open(null, {
            time: moment().add(1, "day").format("YYYY-MM-DDT08:00:00Z")
        });
    }

    open (v: string | null, loadOverride: any = null) {
        this.dialog = true;
        this.item = v ?? Guid.EmptyGuid();
        this.load(loadOverride);
    }

    load(v: any = null) {
        this.loadOverride = v ?? null;
        if (this.item == null || this.item == Guid.EmptyGuid()) {
            const userModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
            const notificationTypeModels = codebooksStore.useGetter(CrudGetter.Data).filter((e: CodebookModel) => { return e.type == CodebookType.NotificationType; }).sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; });
            let value = {
                id: Guid.EmptyGuid(),
                name: "",
                notificationTypeId: notificationTypeModels.length > 0 ? notificationTypeModels[0].id : null,
                time: "",
                description: "",
                ownerId: userModel.partyId,
                partyId: undefined,
                partyProductId: undefined,
                isConfirmed: false
            } as NotificationModel;
            if (this.loadOverride) {
                Object.assign(value, this.loadOverride);
            }
            this.notification = value;
        }
        else {
            notificationsStore.dispatch(CrudAction.Get, { id: this.item, key: this.item });
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === notificationsStore.getActionName(CrudResponseAction.GetSuccess)) {
                    subscribtion();
                    self.notification = mutation.payload.item;
                }
            });
        }
    }

    save () {
        if (this.form.validate()) {
            if (this.item == null || this.item == Guid.EmptyGuid()) {
                notificationsStore.dispatch(CrudAction.Create, { item: this.notification });
                const self = this;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === notificationsStore.getActionName(CrudResponseAction.CreateSuccess)) {
                        subscribtion();
                        self.close();
                    }
                    else if (mutation.type === notificationsStore.getActionName(CrudResponseAction.CreateFailed)) {
                        subscribtion();
                    }
                });
            }
            else {
                notificationsStore.dispatch(CrudAction.Update, { item: this.notification });
                const self = this;
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === notificationsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                        subscribtion();
                        self.close();
                    }
                    else if (mutation.type === notificationsStore.getActionName(CrudResponseAction.UpdateFailed)) {
                        subscribtion();
                        self.close();
                    }
                });
            }
        }
    }

    deleteUserNotification () {
        notificationsStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [ { field: "id", op: "eq", comparand: this.item! } ]));
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === notificationsStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                subscribtion();
                self.close();
            }
            else if (mutation.type === notificationsStore.getActionName(CrudResponseAction.DeleteFailed)) {
                subscribtion();
            }
        });
    }

    close () {
        this.dialog = false;
        this.notification = null;
    }

}
