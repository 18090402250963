var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column flex-grow-1 mb-4"},[_c('div',{staticClass:"detail-header"},[_c('div',{staticClass:"d-flex mobile-hide"},[_vm._m(0),_c('div',{staticClass:"item"},[_c('date-picker',{model:{value:(_vm.since),callback:function ($$v) {_vm.since=$$v},expression:"since"}})],1),_vm._m(1),_c('div',{staticClass:"item"},[_c('date-picker',{model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}})],1)]),_c('div',{staticClass:"desktop-hide py-4"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-shrink-1 my-1"},[_c('v-btn',{on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('v-icon',[_vm._v("mdi-filter-outline")]),(!_vm.showFilters)?_c('span',[_vm._v("Zobrazit filtry")]):_c('span',[_vm._v("Skrýt filtry")])],1)],1)]),_c('div',{staticClass:"toggle mobile-filters",class:!_vm.showFilters ? 'hidden' : ''},[_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-12 pt-0"},[_c('date-picker',{attrs:{"label":"Od"},model:{value:(_vm.since),callback:function ($$v) {_vm.since=$$v},expression:"since"}})],1),_c('div',{staticClass:"col-12 pt-0"},[_c('date-picker',{attrs:{"label":"Do"},model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}})],1)])])])]),_c('v-container',{staticClass:"mt-3 mb-0 px-0 mobile-mt-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"crud-table-max-height",attrs:{"headers":_vm.headers,"items":_vm.items,"dark":"","loading":_vm.pending,"loading-text":"Načítám data...","no-data-text":"Nenalezeny žádné záznamy","server-items-length":_vm.totalRows,"page":_vm.pageIndex,"items-per-page":_vm.pageSize,"footer-props":{
                        itemsPerPageOptions: [ 50 ]
                    }},on:{"update:page":function($event){_vm.pageIndex=$event},"update:itemsPerPage":function($event){_vm.pageSize=$event},"update:items-per-page":function($event){_vm.pageSize=$event}},scopedSlots:_vm._u([{key:"item.fromDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.fromDate))+" ")]}},{key:"item.toDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.toDate))+" ")]}},{key:"item.rate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.rate)+" ")]}},{key:"item.valueFiat",fn:function(ref){
                    var item = ref.item;
return [(item.valueFiat)?_c('span',[_vm._v(_vm._s(item.valueFiat)+" USD")]):_c('span',[_vm._v("--")])]}},{key:"item.valueWallets",fn:function(ref){
                    var item = ref.item;
return [(item.valueWallets)?_c('span',[_vm._v(_vm._s(item.valueWallets)+" tok.")]):_c('span',[_vm._v("--")])]}},{key:"no-data",fn:function(){return [_vm._v(" Žádné záznamy ")]},proxy:true}],null,true)})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('label',[_vm._v("Od")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('label',[_vm._v("Do")])])}]

export { render, staticRenderFns }