import {Api} from '../Api'
import {getAccessToken} from 'axios-jwt'
import {authStore} from '@/libs/auth/+store/store';
import {AuthGetter} from '../auth/models/auth-state';
import {v4 as uuidv4} from 'uuid';
import ApiRequestIds from '@/libs/core/api-request-ids';

class SecurityModel {
    bearer = "";
}

const ApiService = new Api<SecurityModel>({
    baseUrl: "",
    securityWorker: (securityData: SecurityModel | null) => {
        const token = getAccessToken();
        const correlationId = uuidv4();
        ApiRequestIds.push(correlationId)
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'SignalClientId': (window as any).SignalClientId ?? null,
                'CorrelationId': correlationId,
            }
        }
    },
    baseApiParams: {
        secure: true
    }
});

(window as any).ApiService = ApiService;

export default ApiService;
