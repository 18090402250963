import { Vue } from 'vue-property-decorator';
import moment from 'moment';

Vue.filter('currency', function (value: number | string) {
    if (typeof value !== "number") {
        return value;
    }
    if (!isNaN(value) && Number(value) < 0.1 && Number(value) > -0.1) {
        value = 0;
    }
    const formatter = new Intl.NumberFormat('cs', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
});

Vue.filter('currencyToken', function (value: number | string, precision = 3) {
    if (typeof value !== "number") {
        return value;
    }
    if (!isNaN(value) && Number(value) < 0.1 && Number(value) > -0.1) {
        value = 0;
    }
    return Number(value).toFixed(precision);
});
