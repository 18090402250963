import {registerCrudStore} from '@/libs/core/+state/register-crud-store';
import {ProductActivityModel, ProductModel, ProductTypeModel, ProductNotificationRuleModel} from '@/libs/Api';
import {ProductsDataService} from '@/libs/products/products-data-service';
import {BusinessModule} from '@/libs/core/models/business-module';
import { ProductTypesDataService } from '../product-types-data-service';
import { ProductActivitiesDataService } from '../product-comments-data-service';
import { ProductNotificationRulesDataService } from '../product-notification-rules-data-service';

export const productsStore = registerCrudStore<ProductModel, ProductModel>(BusinessModule.Product, new ProductsDataService());
export const productTypesStore = registerCrudStore<ProductTypeModel, ProductTypeModel>(BusinessModule.ProductType, new ProductTypesDataService());
export const productActivitiesStore = registerCrudStore<ProductActivityModel, ProductActivityModel>(BusinessModule.ProductActivity, new ProductActivitiesDataService());
export const productNotificationRulesStore = registerCrudStore<ProductNotificationRuleModel, ProductNotificationRuleModel>(BusinessModule.ProductNotificationRule, new ProductNotificationRulesDataService());
