import {RouteConfig} from 'vue-router';

import SignIn from './pages/SignIn.vue';
import PasswordRecovery from './pages/PasswordRecovery.vue';

export default [
    {
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn
    },
    {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        component: PasswordRecovery
    }
] as RouteConfig[];
