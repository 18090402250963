






























































































































































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { AccountTypeEnum, ApplicationUserModel, PartyModel, PartyProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import moment from 'moment';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { min } from 'lodash';

@Component({
    components: {
        
    }
})
export default class NREIDialog extends Vue {

    isActive = true;
    dialog = false;
    innerValue: string | null = null;
    partyProduct: PartyProductModel | null = null;
    valid = false;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];
    headersInterestRates = [
        { text: 'Od', align: 'left', sortable: false, value: 'fromDate', type: "date", width: "20%" },
        { text: 'Do', align: 'left', sortable: false, value: 'toDate', type: "date", width: "20%" },
        { text: 'Měsíční výnos', align: 'left', sortable: false, value: 'interestRate', suffix: "%", type: "number" },
    ];
    invalidInterestRates = false;
    @VModel() item!: string;
    @Prop() clientId!: string;
    @Prop() productId!: string;
    @Prop() name!: string;
    @Prop() activator!: string;
    @Prop() readonly!: string;
    @Prop({ default: "green" }) activatorColor!: string;
    @Ref() form!: any;
    
    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
        else
            this.load();
    }

    @Watch("item")
    itemChanged (v: string) {
        this.invalidInterestRates = false;
        this.innerValue = v;
    }
    
    get isNewItem(): boolean {
        return this.innerValue == Guid.EmptyGuid();
    }

    get saving(): boolean {
        return partyProductsStore.useGetter(CrudGetter.Saving);
    }

    get canDelete(): boolean {
        return true;
    }

    get client() : PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.clientId!);
    }

    get canEditClientCode () {
        const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        return user.accountType == AccountTypeEnum.Admin;
    }

    mounted () {
        if (this.activator) {
            this.dialog = false;
            this.innerValue = this.item;
        }
        else {
            this.load();
        }
    }

    open () {
        this.dialog = true;
        this.load();
    }

    load() {
        if (this.item == null || this.item == Guid.EmptyGuid()) {
            this.partyProduct = {
                id: Guid.EmptyGuid(),
                name: this.name,
                partyProductName: null,
                partyProducCode: null,
                partyId: this.clientId,
                productId: this.productId,
                dateOfStart: moment().format("YYYY-MM-DDT00:00:00"),
                dateOfSignature: moment().format("YYYY-MM-DDT00:00:00"),
                dateOfEnding: null,
                insurancePrice: null,
                description: null,
                depositAmount: null,
                monthlyRevenue: null,
                revenueAmount: null,
                definedRevenue: null,
                currentCapitalAmount: null,
                pickAmount: null,
                isActive: true,
                bankCompanyId: null,
                investmentStrategyId: null,
                interestRate: null,
                isPeriodical: false,
                isOneTime: false,
                partyProductInterestRates: []
            } as PartyProductModel;
            this.isActive = true;
        }
        else {
            partyProductsStore.dispatch(CrudAction.Get, { id: this.item, key: this.item });
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetSuccess)) {
                    subscribtion();
                    self.partyProduct = mutation.payload.item;
                    self.isActive = self.partyProduct.isActive;
                }
            });
        }
    }

    save () {
        this.checkInterestRateItems();
        if (this.form.validate() && !this.invalidInterestRates) {
            this.partyProduct.isActive = this.isActive;
            if (this.item == null || this.innerValue == Guid.EmptyGuid()) {
            partyProductsStore.dispatch(CrudAction.Create, { item: this.partyProduct });
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.CreateSuccess)) {
                    subscribtion();
                    self.close();
                    this.$emit("changed");
	                partiesStore.dispatch(CrudAction.Get, { id: this.partyProduct.partyId, key: this.partyProduct.partyId });
                }
                else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.CreateFailed)) {
                    subscribtion();
                }
            });
            }
            else {
	            partyProductsStore.dispatch(CrudAction.Update, { item: this.partyProduct });
	            const self = this;
	            const subscribtion = this.$store.subscribe((mutation, _state) => {
	                if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
	                    subscribtion();
	                    self.close();
	                    this.$emit("changed");
		                partiesStore.dispatch(CrudAction.Get, { id: this.partyProduct.partyId, key: this.partyProduct.partyId });
	                }
	                else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.UpdateFailed)) {
	                    subscribtion();
	                    self.close();
	                }
	            });
            }
        }
    }

    close () {
        this.dialog = false;
    }

    deletePartyProduct () {
        partyProductsStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [ { field: "id", op: "eq", comparand: this.innerValue! } ]));
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
        if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.DeleteSuccess)) {
            subscribtion();
            self.close();
            this.$emit("removed");
	        partiesStore.dispatch(CrudAction.Get, { id: this.clientId, key: this.clientId });
        }
        else if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.DeleteFailed)) {
            subscribtion();
        }
        });
    }

    checkInterestRateItems() {
        let minDate = "2000-01-01T00:00:00";
        let maxDate = "2035-01-01T00:00:00";
        this.invalidInterestRates = false;
        this.partyProduct.partyProductInterestRates?.forEach(x => {
            if (!this.invalidInterestRates) {
                if (moment(x.fromDate ?? minDate).toDate() >= moment(x.toDate ?? maxDate).toDate()) {
                    this.invalidInterestRates = true;
                }
                else {
                    const otherInterestRates = this.partyProduct.partyProductInterestRates.except([x]).toArray();
                    if (otherInterestRates.any(o => moment(x.fromDate ?? minDate).toDate() < moment(o.toDate ?? maxDate).toDate() && moment(x.toDate ?? maxDate).toDate() > moment(o.fromDate ?? minDate).toDate())) {
                        this.invalidInterestRates = true;
                    }
                }
            }
        });
    }
    
}
