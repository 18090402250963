




































































































































































































































































































































import {Component, Prop, Ref} from 'vue-property-decorator';

import VueApexCharts from 'vue-apexcharts';
import {productActivitiesStore, productsStore} from '@/libs/products/+state/store';
import {CrudAction, CrudReponse, CrudResponseAction} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {partiesStore, partyProductsStore} from '@/libs/parties/+state/store';
import {Constants} from '@/libs/constants/constants';
import {
	ExchangeRateModel,
	ExchangeRateTypeEnum,
	PartyModel,
	PartyProductModel,
	ProductActivityModel,
	ProductModel,
	QueryOperator,
	SortOrder,
	TradeModel,
	TradeRequest,
	TransactionModel,
	WalletModel,
	WalletTransactionModel, WalletTransactionTypeEnum
} from '@/libs/Api';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import CltDialog from '@/libs/clients/components/CltDialog.vue';
import {exchangeRatesStore} from '@/libs/exchange-rates/+state/store';
import moment from 'moment';
import {walletsStore} from '@/libs/wallets/+state/store';
import Page from "@/Page.vue";
import {walletTransactionsStore} from "@/libs/wallet-transactions/+state/store";
import {tradesStore} from "@/libs/trades/+state/store";
import ApiService from "@/libs/core/api-service";
import ChartDialog from "@/libs/clients/components/Clt/ChartDialog.vue";

@Component({
    components: {
        VueApexCharts,
		CltDialog,
		ChartDialog
    }
})
export default class ClientProductCLT extends Page {
    
    headersTrades = [
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime", width: "16%" },
        { text: 'Typ', align: 'left', sortable: false, value: 'type', type: "text", width: "16%" },
        { text: 'Převod z', align: 'left', sortable: false, value: 'fromAmount', type: "text", width: "16%" },
        { text: 'Převod na', align: 'left', sortable: false, value: 'toAmount', type: "text", width: "16%" },
        { text: 'Kurz USD', align: 'left', sortable: false, value: 'exchangeRateUsd', type: "text", width: "16%" },
        { text: 'Kurz CLT', align: 'left', sortable: false, value: 'exchangeRateClt', type: "text", width: "16%" }
    ];
    headersTransactions = [
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime", width: "15%" },
        { text: 'Typ', align: 'left', sortable: false, value: 'type', type: "text", width: "20%" },
        { text: 'Částka', align: 'right', sortable: false, value: 'amount', type: "text", width: "20%" },
        { text: '', align: 'left', sortable: false, value: 'id', type: "text" }
    ];
	headersRewards = [
		{ text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime", width: "15%" },
		{ text: 'Typ', align: 'left', sortable: false, value: 'type', type: "text", width: "20%" },
		{ text: 'Počet tokenů', align: 'right', sortable: false, value: 'amount', type: "text", width: "20%" },
		{ text: '', align: 'left', sortable: false, value: 'id', type: "text" }
	];
    fundHeaders = [
        { text: 'Datum', align: 'left', sortable: false, value: 'fromDate', type: "date", width: "20%", format: "MMMM YYYY" },
        { text: 'Zhodnocení fondu aktuální', align: 'left', sortable: false, value: 'rate', suffix: "", type: "number", width: "20%" },
        { text: 'Zhodnocení fondu kumulativní', align: 'left', sortable: false, value: 'rateCumulative', suffix: "", type: "number" },
    ];
    items = [];
    product: ProductModel | null = null;
    partyProduct: PartyProductModel | null = null;
    year = moment().year();
    history: TransactionModel[] = [];
    wallets: WalletModel[] = [];
    minBasicChartDate = moment().add(-1, 'days').format("YYYY-MM-DD HH:mm:ss");
    maxBasicChartDate = moment().format("YYYY-MM-DD HH:mm:ss");
    buyDialog = false;
    sellDialog = false;
    buyDialogIsValid = false;
    sellDialogIsValid = false;
    saving = false;
    basicRules = [
      (v: any) => !!v || 'Toto pole je povinné.'
    ];
    buyAmountInputRules = [
      (v: any) => !!v || 'Toto pole je povinné.',
      (v: any) => Number(v) > 0 || 'Částka musí být větší než 0',
      (v: any) => {
        const wallets = walletsStore.useGetter(CrudGetter.Data) as WalletModel[];
        var czkWallet = wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId);
        if (Number(v) > czkWallet.currentValue) {
          return 'Zvolená částka je vyšší než zůstatek na peněžence.';
        }
        else {
          return true;
        }
      }
    ];
    sellAmountInputRules = [
      (v: any) => !!v || 'Toto pole je povinné.',
      (v: any) => Number(v) > 0 || 'Počet tokenů musí být větší než 0',
      (v: any) => {
        const wallets = walletsStore.useGetter(CrudGetter.Data) as WalletModel[];
        var cltWallet = wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCltId);
        if (Number(v) > cltWallet.currentValue) {
          return 'Zvolený počet tokenů je vyšší než zůstatek na peněžence.';
        }
        else {
          return true;
        }
      }
    ];
    amount = null;
    usdExchangeRate: ExchangeRateModel = null;
    cltExchangeRate: ExchangeRateModel = null;
	loadExchangeRateGrouppedInterval = null;
	detailedChartDialog = false
	chartSeries = [
		{
			name: "Vývoj kurzu",
			data: []
		}
	];
	isMobile = false;

    @Ref() buyForm!: any;
    @Ref() sellForm!: any;
    @Ref() apexChart!: any;

    get years () {
        let d = 2023;
        const out: Array<number> = [];
        while (d <= moment().year()) {
            out.push(d);
            d++;
        }
        return out;
    }

    get ProductActivities() : ProductActivityModel[] {
        return productActivitiesStore.useGetter(CrudGetter.Data) ?? [];
    }

    get transactions() : TransactionModel[] {
        return walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions');
    }

	get rewards() : TransactionModel[] {
		return walletTransactionsStore.useGetter(CrudGetter.Data, 'Rewards');
	}

	get pendingTransactions(): boolean {
        return walletTransactionsStore.useGetter(CrudGetter.Pending, 'CzkTransactions');
    }

    get trades() : TradeModel[] {
        return tradesStore.useGetter(CrudGetter.Data);
    }
    
    get pendingTrades(): boolean {
        return tradesStore.useGetter(CrudGetter.Pending);
    }

    get stateOfCapital(): number {
        const depositTransactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
        const deposit = depositTransactions.where(x => x.tradeId != null && x.amount > 0).select(x => x.amount).sum() ?? 0;
        const withdrawalTransactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
        const withdrawal = Math.abs(withdrawalTransactions.where(x => x.tradeId != null && x.amount < 0).select(x => x.amount).sum() ?? 0);
        return deposit - withdrawal;
    }

    get showChart(): boolean {
		return true;
		//let exchangeRates = exchangeRatesStore.useGetter(CrudGetter.Data, "ExchangeRateInLast24Hours") as ExchangeRateModel[];
        //return exchangeRates.length > 0;
    }

    get walletCZK(): WalletModel {
        return this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId);
    }

    get walletCLT(): WalletModel {
        return this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCltId);
    }

    get totalDeposit(): number {
        const transactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
        return transactions.where(x => x.tradeId == null && x.amount > 0).select(x => x.amount).sum() ?? 0;
    }

    get totalWithdrawal(): number {
        const transactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
        return Math.abs(transactions.where(x => x.tradeId == null && x.amount < 0).select(x => x.amount).sum() ?? 0);
    }

    get computedBoughtShares(): number | null {
        if (this.usdExchangeRate == null || this.cltExchangeRate == null) {
            return null;
        }
        return Number((this.amount / this.usdExchangeRate.rate / this.cltExchangeRate.rate).toFixed(3));
    }

    get computedSoldSharesInCzk(): number | null {
        if (this.usdExchangeRate == null || this.cltExchangeRate == null) {
            return null;
        }
        return Number((this.amount * this.cltExchangeRate.rate * this.usdExchangeRate.rate).toFixed(3));
    }

	get rateChangeIn24Hours() {
        let exchangeRates = exchangeRatesStore.useGetter(CrudGetter.Data, "ExchangeRateInLast24Hours") as ExchangeRateModel[];
		if (exchangeRates.count() > 2) {
            const first = exchangeRates.orderBy(x => moment(x.fromDate).toDate().getTime()).first();
			const last = exchangeRates.orderBy(x => moment(x.fromDate).toDate().getTime()).last();
			return (last.rate / first.rate - 1) * 100;
		}
		return null;
	}

    get currentInvestmentValue () {
        const exchangeRateCLT = (exchangeRatesStore.useGetter(CrudGetter.Data, 'LastCLTExchangeRate') as ExchangeRateModel[]).firstOrDefault();
        const exchangeRateUSD = (exchangeRatesStore.useGetter(CrudGetter.Data, 'LastUSDExchangeRate') as ExchangeRateModel[]).firstOrDefault();
        const walletCLT = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCltId);
        if (exchangeRateUSD != null && exchangeRateCLT != null && walletCLT != null) {
			return walletCLT.currentValue * exchangeRateUSD.rate * exchangeRateCLT.rate;
        }
        return null;
    }

	get totalValorization () {
		const exchangeRateCLT = (exchangeRatesStore.useGetter(CrudGetter.Data, 'LastCLTExchangeRate') as ExchangeRateModel[]).firstOrDefault();
		const exchangeRateUSD = (exchangeRatesStore.useGetter(CrudGetter.Data, 'LastUSDExchangeRate') as ExchangeRateModel[]).firstOrDefault();
		const walletCLT = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCltId);
		const walletCZK = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId)
		const transactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
		const depositTransactions = walletTransactionsStore.useGetter(CrudGetter.Data, 'CzkTransactions') as WalletTransactionModel[];
		if (exchangeRateUSD != null && exchangeRateCLT != null && walletCLT != null && walletCZK && depositTransactions) {
			const currentValue = walletCLT.currentValue * exchangeRateUSD.rate * exchangeRateCLT.rate;
			const withdrawals = Math.abs(transactions.where(x => x.tradeId == null && x.amount < 0).select(x => x.amount).sum() ?? 0);
			const deposit = depositTransactions.where(x => x.tradeId == null && x.amount > 0).select(x => x.amount).sum() ?? 0;
            if (deposit - walletCZK.currentValue != 0) {
                return (((currentValue + withdrawals) / (deposit - walletCZK.currentValue)) - 1) * 100;
            }
		}
		return null;
	}

	getChartHeaderHeight(): string {
		if (window.outerWidth < 645) {
			return "120px";
		}
		return "50px";
	}

    getChartSeries() {
        let exchangeRates = exchangeRatesStore.useGetter(CrudGetter.Data, "ExchangeRateInLast24Hours") as ExchangeRateModel[];
        exchangeRates = exchangeRates.orderBy(x => moment(x.fromDate).toDate().getTime()).toArray();
		return [
			{
				name: "Vývoj kurzu",
				data: exchangeRates.select(x => [moment(x.fromDate).toDate().getTime(), x.rate]).orderBy(x => x[0]).toArray()
			}
		];
    }

    get client () : PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.id);
    }

    @Prop() id!: string;
    @Prop() partyProductId!: string;

    mounted(): void {
        this.load();
        this.loadExchangeRatesInLast24Hours();
		this.loadExchangeRateGrouppedInterval = setInterval(() => {
			this.minBasicChartDate = moment().add(-1, 'days').format("YYYY-MM-DD HH:mm:ss");
			this.maxBasicChartDate = moment().format("YYYY-MM-DD HH:mm:ss");
			this.loadExchangeRatesInLast24Hours();
			this.loadLastExchangeRates();
		}, 60000);
		this.resizeHandler();
    }

	created() {
		window.addEventListener("resize", this.resizeHandler);
	}

	destroyed(): void {
		clearInterval(this.loadExchangeRateGrouppedInterval);
		window.removeEventListener("resize", this.resizeHandler);
	}

	resizeHandler() {
		this.isMobile = window.innerWidth <= 991;
	}

    load(): void {
        this.loadProduct();
        this.loadProductActivities();
        this.loadTransactions();
	    this.loadRewards();
        this.loadTrades();
        this.loadLastExchangeRates();
    }

    loadProduct(): void {
        productsStore.dispatch(CrudAction.Get, { id: Constants.CltProductId, key: Constants.CltProductId });
        const self = this;
        const subscriber = this.$store.subscribe((mutation) => {
            if(mutation.type === productsStore.getActionName(CrudResponseAction.GetSuccess)) {
                subscriber();
                self.loadPartyProduct();
            }
        });
    }

    loadPartyProduct(): void {
        partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
            { field: "id", op: "eq", comparand: this.partyProductId }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation) => {
            if(mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.makeProducts();
                if (this.partyProduct != null) {
                    self.loadWallets();
                }
            }
        });
    }

    loadWallets(): void {
        walletsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
            { field: "partyProductId", op: "eq", comparand: this.partyProductId }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === walletsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                this.wallets = walletsStore.useGetter(CrudGetter.Data);
                subscriber();
                self.loadTransactions();
            }
        });
    }

    loadTransactions(): void {
        walletTransactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<WalletTransactionModel>([
            { field: "time", order: "desc", index: 0 }
        ], [
            { field: "wallet.partyProductId", op: "eq", comparand: this.partyProductId },
            { field: "wallet.currencyId", op: "eq", comparand: Constants.CurrencyCzkId }
        ], undefined, false, 'CzkTransactions'));
    }

	loadRewards(): void {
		walletTransactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<WalletTransactionModel>([
			{ field: "time", order: "desc", index: 0 }
		], [
			{ field: "wallet.partyProductId", op: "eq", comparand: this.partyProductId },
			{ field: "wallet.currencyId", op: "eq", comparand: Constants.CurrencyCltId },
			{ field: "transactionType", op: "eq", comparand: WalletTransactionTypeEnum.Reward }
		], undefined, false, 'Rewards'));
	}

	loadTrades(): void {
        tradesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TradeModel>([
            { field: "time", order: "desc", index: 0 }
        ], [
            { field: "partyProductId", op: "eq", comparand: this.partyProductId }
        ]));
    }

    makeProducts(): void {
        this.product = productsStore.useGetter(CrudGetter.Detail, Constants.CltProductId);
        const partyProducts = partyProductsStore.useGetter(CrudGetter.Data);
        if (partyProducts.length > 0) {
            this.partyProduct = partyProducts[0];
        }
    }

    loadProductActivities(): void {
        productActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductActivityModel>([
            { field: "createdAt", order: "desc", index: 0 }
        ], [
            { field: "productId", op: "eq", comparand: Constants.CltProductId }
        ]))
    }

    changed(): void {
        this.load();
    }
    
    removed(): void {
        this.$router.push({ name: "ClientProducts", params: { id: this.id } })
    }
    
    get chartOptions() {
		let exchangeRates = exchangeRatesStore.useGetter(CrudGetter.Data, "ExchangeRateInLast24Hours") as ExchangeRateModel[];
		const minValue = exchangeRates?.length > 0 ? exchangeRates.select(x => x.rate)?.min() : 0;
		const maxValue = exchangeRates?.length > 0 ? exchangeRates.select(x => x.rate)?.max() : 0;
		const minDate = exchangeRates?.length > 0 ? exchangeRates.select(x => moment(x.fromDate).toDate().getTime())?.min() : new Date().getTime();
		const maxDate = exchangeRates?.length > 0 ? exchangeRates.select(x => moment(x.fromDate).toDate().getTime())?.max() : new Date().getTime();
		console.log("chartOptions", minValue, maxValue);
        return {
			chart: {
				animations: {
					enabled: true,
					dynamicAnimation: {
						enabled: false
					}
				},
				height: 600,
				type: 'line',
				background: "transparent",
				toolbar: {
					show: false
				},
				zoom: {
					enabled: false
				}
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				type: 'datetime',
				tickAmount: 6,
				labels: {
					formatter: (value: Date) => {
						return moment(value).format("DD.MM.YYYY HH:mm");
					}
				},
				min: minDate,
				max: maxDate
			},
			yaxis: [
				{
					labels: {
						formatter: function (value: number) {
							return `${Number(value).toFixed(2)} USD`;
						},
						minWidth: 80,
						maxWidth: 80
					},
					forceNiceScale: false,
					min: Math.floor(minValue * 0.99 * 100) / 100,
					max: Math.ceil(maxValue * 1.01 * 100) / 100
				}
			],
			tooltip: {
				y: {
					formatter: function (value: number) {
						return `1 token = ${Number(value).toFixed(3)} USD`;
					}
				}
			},
			theme: {
				mode: "dark"
			},
			dataLabels: {
				enabled: false
			}
		};
    }

	getUsdRateOfTrade(item: TradeModel): number | null {
        return item.tradeConversions.firstOrDefault(x => x.exchangeRate.type == ExchangeRateTypeEnum.Currency)?.exchangeRate.rate ?? null;
    }
    
    getCltRateOfTrade(item: TradeModel): number | null {
        return item.tradeConversions.firstOrDefault(x => x.exchangeRate.type == ExchangeRateTypeEnum.Product)?.exchangeRate.rate ?? null;
    }

    loadExchangeRatesInLast24Hours(): void {
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([], [
			{ field: 'productId', op: QueryOperator.Eq, comparand: Constants.CltProductId },
			{ field: 'isPublic', op: QueryOperator.Eq, comparand: true },
			{ field: 'fromDate', op: QueryOperator.Gte, comparand: moment(this.minBasicChartDate).add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss") }
		], undefined, false, "ExchangeRateInLast24Hours"));
		this.subscribe(exchangeRatesStore, CrudReponse.GetAll, "ExchangeRateInLast24Hours").then(() => {
			const lastDate = this.chartSeries[0].data.select(x => x[0]).orderBy(x => x[0]).lastOrDefault() ?? 0;
			const newData = this.getChartSeries()[0].data.where(x => x[0] > lastDate).orderBy(x => x[0]).toArray();
			newData.forEach(x => {
				this.chartSeries[0].data.push(x);
			})
			//this.apexChart.updateSeries(this.chartSeries, false);
		})
    }

    loadLastExchangeRates(): void {
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
            { field: 'toDate', index: 1, order: 'desc' }
        ], [
            { field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyUsdId },
            { field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
            { field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Currency },
            { field: 'isPublic', op: QueryOperator.Eq, comparand: true }
        ], { pageIndex: 1, pageSize: 1 }, false, "LastUSDExchangeRate"));
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
            { field: 'toDate', index: 1, order: 'desc' }
        ], [
            { field: 'productId', op: QueryOperator.Eq, comparand: Constants.CltProductId },
            { field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Product },
            { field: 'isPublic', op: QueryOperator.Eq, comparand: true }
        ], { pageIndex: 1, pageSize: 1 }, false, "LastCLTExchangeRate"));
    }

    buyClick(): void {
		this.amount = 0;
		this.buyDialog = true;
		this.getCurrentUsdRate();
		this.getCurrentCltRate();
    }

    sellClick(): void {
		this.amount = 0;
		this.sellDialog = true;
		this.getCurrentUsdRate();
		this.getCurrentCltRate();
    }

    getCurrentUsdRate(): Promise<number | null> {
        return new Promise<number | null>((resolve, reject) => {
          ApiService.api.getExchangeRateBatch(1, 1, {
            predicates: [
              { field: 'fromCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyUsdId },
              { field: 'toCurrencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
              { field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Currency },
              { field: 'isPublic', op: QueryOperator.Eq, comparand: true }
            ],
            orderby: [
              { field: "toDate", index: 1, order: SortOrder.Desc }
            ]
          }).then((e: any) => {
            this.usdExchangeRate = e.data.returnValue.items.firstOrDefault();
            resolve(this.usdExchangeRate.rate);
          }).catch((e: any) => reject(e));
        });
      }

    getCurrentCltRate(): Promise<number | null> {
      return new Promise<number | null>((resolve, reject) => {
        ApiService.api.getExchangeRateBatch(1, 1, {
          predicates: [
            { field: 'productId', op: QueryOperator.Eq, comparand: Constants.CltProductId },
            { field: 'type', op: QueryOperator.Eq, comparand: ExchangeRateTypeEnum.Product },
            { field: 'isPublic', op: QueryOperator.Eq, comparand: true }
          ],
          orderby: [
            { field: "toDate", index: 1, order: SortOrder.Desc }
          ]
        }).then((e: any) => {
          this.cltExchangeRate = e.data.returnValue.items.firstOrDefault();
          resolve(this.cltExchangeRate.rate);
        }).catch((e: any) => reject(e));
      });
    }

    saveItem(): void {
        if (this.buyDialog) {
            if (this.buyForm.validate()) {
                this.saving = true;
                ApiService.api.insertTradeByConsultant({
                    partyProductId: this.partyProductId,
                    fromCurrencyId: Constants.CurrencyCzkId,
                    toCurrencyId: Constants.CurrencyCltId,
                    amount: this.amount
                } as TradeRequest).then(() => {
                    this.saving = false;
                    this.buyDialog = false;
                    this.load();
                }).catch(() => {
                    this.saving = false;
                });
            }
        }
        if (this.sellDialog) {
            if (this.sellForm.validate()) {
                this.saving = true;
                ApiService.api.insertTradeByConsultant({
                    partyProductId: this.partyProductId,
                    fromCurrencyId: Constants.CurrencyCltId,
                    toCurrencyId: Constants.CurrencyCzkId,
                    amount: this.amount
                } as TradeRequest).then(() => {
                    this.saving = false;
                    this.sellDialog = false;
                    this.load();
                }).catch(() => {
                    this.saving = false;
                });
            }
        }
    }

	showDetailedChartClick() {
		this.detailedChartDialog = true;
	}

}
