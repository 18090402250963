
























import {Component, Vue, Prop, Watch, Emit, VModel, PropSync, Ref} from 'vue-property-decorator';

@Component({})
export default class Autocomplete extends Vue {

    @VModel() innerValue: any;
    
    @Prop() items!: Array<any>;
    @Prop() label!: string;
    @Prop() loading!: boolean;
    @Prop() itemText!: string;
    @Prop() itemValue!: string;
    @PropSync('searchInput') innerSearchInput!: string;
    @Prop({ default: true }) enabled!: boolean;
    @Prop({ default: true }) clearable!: boolean;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;

    @Ref() autocomplete!: any;

    blur () {
        this.autocomplete.blur();
    }

}
