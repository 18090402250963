































































import {Component, Prop, Vue} from 'vue-property-decorator';

import { productsStore, productTypesStore } from '@/libs/products/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { ProductModel, ProductTypeModel } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { ProductTypeId } from '@/libs/products/product-types';
import ProductCard from '@/libs/system-settings/components/ProductCard.vue';
import ProductDialog from '@/libs/system-settings/components/ProductDialog.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Constants } from '@/libs/constants/constants';

@Component({
    components: { ProductCard, ProductDialog }
})
export default class SystemSettingsProducts extends Vue {

    dialogItem: ProductModel | null = null;
    
    nfgProductType: ProductTypeModel | undefined = undefined;
    insuranceProductType: ProductTypeModel | undefined = undefined;
    loanProductType: ProductTypeModel | undefined = undefined;
    investmentProductType: ProductTypeModel | undefined = undefined;

    nfgProducts: ProductModel[] = [];
    insuranceProducts: ProductModel[] = [];
    loanProducts: ProductModel[] = [];
    investmentProducts: ProductModel[] = [];
    
    noifProduct: ProductModel | null = null;
	reOpportunitiesProduct: ProductModel | null = null;
    wealthProduct: ProductModel | null = null;
    cltProduct: ProductModel | null = null;
    cftProduct: ProductModel | null = null;
    nreiProduct: ProductModel | null = null;
    ncifProduct: ProductModel | null = null;
    creditProduct: ProductModel | null = null;
	nremProduct: ProductModel | null = null;

    get pending() : boolean {
        return productsStore.useGetter(CrudGetter.Pending);
    }
  
    mounted () {
        const productTypes: ProductTypeModel[] = productTypesStore.useGetter(CrudGetter.Data);
        this.nfgProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.NfgProductTypeId; })
        this.insuranceProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.InsuranceProductTypeId; });
        this.loanProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.LoanProductTypeId; });
        this.investmentProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.InvesmentProductTypeId; });
        this.loadProducts();
    }

    loadProducts () {
        productsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductModel>([ { field: "name", index: 1, order: "asc" } ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === productsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.makeProducts();
            }
        });
    }

    makeProducts () {
        const products = productsStore.useGetter(CrudGetter.Data);
        this.insuranceProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.insuranceProductType?.id; });
        this.loanProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.loanProductType?.id; });
        this.investmentProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.investmentProductType?.id; });
        this.noifProduct = products.find((e: ProductModel) => { return e.id == Constants.NoifProductId; });
        this.reOpportunitiesProduct = products.find((e: ProductModel) => { return e.id == Constants.ReOpportunitiesId; });
        this.wealthProduct = products.find((e: ProductModel) => { return e.id == Constants.WealthProductId; });
        this.cltProduct = products.find((e: ProductModel) => { return e.id == Constants.CltProductId; });
        this.cftProduct = products.find((e: ProductModel) => { return e.id == Constants.CftProductId; });
        this.nreiProduct = products.find((e: ProductModel) => { return e.id == Constants.NreiProductId; });
        this.ncifProduct = products.find((e: ProductModel) => { return e.id == Constants.NcifProductId; });
        this.creditProduct = products.find((e: ProductModel) => { return e.id == Constants.CreditProductId; });
        this.nremProduct = products.find((e: ProductModel) => { return e.id == Constants.NremProductId; });
    }
  
    createProduct () {
        this.dialogItem = {
            id: Guid.EmptyGuid(),
            name: "",
            productTypeId: undefined,
            productType: undefined
        };
    }

    openProduct (v: ProductModel) {
        this.dialogItem = v;
    }

}
