












































































































































































import {
	GroupByProjectionDefinition,
	ProjectionAggregationEnum,
	QueryOperator,
	TransactionModel,
	WalletModel,
	WalletTransactionModel,
	WalletTransactionTypeEnum
} from '@/libs/Api';
import {Component, Ref, Watch} from 'vue-property-decorator';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload, createGrouppedQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';

import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Constants} from '@/libs/constants/constants';
import {walletTransactionsStore} from '@/libs/wallet-transactions/+state/store';
import Page from '@/Page.vue';
import {walletsStore} from "@/libs/wallets/+state/store";
import ApiService from '@/libs/core/api-service';
import {CrudDataStats} from "@/libs/core/+state/models/crud-data-stats";

@Component({
    components: {
        CrudTable
    }
})
export default class ProductNremTransactions extends Page {

    headers = [
        { text: 'Klient', align: 'left', sortable: false, value: 'wallet.partyProduct.party.displayName', type: "text", width: "20%" },
        { text: 'Typ', align: 'left', sortable: false, value: 'type', type: "text", width: "10%" },
        { text: 'Částka', align: 'left', sortable: false, value: 'amount', suffix: "Kč", type: "money", width: "10%" },
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime" },
	    { text: 'Zadáno uživatelem', align: 'left', sortable: false, value: 'createdBy.displayName', type: "datetime" }
    ];

    clientId: string | null = null;
    since: string | null = null;
    until: string | null = null;
    showFilters = false;
    productId = Constants.NremProductId;
    isDeposit = false;
    walletCannotBeFound = false;
    partyProductId = null;
    amount = null;
    onlyDeposit = 0;
    wallets: WalletModel[];
    partyWalletCzk: WalletModel = null;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];

	pageIndex = 1;
	pageSize = 50;


	@Ref() crudTable!: CrudTable;

    @Watch("clientId")
    @Watch("since")
    @Watch("until")
    @Watch("onlyDeposit")
    filtersChanged(): void {
        this.loadData();
    }

	@Watch("pageIndex")
	@Watch("pageSize")
	paginationChanged() {
		this.loadData();
	}

    @Watch("partyProductId")
    partyProductIdChanged(v: string | null, p: string | null) {
        if (v != null && v != p) {
            this.getWallets();
        }
    }

    get items(): any[] {
		const items = [...walletTransactionsStore.useGetter(CrudGetter.Data)];
        return items.select((x) => {
			const e = {...x};
			e.type = e.amount > 0 ? "Vklad" : "Výběr";
			return e;
		}).toArray();
    }

    get walletSum(): number {
        const transactions = walletTransactionsStore.useGetter(CrudGetter.Data) as WalletTransactionModel[];
        return transactions.select(x => x.amount).sum();
    }

    get grouppedData(): any {
        const data = walletTransactionsStore.useGetter(CrudGetter.GrouppedData, 'TotalAmount');
        return data && data?.length > 0 ? data[0] : null;
    }

    get grouppedDataDeposit(): any {
        const data = walletTransactionsStore.useGetter(CrudGetter.GrouppedData, 'AmountDeposit');
        return data && data?.length > 0 ? data[0] : null;
    }

    get grouppedDataWithdrawal(): any {
        const data = walletTransactionsStore.useGetter(CrudGetter.GrouppedData, 'AmountWithdrawal');
        return data && data?.length > 0 ? data[0] : null;
    }

	get totalRows (): number {
		return ({...walletTransactionsStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
	}

	@Watch("isSaving")
	@Watch("isDeleting")
	isSavingChanged(current: boolean, prev: boolean) {
		if (!current && prev) {
			this.crudTable.close();
			this.loadGrouppedData();
		}
	}

	get isDeleting(): boolean {
		return walletTransactionsStore.useGetter(CrudGetter.Deleting);
	}

	get isSaving(): boolean {
		return walletTransactionsStore.useGetter(CrudGetter.Saving);
	}

	get isMobile(): boolean {
		return window.outerWidth <= 1140;
	}

    mounted(): void {
        this.loadData();
    }

    loadData(): void {
        let filters: CrudQueryPredicate[] = [
            { field: "wallet.partyProduct.productId", op: "eq", comparand: Constants.NremProductId },
            { field: "wallet.currencyId", op: "eq", comparand: Constants.CurrencyCzkId },
            { field: "tradeId", op: "eq", comparand: null }
        ];
        filters = filters.concat(this.getFilters());
        walletTransactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<WalletTransactionModel>([ { field: "time", index: 1, order: "desc" } ], filters, { pageIndex: this.pageIndex, pageSize: this.pageSize }));
        this.loadGrouppedData();
    }

    getFilters(): CrudQueryPredicate[] {
      let filters: CrudQueryPredicate[] = [];
      if (this.clientId) {
        filters.push({ field: "wallet.partyProduct.partyId", op: "eq", comparand: this.clientId });
      }
      if (this.since) {
        filters.push({ field: "time", op: "gte", comparand: this.since });
      }
      if (this.until) {
        filters.push({ field: "time", op: "lte", comparand: this.until });
      }
      if (this.onlyDeposit > 0) {
        filters.push({ field: "amount", op: this.onlyDeposit == 1 ? 'gt' : 'lt', comparand: 0 });
      }
      return filters;
    }
    
    addDepositClick(): void {
        this.partyWalletCzk = null;
        this.isDeposit = true;
        this.crudTable.addItemClick();
    }
    
    addWithdrawalClick(): void {
        this.partyWalletCzk = null;
        this.isDeposit = false;
        this.crudTable.addItemClick();
    }

    saveItem(e: WalletTransactionModel): void {
        this.walletCannotBeFound = false;
        walletsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<WalletModel>(undefined, [
            { field: 'partyProductId', op: 'eq', comparand: this.partyProductId },
            { field: 'currencyId', op: 'eq', comparand: Constants.CurrencyCzkId },
        ], { pageSize: 1, pageIndex: 1 }, false, 'LoadWallet'));
        this.subscribe(walletsStore, CrudReponse.GetAll, 'LoadWallet').then((wallets: WalletModel[]) => {
            if (wallets.any()) {
                e.amount = Math.abs(this.amount) * (this.isDeposit ? 1 : -1);
                if (e.id == Guid.EmptyGuid() || e.id == null) {
                    e.walletId = wallets.first().id;
                    walletTransactionsStore.dispatch(CrudAction.Create, { item: e });
                }
                else {
                    walletTransactionsStore.dispatch(CrudAction.Update, { item: e });
                }
            }
            else {
                this.walletCannotBeFound = true;
            }
        });
    }

    deleteItem(e: WalletTransactionModel): void {
        walletTransactionsStore.dispatch(CrudAction.Delete, createCrudQueryPayload<TransactionModel>(undefined, [ { field: "id", op: "eq", comparand: e.id! } ]));
    }
    
    beforeAdd(e: WalletTransactionModel): void {
		e.transactionType = WalletTransactionTypeEnum.Standard;
        this.amount = 0;
        this.partyProductId = null;
    }

    beforeEdit(e: WalletTransactionModel): void {
        this.isDeposit = e.amount >= 0;
        this.amount = Math.abs(e.amount);
        this.partyProductId = e.wallet.partyProductId;
    }

    getWallets(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            ApiService.api.getWalletBatch(1, 99, {
                predicates: [
                    { field: 'partyProductId', op: QueryOperator.Eq, comparand: this.partyProductId }
                ]
            }).then((e: any) => {
                this.wallets = e.data.returnValue.items;
                this.partyWalletCzk = this.wallets.firstOrDefault(x => x.currencyId == Constants.CurrencyCzkId && x.partyProductId == this.partyProductId);
                resolve(this.wallets);
            }).catch((e: any) => reject(e));
        });
    }
 
    loadGrouppedData() {
        walletTransactionsStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<WalletTransactionModel>([
            { field: 'wallet.currencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
			{ field: 'wallet.partyProduct.productId', op: QueryOperator.Eq, comparand: Constants.NremProductId }
        ].concat(this.getFilters() as any), [], [
            {
                field: "amount",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "amount"
            } as GroupByProjectionDefinition
        ], 'TotalAmount'));
        walletTransactionsStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<WalletTransactionModel>([
            { field: 'wallet.currencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
			{ field: 'wallet.partyProduct.productId', op: QueryOperator.Eq, comparand: Constants.NremProductId },
            { field: 'tradeId', op: QueryOperator.Eq, comparand: null },
            { field: 'amount', op: QueryOperator.Gt, comparand: 0 }
        ].concat(this.getFilters() as any), [], [
            {
                field: "amount",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "amount"
            } as GroupByProjectionDefinition
        ], 'AmountDeposit'));
        walletTransactionsStore.dispatch(CrudAction.GetGroupped, createGrouppedQueryPayload<WalletTransactionModel>([
            { field: 'wallet.currencyId', op: QueryOperator.Eq, comparand: Constants.CurrencyCzkId },
			{ field: 'wallet.partyProduct.productId', op: QueryOperator.Eq, comparand: Constants.NremProductId },
            { field: 'tradeId', op: QueryOperator.Eq, comparand: null },
            { field: 'amount', op: QueryOperator.Lt, comparand: 0 }
        ].concat(this.getFilters() as any), [], [
            {
                field: "amount",
                aggregation: ProjectionAggregationEnum.Sum,
                outputField: "amount"
            } as GroupByProjectionDefinition
        ], 'AmountWithdrawal'));
    }
       
}
