import { Vue } from 'vue-property-decorator';
import moment from 'moment';

Vue.filter('formatDate', (value : string) => {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm')
    }
})

Vue.filter('momentFormatter', (value : string, format: string) => {
    if (value) {
        moment.locale('cs');
        return moment(String(value)).format(format)
    }
})

Vue.filter('formatSimpleDate', (value : string) => {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
})

Vue.filter('formatYear', (value : string) => {
    if (value) {
        return moment(String(value)).format('YYYY')
    }
})

Vue.filter('formatDateHorizon', (value : string, baseValue: string) => {
    if (value && baseValue) {
        const dateSince = moment(String(baseValue));
        const dateUntil = moment(String(value));
        const diff = dateSince.diff(dateUntil) / 1000;
        const daySecs = 86400;
        const monthSecs = daySecs * 30.4;
        const yearSecs = daySecs * 365;
        const years = Math.floor(diff / yearSecs);
        const months = Math.ceil((diff - Math.floor(diff / yearSecs) * yearSecs) / monthSecs);
        let finalString = "";
        if (years > 0) {
            finalString = years.toString();
            if (years == 1)
                finalString += " rok";
            else if (years > 1 && years < 5)
                finalString += " roky";
            else
                finalString += " let";
        }
        if (months > 0) {
            if (finalString.length > 0)
                finalString += " ";
            finalString += months.toString();
            if (months == 1)
                finalString += " měsíc";
            else if (months > 1 && months < 5)
                finalString += " měsíce";
            else
                finalString += " měsíců";
        }
        return finalString;
    }
})
