import {RouteConfig} from 'vue-router';

import Cloud from './pages/Cloud.vue';

export default [
    {
        path: '/cloud',
        name: 'Cloud',
        component: Cloud
    }
] as RouteConfig[];
