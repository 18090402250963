























































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import moment from 'moment';
import { ApplicationUserModel, PartyModel, PartyProductModel, PartyProductQuotationModel, ProductModel } from '@/libs/Api';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { partiesStore, partyProductQuotationsStore } from '@/libs/parties/+state/store';

@Component
export default class RequestForQuotationDialog extends Vue {

  show = false;
  valid = false;
  thanks = false;
  basicRules = [
    (v: any) => !!v || 'Toto pole je povinné.'
  ];

  accountPartyId = (applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel).partyId!;

  get client(): PartyModel {
      return partiesStore.useGetter(CrudGetter.Detail, this.accountPartyId);
  }

  get saving(): boolean {
    return partyProductQuotationsStore.useGetter(CrudGetter.Saving);
  }

  @Watch("show")
  showChanged(v: boolean) {
    if (!v)
      this.close();
  }

  @Prop() partyProduct!: PartyProductModel;
  @Ref() form!: any;

  get today(): string {
    return moment().format("DD.MM.YYYY");
  }

  open () {
      this.show = true;
  }

  send () {
    if (this.form.validate()) {
      const quotation = {
        id: this.partyProduct.productId!
      } as PartyProductQuotationModel;
      partyProductQuotationsStore.dispatch(CrudAction.Create, { item: quotation });
      const self = this;
      const subscribtion = this.$store.subscribe((mutation, _state) => {
          if (mutation.type === partyProductQuotationsStore.getActionName(CrudResponseAction.CreateSuccess)) {
              subscribtion();
              self.thanks = true;
          }
          else if (mutation.type === partyProductQuotationsStore.getActionName(CrudResponseAction.CreateFailed)) {
              subscribtion();
          }
      });
    }
  }

  close () {
    this.show = false;
  }
    
}
