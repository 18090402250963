













































import { PartyProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import {Component, Vue, Prop, Ref} from 'vue-property-decorator';
import NREIDialog from '@/libs/clients/components/NREIDialog.vue';
import moment from 'moment';

@Component({
    components: {
        NREIDialog
    }
})
export default class NOIFCard extends Vue {

    @Prop() partyProduct!: PartyProductModel;
    @Prop() clientId!: string;
    @Ref() dialog!: NREIDialog;
    @Prop({ default: false }) accountSection!: boolean;
    
    newId: string | null = Guid.EmptyGuid();

    get interestRate(): number {
        let minDate = "2000-01-01T00:00:00";
        let maxDate = "2035-01-01T00:00:00";
        return this.partyProduct.partyProductInterestRates.where(x => moment(x.fromDate ?? minDate).startOf("day").toDate() <= new Date() && moment(x.toDate ?? maxDate).endOf("day").toDate() >= new Date()).orderByDescending(x => moment(x.toDate ?? maxDate).toDate().getTime()).firstOrDefault()?.interestRate ?? 0;
    }
    
    openPartyProduct() {
        this.dialog.open();
    }

}

