



















































































































































import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import { PartyModel, PartyProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import LoanTypeSelect from '@/libs/common/components-smart/LoanTypeSelect.vue';
import moment from 'moment';

@Component({
    components: {
        LoanTypeSelect
    }
})
export default class ClientLoanDialog extends Vue {

  dialog = false;
  innerValue: string | null = null;
  partyProduct: PartyProductModel | null = null;
  valid = true;
  basicRules = [
    (v: any) => !!v || 'Toto pole je povinné.'
  ];
  @VModel() item!: string;
  @Prop() clientId!: string;
  @Prop() productId!: string;
  @Prop() name!: string;
  @Prop() activator!: string;
  @Ref() form!: any;

  @Watch("dialog")
  dialogChanged(v: boolean) {
    if (!v)
        this.close();
    else
        this.load();
  }

  get client() : PartyModel {
      return partiesStore.useGetter(CrudGetter.Detail, this.clientId!);
  }

  @Watch("item")
  itemChanged (v: string) {
    this.innerValue = v;
  }

  mounted () {
    if (this.activator) {
        this.dialog = false;
        this.innerValue = this.item;
    }
    else {
        this.load();
    }
  }

  open () {
      this.dialog = true;
      this.load();
  }

  load() {
    partyProductsStore.dispatch(CrudAction.Get, { id: this.item, key: this.item });
    const self = this;
    const subscribtion = this.$store.subscribe((mutation, _state) => {
        if (mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetSuccess)) {
            subscribtion();
            self.partyProduct = mutation.payload.item;
        }
    });
  }

  close () {
    this.dialog = false;
  }

}
