


















































































import {ExchangeRateModel, QueryOperator, TransactionModel} from '@/libs/Api';
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {CrudAction} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';

import {exchangeRatesStore} from '@/libs/exchange-rates/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Constants} from '@/libs/constants/constants';
import moment from 'moment';
import {CrudDataStats} from "@/libs/core/+state/models/crud-data-stats";

@Component({
    computed: {
        moment() {
            return moment
        }
    },
    components: {
        CrudTable
    }
})
export default class ProductCltExchangeRates extends Vue {

    headers = [
        { text: 'Od', align: 'left', sortable: false, value: 'fromDate', type: "datetime", width: '20%' },
        { text: 'Do', align: 'left', sortable: false, value: 'toDate', type: "datetime", width: '20%' },
        { text: 'Kurz', align: 'left', sortable: false, value: 'rate', suffix: "", type: "number" },
        { text: 'Hodnota CoinTracking', align: 'left', sortable: false, value: 'valueFiat', suffix: "", type: "number" },
        { text: 'Počet tokenů v oběhu', align: 'left', sortable: false, value: 'valueWallets', suffix: "", type: "number" }
    ];
    pageIndex = 1;
    pageSize = 50;
	since: string | null = null;
	until: string | null = null;
	showFilters = false;

    @Ref() crudTable!: CrudTable;

    get items(): ExchangeRateModel[] {
        return exchangeRatesStore.useGetter(CrudGetter.Data, Constants.CltProductId) as ExchangeRateModel[];
    }
    
    get pending(): boolean {
        return exchangeRatesStore.useGetter(CrudGetter.Pending, Constants.CltProductId);
    }
    
    get totalRows (): number {
        return ({...exchangeRatesStore.useGetter(CrudGetter.DataStats, Constants.CltProductId)} as CrudDataStats)?.rowCount;
    }
    
    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged(): void {
        this.loadData();
    }

	@Watch("since")
	@Watch("until")
	filtersChanged(): void {
		this.pageIndex = 1;
		this.loadData();
	}

    mounted(): void {
        this.loadData();
    }

    loadData(): void {
		const predicates = [
			{ field: "productId", op: "eq", comparand: Constants.CltProductId },
			{ field: 'isPublic', op: QueryOperator.Eq, comparand: true }
		];
		if (this.since) {
			predicates.push({ field: 'fromDate', op: QueryOperator.Gte, comparand: moment(this.since).startOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss") });
		}
		if (this.until) {
			predicates.push({ field: 'fromDate', op: QueryOperator.Lte, comparand: moment(this.until).endOf("day").add(-2, "hour").format("YYYY-MM-DDTHH:mm:ss") });
		}
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([ { field: "fromDate", index: 1, order: "desc" } ], predicates, { pageIndex: this.pageIndex, pageSize: this.pageSize }, false, Constants.CltProductId));
    }

    addExchangeRate(): void {
        this.crudTable.addItemClick();
    }

    saveItem(e: ExchangeRateModel): void {
        const date = e.fromDate;
        e.fromDate = moment(date).startOf("month").format("YYYY-MM-DDT00:00:00");
        e.toDate = moment(date).endOf("month").format("YYYY-MM-DDT00:00:00");
        if (e.id == Guid.EmptyGuid()) {
            exchangeRatesStore.dispatch(CrudAction.Create, { item: e, key: Constants.CltProductId });
        }
        else {
            exchangeRatesStore.dispatch(CrudAction.Update, { item: e, key: Constants.CltProductId });
        }
    }

    deleteItem(e: TransactionModel): void {
        exchangeRatesStore.dispatch(CrudAction.Delete, createCrudQueryPayload<ExchangeRateModel>(undefined, [ { field: "id", op: "eq", comparand: e.id! } ], undefined, false, Constants.CltProductId));
    }
    
}
