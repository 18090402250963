














import { SubTaskModel } from '@/libs/Api';
import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';

@Component({})
export default class SubTaskItem extends Vue {
    
    isChecked = false;
    description = "";

    @VModel() innerValue!: SubTaskModel;

    @Watch("innerValue")
    innerValueChanged(v: SubTaskModel) {
        this.isChecked = v.isChecked ?? false;
        this.description = v.description ?? "";
    }

    mounted () {
        this.isChecked = this.innerValue?.isChecked ?? false;
        this.description = this.innerValue?.description ?? "";
    }

    changed () {
        const value = {...this.innerValue};
        this.innerValue.isChecked = this.isChecked;
        this.innerValue.description = this.description;
        this.$emit("input", value);
    }

}

