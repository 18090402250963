
























































import { PartyModel, QueryModel, UserTaskModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { partiesStore } from '@/libs/parties/+state/store';
import { tasksStore } from '@/libs/tasks/+state/store';
import Page from '@/Page.vue';
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import TaskDialog from '@/libs/tasks/components/TaskDialog.vue';

var itemVue = Vue.component("itemTemplate", {
  template: `<span><span class='searchBox--type'>{{data.typeName}}</span><span class='searchBox--name'>{{data.name}}</span></span>`,
  data() {
    return {
      data: {}
    };
  }
});

@Component({
    components: {
        TaskDialog
    }
})
export default class SearchBox extends Page {
  
    item: any | null = null;
    search: string | null = "";
    debounce: any = null;
    dropDownOpened = false;
    loading = false;
    taskId = null;
    cancelOnce = false;

    @Ref() searchMenuDropdown: any;
    @Ref() taskDialog!: TaskDialog;
    @Prop() groupId!: string;

    get items(): any[] {
        
        const parties = [...partiesStore.useGetter(CrudGetter.Data, "searchBox")].map((e: any) => {
            e.name = e.displayName;
            return {
                type: "party",
                data: e
            }
        });
        
        const tasks = [...tasksStore.useGetter(CrudGetter.Data, "searchBox")].map((e: any) => {
            return {
                type: "task",
                data: e
            }
        });

        return parties.concat(tasks);

    }

    @Watch("search")
    searchChanged (v: string) {
        if (this.cancelOnce) {
            this.cancelOnce = false;
            return;
        }
        this.searchOnServer(v);
    }

    itemClicked (e: any, args: any) {
        this.cancelOnce = true;
        this.$nextTick(() => {
            if (e == null || e.length == 0)
                return;
            this.search = null;
            this.item = null;
            if (e.type == "party") {
                this.$router.push({ name: "ClientGeneral", params: { id: e.data.id } });
                this.$emit("changed", e);
            }
            else {
                this.taskId = e.data.id;
                this.taskDialog.open(this.taskId);
            }
        });
    }

    searchOnServer (v: any) {

        clearTimeout(this.debounce);

        if (v != null && v.length > 0 || this.item?.name == v) {
            const partyFilters = this.getPartyFilters(v);
            const taskFilters = this.getTaskFilters(v);
            this.debounce = setTimeout(() => {
                partiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyModel>(undefined, partyFilters, { pageIndex: 1, pageSize: 5 }, false, "searchBox"));
                tasksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<UserTaskModel>(undefined, taskFilters, { pageIndex: 1, pageSize: 5 }, false, "searchBox"));
            }, 300);
        }

    }

    getPartyFilters (v: string) {
        const parts = v?.split(" ") ?? [];
        let filters: Array<any> = [
            { field: "AccountType", op: "eq", comparand: "Client" }
        ];
        for (var p of parts) {
            if (p?.length == 0)
                continue;
            filters.push({
                field: "firstname",
                op: "wrap",
                comparand: [
                    {
                        field: "firstname",
                        op: "c",
                        comparand: p.trim(),
                        junction: "or"
                    },
                    {
                        field: "lastname",
                        op: "c",
                        comparand: p.trim(),
                        junction: "or"
                    },
                    {
                        field: "companyName",
                        op: "c",
                        comparand: p.trim(),
                        junction: "or"
                    }
                ],
                junction: "and"
            });
        }
        return filters;
    }

    getTaskFilters (v: string) {
        const parts = v?.split(" ") ?? [];
        let filters: Array<any> = [];
        for (var p of parts) {
            if (p?.length == 0)
                continue;
            filters.push({
                field: "name",
                op: "c",
                comparand: p.trim(),
                junction: "or"
            });
        }
        return filters;
    }

    // created () {
    //     window.addEventListener('mousemove', this.clickTarget);
    // }

    // destroyed () {
    //     window.removeEventListener('mousemove', this.clickTarget);
    // }

    // searchDropdownToggle () {
    //     this.searchMenuDropdown.classList.toggle("show");
    //     this.dropDownOpened = !this.dropDownOpened;
    // }

    // clickTarget (args: any) {
    //     if (!args.target.matches(".search-menu-btn") && !args.target.matches(".ms-Icon--Search") && !args.target.matches(".e-autocomplete") && !args.target.matches(".e-clear-icon")) {
    //         this.searchMenuDropdown.classList.remove("show");
    //         this.dropDownOpened = false;
    //     }
    // }

}
