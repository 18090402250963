import { Vue } from 'vue-property-decorator';
import moment from 'moment';

Vue.filter('number', function (value: number | string, precision = 3) {
    if (typeof value !== "number") {
        return value;
    }
    const formatter = new Intl.NumberFormat('cs');
    return formatter.format(Number(Number(value).toFixed(precision)));
});

Vue.filter('abs', function (value: number | string) {
    if (typeof value !== "number") {
        return value;
    }
    return Math.abs(Number(value));
});
