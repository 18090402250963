









import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CloudFiles from '@/libs/cloud/components/CloudFiles.vue';
import historyState from 'history-state';

@Component({
    components: { CloudFiles }
})
export default class Cloud extends Vue {

    showFileComponent = true;

    restart() {
        this.showFileComponent = false;
        this.$nextTick(() => {
            this.showFileComponent = true;
        })
    }

}
