




import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({})
export default class AccountBase extends Vue {

    mounted () {
        this.$router.replace({ name: "AccountGeneral" })
    }

}
