import * as signalR from '@microsoft/signalr';
import {Subject} from '@microsoft/signalr';
import {NotificationEventType} from '@/libs/core/notifications/models/notification-event-type';
import {Notification} from '@/libs/core/notifications/models/notification';
import ApiRequestIds from '@/libs/core/api-request-ids';

class NotificationService {
    created: Subject<Notification> = new Subject<Notification>();
    updated: Subject<Notification> = new Subject<Notification>();
    deleted: Subject<Notification> = new Subject<Notification>();

    private connection!: signalR.HubConnection;

    async init() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl((window as any).ApiService.baseUrl + "/events")
            .withAutomaticReconnect()
            .build();

        try {
            await this.connection.start();
            this.registerEvents();
            this.setHeaders();
        } catch (error) {
            console.error(error);
        }
    }

    private registerEvents() {
        this.connection.on('send', (data) => {
            console.log(data);
        });

        this.connection.on(NotificationEventType.ObjectAdded, (e) => {
            console.log("NotificationEventType.ObjectAdded", e);
            const parsedEvent = JSON.parse(e);
            console.info(NotificationEventType.ObjectAdded, parsedEvent);
            const ignoreNotification = this.popCorrelationId(parsedEvent.correlationId);
            if (!ignoreNotification) {
                this.created.next(parsedEvent);
            }
        });
        this.connection.on(NotificationEventType.ObjectUpdated, (e) => {
            console.log("NotificationEventType.ObjectUpdated", e);
            const parsedEvent: Notification = JSON.parse(e);
            console.info(NotificationEventType.ObjectUpdated, parsedEvent);
            const ignoreNotification = this.popCorrelationId(parsedEvent.correlationId);
            if (!ignoreNotification) {
                this.updated.next(parsedEvent);
            }
        });
        this.connection.on(NotificationEventType.ObjectDeleted, (e) => {
            console.log("NotificationEventType.ObjectDeleted", e);
            const parsedEvent = JSON.parse(e);
            console.info(NotificationEventType.ObjectDeleted, parsedEvent);
            const ignoreNotification = this.popCorrelationId(parsedEvent.correlationId);
            if (!ignoreNotification) {
                this.deleted.next(parsedEvent);
            }
        });
    }

    private popCorrelationId(correlationId: string): boolean {
        const correlationIdIndex = ApiRequestIds.findIndex(id => correlationId === id);
        if (correlationIdIndex >= 0) {
            ApiRequestIds.splice(correlationIdIndex, 1);
            return true;
        }
        return false;
    }

    private setHeaders() {
        (window as any).SignalClientId = this.connection.connectionId;
    }
}

export default new NotificationService();
