import NotificationService from '@/libs/core/notifications/notification-service';
import {Plugin} from 'vuex'
import {createStoreAction} from '@/libs/core/+state/functions/create-store-action';
import {createStoreGetter} from '@/libs/core/+state/functions/create-store-getter';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {CrudEntity} from '@/libs/core/+state/models/crud-service';
import {CrudAction} from '@/libs/core/+state/models/crud-action';
import {BusinessModule} from '@/libs/core/models/business-module';

export function createNotificationPlugin(): Plugin<any> {
    const getStore = (storeName: BusinessModule) => ({
        action: createStoreAction(storeName),
        getter: createStoreGetter(storeName)
    });

    return store => {
        NotificationService.created.subscribe({
            next: notification => {
                const store = getStore(notification.objectType);

                const relations = notification.relations || {'': null};
                Object.keys(relations).forEach(relationKey => {
                    const data: CrudEntity[] = store.getter(CrudGetter.Data, notification.relations[relationKey]);
                    if (data !== undefined) {
                        store.action(CrudAction.GetAll, {
                            key: notification.relations[relationKey], query: 'same', extra: {
                                ignoreKeySuffix: true,
                                command: notification.command
                            }
                        })
                    }
                });
            },
            complete() {
                console.log('STORE NOTIFICATION', 'CREATED COMPLETE');
            },
            error(err: any) {
                console.error('STORE NOTIFICATION CREATED ERROR', err);
            }
        });

        NotificationService.updated.subscribe({
            next: notification => {
                const store = getStore(notification.objectType);
                store.action(CrudAction.PartialUpdate, {
                    id: notification.objectId,
                    key: notification.objectId,
                    diff: notification.diff,
                    extra: {
                        local: true,
                        ignoreKeySuffix: true,
                        command: notification.command
                    }
                })
            },
            complete() {
                console.log('STORE NOTIFICATION', 'UPDATE COMPLETE');
            },
            error(err: any) {
                console.error('STORE NOTIFICATION UPDATE ERROR', err);
            }
        });

        NotificationService.deleted.subscribe({
            next: notification => {
                const store = getStore(notification.objectType);

                const relations = notification.relations || {'': null};
                Object.keys(relations).forEach(relationKey => {
                    const data: CrudEntity[] = store.getter(CrudGetter.Data, notification.relations[relationKey]);
                    const detail: CrudEntity[] = store.getter(CrudGetter.Detail, notification.relations[relationKey]);

                    if (data?.find(item => item.id === notification.objectId) || detail) {
                        store.action(CrudAction.Delete, {
                            id: notification.objectId,
                            key: notification.relations[relationKey],
                            extra: {
                                ignoreKeySuffix: true,
                                command: notification.command
                            }
                        })
                    }
                });
            }, complete() {
                console.log('STORE NOTIFICATION', 'DELETE COMPLETE');
            },
            error(err: any) {
                console.error('STORE NOTIFICATION DELETE ERROR', err);
            }
        })
    };
}
