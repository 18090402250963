

































































































































import { PartyCreditModel, DirectionEnum, CreditStateEnum, ApplicationUserModel, AccountTypeEnum, PartyCreditUploadModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { partyCreditsStore } from '@/libs/parties/+state/store';
import { Guid } from '@/libs/common/functions/guid';
import { CrudTableEnumerator, CrudTableEnumeratorItem } from '@/libs/common/models/crud-table-enumerator';
import moment from 'moment';
import { applicationUserStore } from '@/libs/user-settings/+state/store';

@Component({
    components: {
        CrudTable
    }
})
export default class Credits extends Vue {

    headers = [
        { text: 'Datum', align: 'left', sortable: false, value: 'date', type: "date" },
        { text: 'Klient', align: 'left', sortable: false, value: 'party.displayName', type: "text" },
        { text: 'Tvůrce', align: 'left', sortable: false, value: 'createdBy.displayName', type: "text" },
        { text: 'Počet kreditů', align: 'left', sortable: false, value: 'quantityReal', type: "text" },
        { text: 'Způsob', align: 'left', sortable: false, value: 'direction', type: "enumerator", enumerator: "Direction" },
        { text: 'Stav', align: 'left', sortable: false, value: 'creditState', type: "enumerator", enumerator: "CreditState" },
        { text: 'Popis', align: 'left', sortable: false, value: 'description', type: "text" },
    ];

    clientId: string | null = null;
    since: string | null = null;
    until: string | null = null;
    direction: string | null = null;
    showFilters = false;
    user: ApplicationUserModel =  applicationUserStore.useGetter(CrudGetter.Detail);

    uploadedFiles = [];
    uploadError = null
    currentStatus = null;
    uploadFieldName = 'photos';
    isSaving = false;

    @Ref() crudTable!: CrudTable;

    @Watch("clientId")
    @Watch("since")
    @Watch("until")
    @Watch("direction")
    filtersChanged () {
        this.loadData();
    }

    get isAdminOrManager () {
        return this.user.accountType == AccountTypeEnum.Admin || this.user.accountType == AccountTypeEnum.Manager;
    }

    get enumerators (): CrudTableEnumerator {
        return {
            "Direction": [
                { value: "In", text: "Přípis", color: "green--text" },
                { value: "Out", text: "Odpis", color: "red--text" }
            ],
            "CreditState": [
                { value: "WaitingForApproval", text: "Čeká na schválení", color: "orange--text" },
                { value: "Approved", text: "Připsáno", color: "green--text" }
            ]
        } as CrudTableEnumerator;
    }

    get items(): PartyCreditModel[] {
        return partyCreditsStore.useGetter(CrudGetter.Data);
    }

    get defaultCreditItem(): PartyCreditModel {
        return {
            id: Guid.EmptyGuid(),
            date: null,
            partyId: null,
            quantity: null,
            quantityReal: null,
            direction: DirectionEnum.In,
            creditState: CreditStateEnum.WaitingForApproval,
            description: null,
            createdById: Guid.EmptyGuid()
        } as PartyCreditModel
    }

    get timestamp() {
        return new Date().getTime().toString();
    }

    mounted() {
        this.loadData();
    }

    loadData () {
        let filters: CrudQueryPredicate[] = [
        ];
        if (this.clientId) {
            filters.push({ field: "partyId", op: "eq", comparand: this.clientId });
        }
        if (this.since) {
            filters.push({ field: "date", op: "gte", comparand: this.since });
        }
        if (this.until) {
            filters.push({ field: "date", op: "lte", comparand: this.until });
        }
        if (this.direction) {
            filters.push({ field: "direction", op: "eq", comparand: this.direction });
        }
        partyCreditsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyCreditModel>([ { field: "date", index: 1, order: "desc" } ], filters));
    }

    addCreditClick () {
        this.crudTable.addItemClick();
    }

    saveItem (e: PartyCreditModel) {
        if (e.direction == DirectionEnum.In) {
            e.quantityReal = Math.abs(e.quantity);
        }
        else {
            e.quantityReal = Math.abs(e.quantity) * -1;
        }
        if (e.id == Guid.EmptyGuid() || e.id == null) {
            partyCreditsStore.dispatch(CrudAction.Create, { item: e });
        }
        else {
            partyCreditsStore.dispatch(CrudAction.Update, { item: e });
        }
    }

    deleteItem (e: PartyCreditModel) {
        partyCreditsStore.dispatch(CrudAction.Delete, createCrudQueryPayload<PartyCreditModel>(undefined, [ { field: "id", op: "eq", comparand: e.id! } ]));
    }
    
    activateNewest () {
        this.since = moment().subtract(2, "month").format("YYYY-MM-DDT00:00:00");
        this.until = moment().format("YYYY-MM-DDT23:59:59");
    }

    activateThisMonth () {
        this.since = moment().startOf("month").format("YYYY-MM-DDT00:00:00");
        this.until = moment().format("YYYY-MM-DDT23:59:59");
    }

    enableActions (item: PartyCreditModel) {
        //return (item.creditState == CreditStateEnum.WaitingForApproval && (this.user.accountType == AccountTypeEnum.Consultant)) || 
                //(this.user.accountType == AccountTypeEnum.Admin || this.user.accountType == AccountTypeEnum.Manager || this.user.accountType == AccountTypeEnum.Assistant);
        return true;
    }

    approve (item: PartyCreditModel) {
        item.creditState = CreditStateEnum.Approved;
        partyCreditsStore.dispatch(CrudAction.Update, { item: item });
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === partyCreditsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                subscriber();
                this.loadData();
            }
            else if(mutation.type === partyCreditsStore.getActionName(CrudResponseAction.UpdateFailed)) {
                subscriber();
            }
        });
    }

}
