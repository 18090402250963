











































































































































































import { EventModel, CodebookModel, CodebookType, ProductTypeModel, ApplicationUserModel, PartyModel } from '@/libs/Api';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { eventsStore } from '@/libs/events/+state/store';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import CodebookSelect from '@/libs/common/components-smart/CodebookSelect.vue';
import EventDialog from '@/libs/events/components/EventDialog.vue';
import moment from 'moment';
import { startsWith } from 'lodash';
import { CrudQueryModel } from '@/libs/core/+state/models/crud-service';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { Guid } from '@/libs/common/functions/guid';
import { applicationUserStore } from '@/libs/user-settings/+state/store';

@Component({
    components: {
        CodebookSelect,
        EventDialog
    }
})
export default class Calendar extends Vue {

    currentDate = moment().toDate();
    taskId: string | null = null;
    partyId: string | null = null;
    eventPublicity: boolean | number | null = 0;
    ownerId: string | null = (applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel).partyId!;
    eventTypeId: any = Guid.EmptyGuid();
    headers = [
        { text: 'Jméno a příjmení', value: 'name' }
    ];
    type: string | null = "week";
    newId = Guid.EmptyGuid();
    showFilters = false;

    dragEvent: any = null;
    dragStart: any = null;
    createEvent: any = null;
    createStart: any = null;
    extendOriginal: any = null;
    dragTime: any = null;

    onceLoaded = false;

    @Ref() dialog!: EventDialog;
    @Ref() calendar!: any;

    @Watch("partyId")
    @Watch("ownerId")
    @Watch("eventTypeId")
    @Watch("eventPublicity")
    filtersChanged () {
        this.loadData();
    }

    @Watch("calendar")
    calendarAppeared () {
        console.log("calendarAppeared");
    }

    /*get calendarInstance (): Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => any; title: string } {
        return this.$refs.calendar as Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => any; title: string }
    }*/

    get codebooks(): CodebookModel[] {
        const type = "EventType";
        return codebooksStore.useGetter(CrudGetter.Data)?.filter((e: CodebookModel) => {
            return e.type == CodebookType[type as keyof typeof CodebookType];
        }).sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; });
    }

    get events(): EventModel[] {
        return [...eventsStore.useGetter(CrudGetter.Data).map((e: EventModel) => {
            return {
                id: e.id,
                name: e.name,
                start: e.start ? moment(e.start).toDate() : null,
                end: e.start ? moment(e.end).toDate() : null,
                color: e.color ? "#".concat(e.color!.replaceAll("#", "").slice(0, 6) ?? "43D01A") : "43D01A",
                timed: true,
                startDate: moment(e.start).format("YYYY-MM-DD"),
                endDate: moment(e.end).format("YYYY-MM-DD"),
                item: e
            }
        })];
    }
    
    get calendarTitle(): string {
        switch (this.type) {
            case "day":
                return moment(this.currentDate).format("dddd, D. MMMM YYYY");
            case "week":
                return moment(this.currentDate).startOf("isoWeek").format("W. [týden], DD.MM.YYYY").concat(" - ").concat(moment(this.currentDate).endOf("isoWeek").format("DD.MM.YYYY"));
            case "month":
                return moment(this.currentDate).startOf("month").format("MMM YYYY");
        }
        return "";
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get pendingEvents() : boolean {
        return eventsStore.useGetter(CrudGetter.Pending);
    }

    mounted () {
        this.loadData();
        if (this.$vuetify.breakpoint.smAndDown) {
            this.type = "day";
        }
        if (this.$router.currentRoute.query?.id) {
            this.dialog.open(this.$router.currentRoute.query!.id! as string);
        }
    }

    loadData () {
        let query: CrudQueryPredicate[] = [];
        if (this.partyId != null) 
            query.push({ field: "partyId", op: "eq", comparand: this.partyId! });
        if (this.eventTypeId != this.newId) 
            query.push({ field: "eventTypeId", op: "eq", comparand: this.eventTypeId! });
        if (this.eventPublicity == 1) {
            query.push({ field: "isPublic", op: "eq", comparand: true });
        }
        else {
            if (this.ownerId != null) {
                query.push({
                    field: "id",
                    op: "wrap",
                    comparand: [
                        { field: "EventParticipants.PartyId", op: "eq", comparand: this.ownerId!, junction: "or" },
                        { field: "isPublic", op: "eq", comparand: true, junction: "or" }
                    ]
                });
            }
        
        }
        let startDate = this.currentDate;
        let endDate = this.currentDate;
        if (this.type == "week") {
            startDate = moment(this.currentDate).startOf("isoWeek").toDate();
            endDate = moment(this.currentDate).endOf("isoWeek").toDate();
        }
        else if (this.type == "month") {
            startDate = moment(this.currentDate).startOf("month").toDate();
            endDate = moment(this.currentDate).endOf("month").toDate();
        }
        eventsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<EventModel>(undefined, [
            ...query,
            {
                field: "id", op: "wrap", comparand: [
                    {
                        field: "id", op: "wrap", comparand: [
                            { field: "start", op: "gte", comparand: moment(startDate).format("YYYY-MM-DDT00:00:00") },
                            { field: "start", op: "lte", comparand: moment(endDate).format("YYYY-MM-DDT23:59:59") }
                        ], junction: "or"
                    },
                    {
                        field: "id", op: "wrap", comparand: [
                            { field: "end", op: "gte", comparand: moment(startDate).format("YYYY-MM-DDT00:00:00") },
                            { field: "end", op: "lte", comparand: moment(endDate).format("YYYY-MM-DDT23:59:59") }
                        ], junction: "or"
                    }
                ]
            }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === eventsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.$nextTick(() => {
                    self.calendar.scrollToTime("08:00");
                });
            }
        });
    }

    openEvent (e: any) {
        this.dialog.open(e.event.id);
    }

    prev () {
        if (this.type == "day")
            this.currentDate = moment(this.currentDate).add(-1, 'days').toDate();
        else if (this.type == "week")
            this.currentDate = moment(this.currentDate).add(-1, 'week').toDate();
        else if (this.type == "month")
            this.currentDate = moment(this.currentDate).add(-1, 'month').toDate();
        this.loadData();
    }

    next () {
        if (this.type == "day")
            this.currentDate = moment(this.currentDate).add(1, 'days').toDate();
        else if (this.type == "week")
            this.currentDate = moment(this.currentDate).add(1, 'week').toDate();
        else if (this.type == "month")
            this.currentDate = moment(this.currentDate).add(1, 'month').toDate();
        this.loadData();
    }

    startDrag ({ event, timed }: any) {
        if (event && timed) {
          this.dragEvent = event
          this.dragTime = null
          this.extendOriginal = null
        }
    }

    startTime (tms: any) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start;
          this.dragTime = mouse - start
        }
    }

    mouseMove (tms: any) {
        const mouse = this.toTime(tms);
        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start;
          const end = this.dragEvent.end;
          const duration = end - start;
          const newStartTime = mouse - this.dragTime;
          const newStart = this.roundTime(newStartTime);
          const newEnd = newStart + duration;
          this.dragEvent.start = moment(newStart).toDate();
          this.dragEvent.end = moment(newEnd).toDate();
          console.log(moment(newStart).toDate());
        }
    }

    changeEvents () {
        console.log("changeEvents");
    }
    
    endDrag () {
        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
    }
    
    cancelDrag () {
        if (this.createEvent) {
            if (this.extendOriginal) {
                this.createEvent.end = this.extendOriginal
            }
            else {
                const i = this.events.indexOf(this.createEvent)
                if (i !== -1) {
                    this.events.splice(i, 1)
                }
            }
        }
        this.createEvent = null;
        this.createStart = null;
        this.dragTime = null;
        this.dragEvent = null;
    }

    roundTime (time: any, down = true) {
        const roundTo = 15; // minutes
        const roundDownTime = roundTo * 60 * 1000;

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime));
    }
    
    toTime (tms: any) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    }

    rnd (a: any, b: any) {
        return Math.floor((b - a + 1) * Math.random()) + a;
    }

    rndElement (arr: any) {
        return arr[this.rnd(0, arr.length - 1)];
    }

}
