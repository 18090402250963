import {RouteConfig} from 'vue-router';

import Notifications from '@/libs/notifications/pages/Notifications.vue';

export default [
    {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications,
    }
] as RouteConfig[];
