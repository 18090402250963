





























import { SubTaskModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import { Component, Prop, PropSync, VModel, Vue, Watch, Ref } from 'vue-property-decorator';
import SubTaskItem from './SubTaskItem.vue';

@Component({
    components: {
        SubTaskItem
    }
})
export default class SubTaskItemList extends Vue {
    
    isChecked = false;
    description = "";
    items: Array<SubTaskModel> = [];
    
    get totalCount() {
        return this.items?.length ?? 0;
    }
    
    get completedCount() {
        return this.items?.where(x => x.isChecked == true).toArray().length ?? 0;
    }

    get completedPercent() {
        const totalCount = this.items?.length ?? 0;
        const completedCount = this.items?.where(x => x.isChecked == true).toArray().length ?? 0;
        return totalCount > 0 ? completedCount / totalCount * 100 : 100;
    }
    
    @VModel() innerValue!: Array<SubTaskModel>;

    @Watch("innerValue")
    innerValueChanged (v: Array<SubTaskModel>) {
        this.items = v;
    }

    mounted () {
        this.items = this.innerValue;
    }

    addSubTask () {
        this.items.push({
            id: Guid.EmptyGuid(),
            isChecked: false,
            description: ""
        } as SubTaskModel);
    }

    changed () {
        this.$emit("input", this.items);
    }

}

