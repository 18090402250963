














import { ProductTypeModel } from '@/libs/Api';
import { Component, Prop, PropSync, VModel, Vue, Watch } from 'vue-property-decorator';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { productsStore } from '@/libs/products/+state/store';

@Component({ })
export default class ProductSelect extends Vue {

    @Prop({ default: "Typ produktu" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() readonly!: boolean;
    @Prop() clearable!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;

    get items(): ProductTypeModel[] {
        return productsStore.useGetter(CrudGetter.Data);
    }

}
