




















import ApiService from "@/libs/core/api-service"
import { ProductNotificationRuleModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { productNotificationRulesStore } from '@/libs/products/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { Constants } from "@/libs/constants/constants";
import { Guid } from "@/libs/common/functions/guid";

@Component({
    components: {
        CrudTable
    }
})
export default class ProductNotificationRules extends Vue {

    downloading = false;
    headers = [
        { text: 'Název', align: 'left', sortable: false, value: 'name', type: "text", required: true },
        { text: 'Produkt', align: 'left', sortable: false, value: 'productId', valueText: "product.name", type: "product", required: true },
        { text: 'Čas podmínky', align: 'left', sortable: false, value: 'actionType', required: true, type: "select", items: [
            { id: "Before", name: "Před" },
            { id: "After", name: "Po" }
        ] },
        { text: 'Typ podmínky', align: 'left', sortable: false, value: 'action', required: true, type: "select", items: [
            { id: "ContractEnd", name: "Začátek smlouvy" },
            { id: "ContractSigned", name: "Konec smlouvy / výročí" },
            { id: "FixationEnd", name: "Fixace" }
        ] },
        { text: 'Počet dní', align: 'left', sortable: false, value: 'numberOfDays', required: true, type: "number" },
        { text: 'Aktivní', align: 'left', sortable: false, value: 'isActive', required: true, type: "boolean" },
    ];

    @Ref() crudTable!: CrudTable;

    get items(): ProductNotificationRuleModel[] {
        return productNotificationRulesStore.useGetter(CrudGetter.Data);
    }

    get pendingRules(): boolean {
        return productNotificationRulesStore.useGetter(CrudGetter.Pending);
    }

    get savingRules(): boolean {
        return productNotificationRulesStore.useGetter(CrudGetter.Saving);
    }

    mounted() {
        this.loadData();
    }

    loadData () {
        productNotificationRulesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductNotificationRuleModel>([{ field: "name", index: 1, order: "asc" }]));
    }

    beforeInsertRule (e: any) {
        e.id = Guid.EmptyGuid();
        return e;
    }

    saveRule (e: ProductNotificationRuleModel) {
        if (e.id == Guid.EmptyGuid()) {
            productNotificationRulesStore.dispatch(CrudAction.Create, { item: e });
        }
        else {
            productNotificationRulesStore.dispatch(CrudAction.Update, { item: e });
        }
    }

    deleteRule (e: ProductNotificationRuleModel) {
        productNotificationRulesStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [
            { field: "id", op: "eq", comparand: e.id! }
        ], undefined, false));
    }
    
}
