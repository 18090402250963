export enum CrudAction {
    GetAll = 'GET_ALL',
    Get = 'GET',
    Create = 'CREATE',
    Update = 'UPDATE',
    PartialUpdate = 'UPDATE_PARTIAL',
    BatchPartialUpdate = 'BATCH_UPDATE_PARTIAL',
    Delete = 'DELETE',
    Download = 'DOWNLOAD',
    SetEcho = 'SET_ECHO',
    GetCount = "GET_COUNT",
    GetAvg = "GET_AVERAGE",
    GetSum = "GET_SUM",
    GetGroupped = 'GET_GROUPPED',
}

export enum CrudResponseAction {
    GetAllSuccess = 'GET_ALL_SUCCESS',
    GetAllNoop = 'GET_ALL_NOOP',
    GetAllFailed = 'GET_ALL_FAILED',
    GetSuccess = 'GET_SUCCESS',
    GetFailed = 'GET_FAILED',
    CreateSuccess = 'CREATE_SUCCESS',
    CreateFailed = 'CREATE_FAILED',
    UpdateSuccess = 'UPDATE_SUCCESS',
    UpdateFailed = 'UPDATE_FAILED',
    PartialUpdateSuccess = 'UPDATE_PARTIAL_SUCCESS',
    PartialUpdateFailed = 'UPDATE_PARTIAL_FAILED',
    BatchPartialUpdateSuccess = 'BATCH_UPDATE_PARTIAL_SUCCESS',
    BatchPartialUpdateFailed = 'BATCH_UPDATE_PARTIAL_FAILED',
    DeleteSuccess = 'DELETE_SUCCESS',
    DeleteSuccessDone = 'DELETE_SUCCESS_DONE',
    DeleteFailed = 'DELETE_FAILED',
    DownloadSuccess = 'DOWNLOAD_SUCCESS',
    DownloadSuccessDone = 'DOWNLOAD_SUCCESS_DONE',
    DownloadFailed = 'DOWNLOAD_FAILED',
    GetCountSuccess = 'GET_COUNT_SUCCESS',
    GetCountFailed = 'GET_COUNT_FAILED',
    GetAvgSuccess = 'GET_AVERAGE_SUCCESS',
    GetAvgFailed = 'GET_AVERAGE_FAILED',
    GetSumSuccess = 'GET_SUM_SUCCESS',
    GetSumFailed = 'GET_SUM_FAILED',
    GetGrouppedSuccess = 'GET_GROUPPED_SUCCESS',
    GetGrouppedNoop = 'GET_GROUPPED_NOOP',
    GetGrouppedFailed = 'GET_GROUPPED_FAILED',
}

export enum CrudReponse {
    GetAll = "GET_ALL",
    Get = "GET",
    Create = "CREATE",
    Update = "UPDATE",
    PartialUpdate = "UPDATE_PARTIAL",
    BatchPartial = "BATCH_UPDATE_PARTIAL",
    Delete = "DELETE",
    GetCount = "GET_COUNT",
    GetAvg = "GET_AVERAGE",
    GetSum = "GET_SUM",
    Download = "DOWNLOAD",
    GetGroupped = "GET_GROUPPED",
}