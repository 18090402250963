






































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';

import VueApexCharts from 'vue-apexcharts';
import { productActivitiesStore, productsStore } from '@/libs/products/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { Constants } from '@/libs/constants/constants';
import { PartyModel, PartyProductModel, ProductActivityModel, ProductModel, StateOfCapitalModel, TransactionModel, StateOfCapitalYearModel } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import NREIDialog from '@/libs/clients/components/NREIDialog.vue';
import { transactionsStore } from '@/libs/transactions/+state/store';
import moment from 'moment';
import { Guid } from '@/libs/common/functions/guid';
import { stateOfCapitalStore, stateOfCapitalYearStore } from '@/libs/state-of-capital/+state/store';
import { StateOfTransactionModel } from '../models/StateOfTransactionModel';

@Component({
    components: {
        VueApexCharts,
        NREIDialog
    }
})
export default class ClientProductNREI extends Vue {

    get chartOptions() {
        const n = {
            chart: {
                type: 'line',
                background: "transparent",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                tickAmount: 6,
                labels: {
                    formatter: (value: Date) => {
                        if (moment(value).isBefore(moment(this.year + "-01-01 00:00:00"))) {
                            return "Zahájení roku";
                        }
                        else {
                            return moment(value).format("MM.YYYY");
                        }
                    }
                },
                min: moment(this.year + "-01-01 00:00:00").add(-1, "month").toDate().getTime(),
                max: moment(this.year + "-12-31 23:59:59").toDate().getTime()
            },
            yaxis: [
                {
                    min: 0,
                    labels: {
                        formatter: function (value: number) {
                            return Number(value).toFixed(2).concat(" %");
                        }
                    },
                    forceNiceScale: true
                }
            ],
            theme: {
                mode: "dark"
            },
            dataLabels: {
                enabled: false
            }
        };
        return n;
    }
    headersTransactions = [
        { text: 'Klient', align: 'left', sortable: false, value: 'party.displayName', suffix: "Kč", type: "money" },
        { text: 'Vklad', align: 'left', sortable: false, value: 'deposit', suffix: "Kč", type: "money" },
        { text: 'Výnos', align: 'left', sortable: false, value: 'earnings', suffix: "Kč", type: "money" },
        { text: 'Úrok', align: 'left', sortable: false, value: 'interestRate', suffix: "%", type: "number" },
        { text: 'Výběr', align: 'left', sortable: false, value: 'withdrawal', suffix: "Kč", type: "money" },
        { text: 'Stav kapitálu', align: 'left', sortable: false, value: 'stateOfCapital', suffix: "Kč", type: "money" },
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "date" },
    ];
    items = [];
    product: ProductModel | null = null;
    partyProduct: PartyProductModel | null = null;
    stateOfCapital : number | null = null;
    stateOfCapitalIncrease : number | null = null;
    totalDeposit: number | null = null;
    monthlyEarnings: number | null = null;
    totalWithdrawal: number | null = null;
    totalEarnings: number | null = null;
    history: StateOfTransactionModel[] = [];
    clientEvaluations: Array<any> = [];
    year = moment().year();

    get years () {
        let d = 2022;
        const out: Array<number> = [];
        while (d <= moment().year()) {
            out.push(d);
            d++;
        }
        return out;
    }

    get ProductActivities () : ProductActivityModel[] {
        return productActivitiesStore.useGetter(CrudGetter.Data);
    }

    get transactions () : TransactionModel[] {
        return transactionsStore.useGetter(CrudGetter.Data).filter((e: TransactionModel) => { return moment(e.time).isBefore(moment().endOf("month")); });
    }
    
    get showChart(): boolean {
        const transactions = 
            this.history
                .filter(e => { return moment(e.time).isBefore(moment()); })
                .sort((a: StateOfTransactionModel, b: StateOfTransactionModel) => { return a.time.getTime() - b.time.getTime(); });
        if (transactions.length <= 0)
            return false;
        return true;
    }
    
    get chartSeries () : any[] {
        const stateOfCapitalItems = stateOfCapitalStore.useGetter(CrudGetter.Data) as StateOfCapitalModel[];
        if (this.partyProduct == null)
            return [];
        /*const transactions = 
            this.history
                .filter(e => { return moment(e.time).isBefore(moment()); })
                .sort((a: StateOfTransactionModel, b: StateOfTransactionModel) => { return a.time.getTime() - b.time.getTime(); });
        if (transactions.length <= 0)
            return [];
        let dataSeriesFund = [];
        let dataSeriesClient = [];
        let previousStateOfCapital = null;
        const partyProduct = this.partyProduct;
        const partyProductInterestRate = this.partyProduct!.interestRate!;
        const dateOfStart = this.partyProduct!.dateOfStart!;
        let interestRate = this.partyProduct!.interestRate!;
        let i = 1;
        for (let transaction of transactions) {
            const n = transaction as any;
            const d = moment(transaction.time!).startOf("month").toDate().getTime();
            const s = stateOfCapitalItems.firstOrDefault(x => moment(x.date).startOf("month").toDate().getTime() == d);
            const monthsFromStart = moment(transaction.time!).startOf("month").diff(moment(dateOfStart).startOf("month"), "month");
            dataSeriesClient.push([ moment(transaction.time!).startOf("month").toDate().getTime(), partyProductInterestRate ]);
            if (s != null)
                dataSeriesFund.push([ d, ((Number(s.investmentValue) / Number(s.value)) - 1) * 100 ]);
            else if (previousStateOfCapital != null)
                dataSeriesFund.push([ d, ((Number(previousStateOfCapital.investmentValue) / Number(previousStateOfCapital.value)) - 1) * 100 ]);
            else
                dataSeriesFund.push([ d, 0 ]);
            if (s != null) {
                previousStateOfCapital = s;
            }
            interestRate *= (1 + this.partyProduct!.interestRate!);
            i++
        }
        dataSeriesClient = dataSeriesClient.where(x => moment(x[0]).year() == this.year).toArray();
        dataSeriesFund = dataSeriesFund.where(x => moment(x[0]).year() == this.year).toArray();
        this.clientEvaluations = dataSeriesClient;
        return [
            {
                name: 'Zhodnocení fondu',
                data: dataSeriesFund
            },
            {
                name: 'Zhodnocení klienta',
                data: dataSeriesClient
            }
        ];*/
        let dataSeriesFund: Array<any> = [ [ moment(this.year + '-01-01').add(-1, 'month').startOf("month").toDate().getTime(), 0 ] ];
        let dataSeriesClient: Array<any> = [ [ moment(this.year + '-01-01').add(-1, 'month').startOf("month").toDate().getTime(), 0 ] ];
        let fromDate = moment(this.year + '-01-01').startOf("year").toDate();
        let clientFromDate = moment(this.year + '-01-01').startOf("year").toDate();
        let toDate = moment(this.year + '-12-31').endOf("year").toDate();
        if (moment(fromDate).startOf("month").isBefore(moment(this.partyProduct!.dateOfStart))) {
            clientFromDate = moment(this.partyProduct!.dateOfStart).startOf("month").toDate();
        }
        if (moment(toDate).startOf("month").isAfter(moment(this.partyProduct!.dateOfEnding))) {
            toDate = moment(this.partyProduct!.dateOfEnding).endOf("month").toDate();
        }
        let currentDate = fromDate;
        let minDate = "2000-01-01T00:00:00";
        let maxDate = "2035-01-01T00:00:00";
        let clientEvaluationBase = 0;
        let fundEvaluationBase = 0;
        debugger;
        while (currentDate < toDate) {
            if (moment(currentDate).startOf("month").isSameOrAfter(moment().startOf("month"))) {
                break;
            }
            const interestRate = this.partyProduct.partyProductInterestRates.firstOrDefault(x => moment(x.fromDate ?? minDate).startOf("day").toDate() <= currentDate && moment(x.toDate ?? maxDate).endOf("day").toDate() >= currentDate)?.interestRate ?? 0;
            const d = moment(currentDate).startOf("month").toDate().getTime();
            const stateOfCapitalItem = stateOfCapitalItems.where(x => moment(x.date).startOf("month").toDate().getTime() == d).firstOrDefault();
            fundEvaluationBase += interestRate;
            if (currentDate >= clientFromDate && currentDate <= toDate) {
                clientEvaluationBase += interestRate;
            }
            const evaluation = stateOfCapitalItem != null ? (Number(stateOfCapitalItem.investmentValue) / Number(stateOfCapitalItem.value)) - 1 : 0;
            const fundEvaluation = fundEvaluationBase + evaluation * 100;
            const clientEvaluation = clientEvaluationBase;
            dataSeriesFund.push([ d, fundEvaluation ]);
            dataSeriesClient.push([ d, clientEvaluation ]);
            currentDate = moment(currentDate).add(1, "month").toDate();
        }
        this.clientEvaluations = dataSeriesClient;
        return [
            {
                name: 'Zhodnocení fondu',
                data: dataSeriesFund
            },
            {
                name: 'Zhodnocení klienta '.concat(this.partyProduct.party.firstName).concat(" ").concat(this.partyProduct.party.lastName),
                data: dataSeriesClient
            }
        ];
    }

    get pendingTransactions () : boolean {
        return transactionsStore.useGetter(CrudGetter.Pending);
    }

    get savingTransactions () : boolean {
        return transactionsStore.useGetter(CrudGetter.Saving);
    }

    get client () : PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.id);
    }

    get stateOfCapitalYear () : StateOfCapitalYearModel[] {
        return stateOfCapitalYearStore.useGetter(CrudGetter.Data);
    }
    
    get totalInvestment(): number {
        return this.history.sum(x => x.deposit - x.withdrawal);
    }

    get interestRate(): number {
        let minDate = "2000-01-01T00:00:00";
        let maxDate = "2035-01-01T00:00:00";
        return this.partyProduct.partyProductInterestRates.where(x => moment(x.fromDate ?? minDate).startOf("day").toDate() <= new Date() && moment(x.toDate ?? maxDate).endOf("day").toDate() >= new Date()).orderByDescending(x => moment(x.toDate ?? maxDate).toDate().getTime()).firstOrDefault()?.interestRate ?? 0;
    }
    
    @Prop() id!: string;
    @Prop() partyProductId!: string;

    mounted () {
        this.load();
    }

    load () {
        this.loadProduct();
        this.loadProductActivities();
        this.loadStateOfCapital();
        this.loadStateOfCapitalYear();
    }

    loadProduct () {
        productsStore.dispatch(CrudAction.Get, { id: Constants.NreiProductId, key: Constants.NreiProductId });
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === productsStore.getActionName(CrudResponseAction.GetSuccess)) {
                subscriber();
                self.loadPartyProduct();
            }
        });
    }

    loadPartyProduct () {
        partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
            { field: "id", op: "eq", comparand: this.partyProductId }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.makeProducts();
                if (this.partyProduct != null) {
                    self.loadTransactions();
                }
            }
        });
    }

    loadTransactions () {
        transactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TransactionModel>([{field: "time", order: "desc", index: 0}], [
            { field: "partyProductId", op: "eq", comparand: this.partyProduct!.id! }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === transactionsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.computeHistory();
            }
        });
    }

    makeProducts () {
        this.product = productsStore.useGetter(CrudGetter.Detail, Constants.NreiProductId);
        const partyProducts = partyProductsStore.useGetter(CrudGetter.Data);
        if (partyProducts.length > 0) {
            this.partyProduct = partyProducts[0];
        }
    }

    loadProductActivities () {
        productActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductActivityModel>([
            { field: "createdAt", order: "desc", index: 0 }
        ], [
            { field: "productId", op: "eq", comparand: Constants.NreiProductId }
        ]))
    }

    computeSummary (items: StateOfTransactionModel[]) {
        const transactions = [...items].sort((a: StateOfTransactionModel, b: StateOfTransactionModel) => { return moment(a.time).toDate().getTime() - moment(b.time).toDate().getTime(); }); 
        this.stateOfCapital = transactions.length > 0 ? transactions[transactions.length - 1].stateOfCapital! : 0;
        this.stateOfCapitalIncrease = transactions.length > 1 ? 
                (transactions[transactions.length - 1].stateOfCapital! / transactions[transactions.length - 2].stateOfCapital! - 1) * 100 :
                null
        this.totalDeposit = transactions.reduce((s: number, a: StateOfTransactionModel) => s + a.deposit!, 0);
        this.totalWithdrawal = transactions.reduce((s: number, a: StateOfTransactionModel) => s + a.withdrawal!, 0);
        this.totalEarnings = transactions.reduce((s: number, a: StateOfTransactionModel) => s + a.earnings!, 0) + (this.partyProduct.initialEvaluation ?? 0);
    }

    computeHistory () {
        let minDate = "2000-01-01T00:00:00";
        let maxDate = "2035-01-01T00:00:00";
        let transactions = transactionsStore.useGetter(CrudGetter.Data) as TransactionModel[];
        let start = moment(this.partyProduct?.dateOfStart).toDate();
        if (moment(start).isBefore(moment("2022-01-01T00:00:00"))) {
            start = moment("2022-01-01T00:00:00").toDate();
        }
        let end = this.partyProduct?.dateOfEnding != null ? moment(this.partyProduct?.dateOfEnding).toDate() : new Date();
        let output: StateOfTransactionModel[] = [];
        const endOfPreviousMonth = moment().add(-1, "month").endOf("month");
        while (start <= end)
        {
            const monthStart = moment(start).startOf('month');
            if (monthStart.isAfter(endOfPreviousMonth))
                break;
            const monthEnd = moment(start).endOf('month');
            const monthTransactions = transactions.filter((e: TransactionModel) => moment(e.time).isSameOrAfter(monthStart) && moment(e.time).isSameOrBefore(monthEnd)) as TransactionModel[];
            const deposit = monthTransactions.map((e) => e.deposit!).reduce((a, b) => a + b, 0);
            const withdrawal = monthTransactions.map((e) => e.withdrawal!).reduce((a, b) => a + b, 0);
            const earnings = monthTransactions.map((e) => e.earnings!).reduce((a, b) => a + b, 0);
            const stateOfCapital = monthTransactions.map((e) => e.stateOfCapital!).reduce((a, b) => a + b, 0);
            const interestRate = this.partyProduct.partyProductInterestRates.firstOrDefault(x => moment(x.fromDate ?? minDate).startOf("day").toDate() < monthEnd.toDate() && moment(x.toDate ?? maxDate).endOf("day").toDate() > monthStart.toDate())?.interestRate ?? 0;
            const party = this.partyProduct!.party!;
            output.splice(0, 0, {
                party: party,
                time: monthEnd.isAfter(moment()) ? moment().endOf("month").toDate() : monthEnd.endOf("month").toDate(),
                deposit: deposit,
                withdrawal: withdrawal,
                interestRate: interestRate,
                earnings: earnings,
                stateOfCapital: stateOfCapital
            });
            start = moment(start).add(1, "month").startOf("month").toDate();
        }
        this.history = output;
        this.computeSummary(output);
    }

    loadStateOfCapital () {
        stateOfCapitalStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<StateOfCapitalModel>([{ field: "date", index: 1, order: "asc" }], [
            { field: "productId", op: "eq", comparand: Constants.NreiProductId }
        ]))
    }

    loadStateOfCapitalYear () {
        stateOfCapitalYearStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<StateOfCapitalYearModel>([{ field: "date", index: 1, order: "desc" }], [
            { field: "productId", op: "eq", comparand: Constants.NreiProductId }
        ]))
    }

    changed () {
        this.load();
    }
    
    removed () {
        this.$router.push({ name: "ClientProducts", params: { id: this.id } })
    }
    
}
