import moment from "moment";
import { TableOptionsModel } from "../models/table-options-model";

export class LocalStorageHelper {
    
    //#region TableOptions

    public static getTableOptions (name: string) : TableOptionsModel | null {

        if (window.localStorage.getItem(name)) {
            const stringModel = window.localStorage.getItem(name);
            if (stringModel != null)
                return JSON.parse(stringModel) as TableOptionsModel;
        }

        return null;

    }

    public static getTableOptionsOrDefault(name: string) : TableOptionsModel {
        
        const tableOptionsModel = LocalStorageHelper.getTableOptions(name);

        if (tableOptionsModel)
            return tableOptionsModel;

        return {
            page: 1,
            itemsPerPage: 100,
            itemsLength: 0
        } as TableOptionsModel;

    }

    public static setTableOptions(name: string, tableOptionsModel: TableOptionsModel) {

        if (TableOptionsModel)
            window.localStorage.setItem(name, JSON.stringify(tableOptionsModel));
        else
            window.localStorage.removeItem(name);

    }

    //#endregion

    //#region Value

    public static getValue(name: string) : string | null {
        return window.localStorage.getItem(name);
    }

    public static getValueOrDefault(name: string, defaultValue: string | null = null) : string | null {
        return window.localStorage.getItem(name) ?? defaultValue;
    }

    public static setValue(name: string, value: string) {
        window.localStorage.setItem(name, value);
    }

    //#endregion

    //#region Number

    public static getNumber(name: string) : number | null {
        const value = LocalStorageHelper.getValue(name);
        if (value) 
            return Number(value);
        return null;
    }

    public static getNumberOrDefault(name: string, defaultValue = 0) : number {
        return LocalStorageHelper.getNumber(name) ?? defaultValue;
    }

    public static setNumber(name: string, value: number) {
        LocalStorageHelper.setValue(name, value.toString());
    }

    //#endregion

    //#region Boolean

    public static getBoolean(name: string): boolean | null  {
        const value = LocalStorageHelper.getValue(name);
        if (value) 
            return Boolean(value);
        return null;
    }

    public static getBooleanOrDefault(name: string, defaultValue = false)  {
        return LocalStorageHelper.getBoolean(name) ?? defaultValue;
    }

    public static setBoolean(name: string, value: number) {
        LocalStorageHelper.setValue(name, value.toString());
    }

    //#endregion
    
    //#region DateTime

    public static getDateTime(name: string): Date | null  {
        const value = LocalStorageHelper.getValue(name);
        if (value) 
            return moment(value).toDate();
        return null;
    }

    public static getDateTimeOrDefault(name: string, defaultValue: Date = new Date())  {
        return LocalStorageHelper.getDateTime(name) ?? defaultValue;
    }

    public static setDateTime(name: string, value: Date) {
        LocalStorageHelper.setValue(name, value.toString());
    }

    //#endregion
    
}