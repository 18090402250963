






















































































































































































import {Component, Vue, Prop, Ref, Watch} from 'vue-property-decorator';
import Kanban from '@/libs/common/components/Kanban.vue';
import { KanbanColumn } from '@/libs/common/components/Kanban/KanbanColumn';
import TaskDialog from '@/libs/tasks/components/TaskDialog.vue';
import { AccountTypeEnum, ApplicationUserModel, CodebookModel, CodebookType, UserTaskModel } from '@/libs/Api';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { tasksStore } from '../+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { CrudPartialUpdatePatch } from '@/libs/core/+state/models/crud-partial-update-patch';
import { useObjectKey } from '@/libs/common/functions/use-object-key';

import CodebookSelect from '@/libs/common/components-smart/CodebookSelect.vue';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import moment from 'moment';
import Page from '@/Page.vue';

@Component({
    components: {
        Kanban,
        TaskDialog,
        CodebookSelect
    }
})
export default class Tasks extends Page {
    
    taskName: string | null = null;
    taskId: string | null = null;
    partyId: string | null = null;
    solverId: string | null = (applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel).partyId!;
    ownerId: string | null = null;
    taskTypeId: string | null = null;
    showFilters = false;
    view: string | null = "actual";
    taskSince: string | null = null;
    taskUntil: string | null = null;
    archived = false;
    debounce: any = null;
    
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;

    @Ref() taskDialog!: TaskDialog;

    @Watch("partyId")
    @Watch("ownerId")
    @Watch("solverId")
    @Watch("taskTypeId")
    @Watch("taskSince")
    @Watch("taskUntil")
    @Watch("archived")
    filtersChanged () {
        this.loadData();
    }
   
    @Watch("taskName")
    taskNameChanged () {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.loadData();
        }, 300);
    }

    get kanbanColumns (): KanbanColumn[] {
        return codebooksStore
                    .useGetter(CrudGetter.Data)
                    .filter((e: CodebookModel) => { return e.type == CodebookType.TaskState; })
                    .sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; })
                    .map((e: CodebookModel) => {
                        return {
                            name: e.name,
                            color: e.color,
                            propertyName: "taskStateId",
                            propertyValue: e.id
                        } as KanbanColumn;
                    });
    }

    get tasks(): UserTaskModel[] {
        return tasksStore.useGetter(CrudGetter.Data);
    }

    get pendingTasks() : boolean {
        return tasksStore.useGetter(CrudGetter.Pending);
    }

    mounted () {
        this.loadData();
        if (this.$router.currentRoute.query?.id) {
            this.openTask(this.$router.currentRoute.query!.id! as string);
        }
    }

    loadData () {
        tasksStore.dispatch(CrudAction.GetAll, this.getPayload());
    }

    getPayload () {
        let filters: CrudQueryPredicate[] = [];
        if (this.applicationUser.accountType == AccountTypeEnum.Consultant) {
            filters.push({
                field: "id", 
                op: "wrap",
                comparand: [
                    { field: "ownerId", op: "eq", comparand: this.applicationUser.partyId!, junction: "or" },
                    { field: "userTaskSolvers.partyId", op: "eq", comparand: this.applicationUser.partyId!, junction: "or" }
                ]
            });
        }
        if (this.taskName) {
            const parts = this.taskName.split(" ");
            let innerFilters: Array<any> = [];
            for (var p of parts) {
                if (p?.length == 0)
                    continue;
                innerFilters.push({
                    field: "name",
                    op: "c",
                    comparand: p.trim(),
                    junction: "and"
                });
            }
            if (innerFilters.length > 0) {
                filters.push({ field: "id", op: "wrap", comparand: innerFilters });
            }
        }
        if (this.partyId) {
            filters.push({ field: "partyId", op: "eq", comparand: this.partyId });
        }
        if (this.ownerId) {
            filters.push({ field: "ownerId", op: "eq", comparand: this.ownerId });
        }
        if (this.solverId) {
            filters.push({ field: "userTaskSolvers.partyId", op: "eq", comparand: this.solverId });
        }
        if (this.taskTypeId) {
            filters.push({ field: "taskTypeId", op: "eq", comparand: this.taskTypeId });
        }
        if (this.taskSince) {
            filters.push({ field: "taskDate", op: "gte", comparand: moment(this.taskSince).format("YYYY-MM-DDT00:00:00") });
        }
        if (this.taskUntil) {
            filters.push({ field: "taskDate", op: "lte", comparand: moment(this.taskUntil).format("YYYY-MM-DDT23:59:59") });
        }
        filters.push({ field: "isArchived", op: "eq", comparand: this.archived });
        return createCrudQueryPayload<UserTaskModel>({ field: "taskDate", index: 1, order: "asc" }, filters);
    }

    openTask (v: string) {
        this.taskDialog.open(v);
    }

    dragAndDropTask (v: UserTaskModel) {
        tasksStore.dispatch(CrudAction.PartialUpdate, { id: v.id, diff: [
            { op: 'replace', path: useObjectKey<UserTaskModel>('taskStateId'), value: v.taskStateId } as CrudPartialUpdatePatch
        ] });
    }

    footerButtonClick (v: string) {
        this.taskDialog.createTaskWithState(v);
    }

    downloadTasks () {
        tasksStore.dispatch(CrudAction.Download, this.getPayload());
    }

    getSubTaskTotalCount(v: UserTaskModel) {
        return v.subTasks?.length ?? 0;
    }

    getSubTaskCompletedCount(v: UserTaskModel) {
        return v.subTasks?.where(x => x.isChecked == true).toArray().length ?? 0;
    }

}
