import {RouteConfig} from 'vue-router';
import Credits from './pages/Credits.vue';

export default [
    {
        path: '/credits',
        name: 'Credits',
        component: Credits
    }
] as RouteConfig[];
