














import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class MaritalStatusSelect extends Vue {

    @Prop({ default: "Rodinný stav:" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;

    get items(): any[] {
        return [
          
        ];
    }

}
