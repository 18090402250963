










































































import { ApplicationUserModel, PartyModel, PartyProductModel, ProductModel, ProductTypeModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { partiesStore, partyProductsStore } from '@/libs/parties/+state/store';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { productsStore, productTypesStore } from '@/libs/products/+state/store';

import ClientInsuaranceCard from '@/libs/account/components/ClientInsuaranceCard.vue';
import ClientLoanCard from '@/libs/account/components/ClientLoanCard.vue';
import ClientInvestmentCard from '@/libs/account/components/ClientInvestmentCard.vue';
import ClientNOIFCard from '@/libs/account/components/ClientNOIFCard.vue';
import ClientReOpportunitiesCard from '@/libs/account/components/ClientReOpportunitiesCard.vue';
import ClientNREICard from '@/libs/account/components/ClientNREICard.vue';
import ClientNCIFCard from '@/libs/account/components/ClientNCIFCard.vue';
import ClientNWCard from '@/libs/account/components/ClientNWCard.vue';
import ClientCLTCard from '@/libs/account/components/ClientCltCard.vue';
import ClientCftCard from '@/libs/account/components/ClientCftCard.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Constants } from '@/libs/constants/constants';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import ClientProductNrem from "@/libs/clients/pages/ClientProductNrem.vue";
import NNremCard from "@/libs/clients/components/NNremCard.vue";
import ClientNremCard from "@/libs/account/components/ClientNremCard.vue";

@Component({
    components: {
	    ClientNremCard,
	    NNremCard,
	    ClientProductNrem,
        ClientInsuaranceCard,
        ClientLoanCard,
        ClientInvestmentCard,
        ClientNOIFCard,
        ClientReOpportunitiesCard,
        ClientNREICard,
        ClientNCIFCard,
        ClientNWCard,
		ClientCLTCard,
		ClientCftCard
    }
})
export default class AccountProducts extends Vue {

    id = (applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel).partyId!;

    get client(): PartyModel {
        const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        return partiesStore.useGetter(CrudGetter.Detail, this.id != null ? this.id : user.partyId!);
    }

    noifPartyProduct: PartyProductModel | null = null;
    reOpportunitiesPartyProduct: PartyProductModel | null = null;
    nreiPartyProduct: PartyProductModel | null = null;
    ncifPartyProduct: PartyProductModel | null = null;
    wealthPartyProduct: PartyProductModel | null = null;
    cltPartyProduct: PartyProductModel | null = null;
    cftPartyProduct: PartyProductModel | null = null;
    insuranceProducts: PartyProductModel[] = [];
    loanProducts: PartyProductModel[] = [];
    investmentProducts: PartyProductModel[] = [];
    inactiveProducts: PartyProductModel[] = [];
	nremProduct: ProductModel | null = null;

    nfgProductType: ProductTypeModel | undefined = undefined;
    insuranceProductType: ProductTypeModel | undefined = undefined;
    loanProductType: ProductTypeModel | undefined = undefined;
    investmentProductType: ProductTypeModel | undefined = undefined;

    noifPartyProducts: PartyProductModel[] = [];
    inactiveNoifPartyProducts: PartyProductModel[] = [];
    newNoifProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.NoifProductId
    } as PartyProductModel;
    
    reOpportunitiesPartyProducts: PartyProductModel[] = [];
    inactiveReOpportunitiesPartyProducts: PartyProductModel[] = [];
    newReOpportunitiesProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.ReOpportunitiesId
    } as PartyProductModel;
    
    nreiPartyProducts: PartyProductModel[] = [];
    inactiveNreiPartyProducts: PartyProductModel[] = [];
    newNreiProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.NreiProductId
    } as PartyProductModel;
    
    wealthPartyProducts: PartyProductModel[] = [];
    inactiveWealthPartyProducts: PartyProductModel[] = [];
    newWealthProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.WealthProductId
    } as PartyProductModel;

    cltPartyProducts: PartyProductModel[] = [];
    inactiveCltPartyProducts: PartyProductModel[] = [];
    newCltProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.CltProductId
    } as PartyProductModel;

    cftPartyProducts: PartyProductModel[] = [];
    inactiveCftPartyProducts: PartyProductModel[] = [];
    newCftProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.CftProductId
    } as PartyProductModel;

    nremPartyProducts: PartyProductModel[] = [];
    inactiveNremPartyProducts: PartyProductModel[] = [];
    newNremProduct = {
        id: Guid.EmptyGuid(),
        productId: Constants.NremProductId
    } as PartyProductModel;

    newId: string | null = Guid.EmptyGuid();
    
    mounted () {
        const productTypes: ProductTypeModel[] = productTypesStore.useGetter(CrudGetter.Data);
        this.nfgProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.NfgProductTypeId; })
        this.insuranceProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.InsuranceProductTypeId; });
        this.loanProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.LoanProductTypeId; });
        this.investmentProductType = productTypes.find((e: ProductTypeModel) => { return e.id?.toLowerCase() == Constants.InvesmentProductTypeId; });
        this.loadProducts();
    }

    loadProducts () {
        productsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductModel>([ { field: "name", index: 1, order: "asc" } ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === productsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.loadPartyProducts();
            }
        });
    }

    loadPartyProducts () {
        const user = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PartyProductModel>({ field: "createdAt", index: 1, order: "asc" }, [
            { field: "partyId", op: "eq", comparand: this.id != null ? this.id : user.partyId! }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.makeProducts();
            }
        });
    }

    makeProducts () {
        
        const products = productsStore.useGetter(CrudGetter.Data) as Array<ProductModel>;
        const partyProducts = partyProductsStore.useGetter(CrudGetter.Data) as Array<PartyProductModel>;
        
        const activeInsurancePartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.InsuranceProductTypeId) && x.isActive == true);
        const newInsuranceProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.insuranceProductType?.id; }).map((e: ProductModel) => {
            return this.getPartyProduct(e, []);
        }).filter((e: any) => { return typeof e.isActive == "undefined"; });
        this.insuranceProducts = activeInsurancePartyProducts.concat(newInsuranceProducts);
        
        const activeLoanPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.LoanProductTypeId) && x.isActive == true);
        const newLoanProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.loanProductType?.id; }).map((e: ProductModel) => {
            return this.getPartyProduct(e, []);
        }).filter((e: any) => { return typeof e.isActive == "undefined" });
        this.loanProducts = activeLoanPartyProducts.concat(newLoanProducts);
        
        const activeInvestmentPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.InvesmentProductTypeId) && x.isActive == true);
        const newInvestmentProducts = products.filter((e: ProductModel) => { return e.productTypeId == this.investmentProductType?.id; }).map((e: ProductModel) => {
            return this.getPartyProduct(e, []);
        }).filter((e: any) => { return typeof e.isActive == "undefined" });
        this.investmentProducts = activeInvestmentPartyProducts.concat(newInvestmentProducts);
        
        this.noifPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NoifProductId.toLowerCase()) && x.isActive == true);
        this.inactiveNoifPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NoifProductId.toLowerCase()) && x.isActive == false);

        this.reOpportunitiesPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.ReOpportunitiesId.toLowerCase()) && x.isActive == true);
        this.inactiveReOpportunitiesPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.ReOpportunitiesId.toLowerCase()) && x.isActive == false);

        this.nreiPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NreiProductId.toLowerCase()) && x.isActive == true);
        this.inactiveNreiPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NreiProductId.toLowerCase()) && x.isActive == false);

        this.wealthPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.WealthProductId.toLowerCase()) && x.isActive == true);
        this.inactiveWealthPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.WealthProductId.toLowerCase()) && x.isActive == false);

        this.cltPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.CltProductId.toLowerCase()) && x.isActive == true);
        this.inactiveCltPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.CltProductId.toLowerCase()) && x.isActive == false);

        this.cftPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.CftProductId.toLowerCase()) && x.isActive == true);
        this.inactiveCftPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.CftProductId.toLowerCase()) && x.isActive == false);

        this.nremPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NremProductId.toLowerCase() && p.isHidden != true) && x.isActive == true);
        this.inactiveNremPartyProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.id.toLowerCase() == Constants.NremProductId.toLowerCase() && p.isHidden != true) && x.isActive == false);

        const noifProduct = products.find((e: ProductModel) => { return e.id == Constants.NoifProductId; });
        if (noifProduct != null) {
            this.noifPartyProduct = this.getPartyProduct(noifProduct, partyProducts);
        }
        const reOpportunitiesProduct = products.find((e: ProductModel) => { return e.id == Constants.ReOpportunitiesId; });
        if (reOpportunitiesProduct != null) {
            this.reOpportunitiesPartyProduct = this.getPartyProduct(reOpportunitiesProduct, partyProducts);
        }
        const nreiProduct = products.firstOrDefault((e: ProductModel) => { return e.id == Constants.NreiProductId; });
        if (nreiProduct != null) {
            this.nreiPartyProduct = this.getPartyProduct(nreiProduct!, partyProducts);
        }
        const ncifProduct = products.firstOrDefault((e: ProductModel) => { return e.id == Constants.NcifProductId; });
        if (ncifProduct != null) {
            this.ncifPartyProduct = this.getPartyProduct(ncifProduct!, partyProducts);
        }
        const weathProduct = products.firstOrDefault((e: ProductModel) => { return e.id == Constants.WealthProductId; });
        if (weathProduct != null) {
            this.wealthPartyProduct = this.getPartyProduct(weathProduct!, partyProducts);
        }
        const cltProduct = products.firstOrDefault((e: ProductModel) => { return e.id == Constants.CltProductId; });
        if (cltProduct != null) {
            this.cltPartyProduct = this.getPartyProduct(cltProduct!, partyProducts);
        }
        const cftProduct = products.firstOrDefault((e: ProductModel) => { return e.id == Constants.CftProductId; });
        if (cftProduct != null) {
            this.cftPartyProduct = this.getPartyProduct(cftProduct!, partyProducts);
        }
        this.nremProduct = products.firstOrDefault((e: ProductModel) => { return e.id == Constants.NremProductId; });
        
        const inactiveInsuranceProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.InsuranceProductTypeId) && x.isActive == false);
        const inactiveLoanProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.LoanProductTypeId) && x.isActive == false);
        const inactiveInvestmentProducts = partyProducts.filter(x => products.any(p => p.id == x.productId && p.productTypeId == Constants.InvesmentProductTypeId) && x.isActive == false);
        
        this.inactiveProducts = inactiveInsuranceProducts.concat(inactiveLoanProducts).concat(inactiveInvestmentProducts);

    }

    getPartyProduct (product: ProductModel, partyProducts: PartyProductModel[]) {
        const partyProduct = partyProducts.find((e: PartyProductModel) => { return e.productId == product.id; });
        return partyProduct != null ? partyProduct : {
            id: Guid.EmptyGuid(),
            productId: product.id,
            name: product.name
        } as PartyProductModel;
    }

}
