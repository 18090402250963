













































import ApiService from "@/libs/core/api-service"
import { PartyCreditUploadModel, PartyModel, ProductModel, StateOfCapitalModel, TransactionModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { stateOfCapitalStore } from '@/libs/state-of-capital/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Constants } from '@/libs/constants/constants';
import { productsStore } from '@/libs/products/+state/store';

@Component({
    components: {
        CrudTable
    }
})
export default class ProductCreditSettings extends Vue {

    fileTooBig = false;
    uploadingFile = false;
    uploadedSnackbar = false;

    @Prop() id!: string;

    @Ref() uploadPdfInput!: any;

    mounted() {
        this.loadData();
    }

    get item(): ProductModel {
        return {...productsStore.useGetter(CrudGetter.Detail, this.id)};
    }

    get saving(): boolean {
        return productsStore.useGetter(CrudGetter.Saving, this.id);
    }

    get timestamp() {
        return new Date().getTime().toString();
    }

    loadData () {
        productsStore.dispatch(CrudAction.Get, { id: this.id, key: this.id });
    }

    save () {
        productsStore.dispatch(CrudAction.Update, {  item: this.item, key: this.id });
    }

    uploadAvatarClick (e: any) {
        e.preventDefault();
        this.uploadPdfInput.click();
    }

    getFileBase64(file: File, callback: any) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result!.toString().split(',')[1]);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    filesChange (fieldName: string, fileList: FileList) {

        if (!fileList.length) return;

        this.uploadingFile = true;

        this.getFileBase64(fileList[0], (e: string) => {

            const uploadModel = {
                contentType: fileList[0].type,
                base64: e
            } as PartyCreditUploadModel;
            this.uploadPdfInput.value = null;
            ApiService.api.updatePartyCreditPdf(uploadModel).then(() => {
                this.uploadingFile = false;
                this.uploadedSnackbar = true;
            });

        });
        
    }

}
