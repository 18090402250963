


































import { PartyProductModel } from '@/libs/Api';
import { Guid } from '@/libs/common/functions/guid';
import {Component, Vue, Prop, Ref} from 'vue-property-decorator';
import RequestForQuotationDialog from '@/libs/account/components/RequestForQuotationDialog.vue';

@Component({
    components: {
        RequestForQuotationDialog
    }
})
export default class ClientNCIFCard extends Vue {

    @Prop() partyProduct!: PartyProductModel;
    @Prop() clientId!: string;
    @Ref() dialogRequest!: RequestForQuotationDialog;
    @Prop({ default: false }) accountSection!: boolean;
    
    newId: string | null = Guid.EmptyGuid();

    openPartyProduct() {
        this.dialogRequest.open();
    }

}

