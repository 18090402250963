











































































import Page from "@/Page.vue";
import {Component, Ref, VModel, Watch} from "vue-property-decorator";
import {exchangeRatesStore} from "@/libs/exchange-rates/+state/store";
import {CrudGetter} from "@/libs/core/+state/models/crud-getter";
import moment from "moment/moment";
import {
	ConvertFunctionEnum,
	ExchangeRateModel,
	GroupByDefinition, GroupByProjectionDefinition,
	ProjectionAggregationEnum,
	QueryOperator
} from "@/libs/Api";
import {CrudAction, CrudReponse} from "@/libs/core/+state/models/crud-action";
import {createGrouppedQueryPayload} from "@/libs/core/+state/models/crud-query-payload";
import {Constants} from "@/libs/constants/constants";
import VueApexCharts from 'vue-apexcharts';
import ChartByTransaction from "@/libs/clients/components/Cft/ChartByTransaction.vue";
import ChartByHour from "@/libs/clients/components/Cft/ChartByHour.vue";
import ChartByDay from "@/libs/clients/components/Cft/ChartByDay.vue";
import ChartByMonth from "@/libs/clients/components/Cft/ChartByMonth.vue";

@Component({
	components: {
		VueApexCharts,
		ChartByTransaction,
		ChartByHour,
		ChartByDay,
		ChartByMonth
	}
})
export default class ChartDialog extends Page {

	minBasicChartDate = moment().add(-1, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss");
	maxBasicChartDate = moment().format("YYYY-MM-DD HH:mm:ss");
	rateDialogView = "rate";
	rateDialogDateRange = "last24hours";
	loaded = false;
	chartHeight = 200;
	datePickerMinDate = moment("2023-06-01").format("YYYY-MM-DD HH:mm:ss");
	datePickerMaxDate = moment().format("YYYY-MM-DD HH:mm:ss");

	@VModel() dialog!: boolean;

	@Watch("rateDialogView")
	rateDialogViewChanged(v: string) {
		if (v) {
			const options = this.getRateDialogRateRangeOptions(v);
			if (!options.any(x => x.id == v)) {
				this.rateDialogDateRange = options.first().id;
			}
		}
	}

	get isMobile(): boolean {
		return window.outerWidth <= 1140;
	}

	get rateDialogDateRangeOptions(): { id: string, text: string, maxDayRange?: number }[] {
		return this.getRateDialogRateRangeOptions(this.rateDialogView);
	}

	getRateDialogRateRangeOptions(rateDialogView: string) {
		switch (rateDialogView) {
			case "rate":
				return [
					{ id: "last24hours", text: "Posledních 24 hodin" },
					{ id: "last3days", text: "Poslední 3 dny" },
					{ id: "last7days", text: "Posledních 7 dní" },
					{ id: "custom", text: "Vlastní", maxDayRange: 7 }
				];
			case "hour":
				return [
					{ id: "last24hours", text: "Posledních 24 hodin" },
					{ id: "last3days", text: "Poslední 3 dny" },
					{ id: "last7days", text: "Posledních 7 dní" },
					{ id: "custom", text: "Vlastní", maxDayRange: 7 }
				];
			case "day":
				return [
					{ id: "last3days", text: "Poslední 3 dny" },
					{ id: "last7days", text: "Posledních 7 dní" },
					{ id: "last30days", text: "Posledních 30 dní" },
					{ id: "last3months", text: "Posledních 3 měsíce" },
					{ id: "last6months", text: "Posledních 6 měsíců" },
					{ id: "last12months", text: "Posledních 12 měsíců" },
					{ id: "custom", text: "Vlastní", maxDayRange: 90 }
				];
			case "month":
				return [
					{ id: "last3months", text: "Posledních 3 měsíce" },
					{ id: "last6months", text: "Posledních 6 měsíců" },
					{ id: "last12months", text: "Posledních 12 měsíců" },
					{ id: "custom", text: "Vlastní", maxDayRange: 365*3 }
				];
		}
		return [];
	}

	mounted(): void {
		this.chartHeight = document.getElementById("chartContainer").clientHeight - 30;
		this.loaded = true;
	}

	closeDialog(): void {
		this.$emit("input", false);
	}

}
