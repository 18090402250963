

































































































import { ApplicationUserModel, NotificationModel } from '@/libs/Api';
import { notificationsStore } from '@/libs/notifications/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import NotificationDialog from '@/libs/notifications/components/NotificationDialog.vue';

import AccountDialog from '@/libs/system-settings/components/AccountDialog.vue';
import AccountTypeSelect from '@/libs/common/components-smart/AccountTypeSelect.vue';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { applicationUserStore } from '@/libs/user-settings/+state/store';

@Component({
    components: { 
        AccountDialog,
        AccountTypeSelect, 
        NotificationDialog
    }
})
export default class SystemSettingsNotifications extends Vue {
  
    dialogItem: NotificationModel | null = null;
    tableHeaders = [
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "datetime" },
        { text: 'Název', align: 'left', sortable: false, value: 'name', type: "text" },
        { text: 'Vlastník', align: 'left', sortable: false, value: 'owner.displayName' },
        { text: 'Klient', align: 'left', sortable: false, value: 'party.displayName' },
        { text: 'Druh upozornění', align: 'left', sortable: false, value: 'notificationType.name' },
        { text: 'Potvrzeno', align: 'left', sortable: false, value: 'isConfirmed' },
        { text: 'Popis', align: 'left', sortable: false, value: 'description' },
        { text: '', align: 'right', sortable: false, value: 'actions' }
    ];
    tableHeadersMobile = [
        { text: 'Vlastník', align: 'left', sortable: false, value: 'owner.displayName' },
        { text: 'Popis', align: 'left', sortable: false, value: 'description' }
    ];
    filterOwnerId = null;
    filterType = null;
    filterClientId = null;
    showFilters = false;
    notificationId: string | null = null;

    @Ref() notificationDialog!: NotificationDialog;

    get pending() : boolean {
        return notificationsStore.useGetter(CrudGetter.Pending);
    }

    get tableItems() : NotificationModel[] {
        return notificationsStore.useGetter(CrudGetter.Data);
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    @Watch("filterOwnerId")
    @Watch("filterType")
    @Watch("filterClientId")
    filtersChanged() {
        this.loadNotifications();
    }
    
    mounted () {
        this.loadNotifications();
    }

    loadNotifications() {
        
        const filters: CrudQueryPredicate[] = [];
        
        if (this.filterOwnerId != null)
            filters.push({ field: "OwnerId", op: "eq", comparand: this.filterOwnerId! });
        
        if (this.filterType != null)
            filters.push({ field: "NotificationTypeId", op: "eq", comparand: this.filterType! });

        if (this.filterClientId != null)
            filters.push({ field: "PartyId", op: "eq", comparand: this.filterClientId! });
        
        notificationsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<NotificationModel>(undefined, filters, undefined, false));

    }

    editItem (args: any) {
        this.notificationDialog.open(args.id);
    }

    deleteItem () {
        console.log("deleteItem");
    }

}
